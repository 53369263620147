import React, { useState, useRef, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { FaBold, FaItalic, FaUnderline, FaTimes, FaPaperclip, FaSave, FaSuperscript, FaSubscript, FaRegTrashAlt } from "react-icons/fa";
import pathconfig from '../../../pathconfig.json';
import axios from "axios";
import { toastError, toastSuccess } from '../../toaster';
import packjson from '../../../../package.json';
import { useAppContextProvider } from '../../../contextapi';

const CommentBox = ({ onSaveComment, onCancelComment, comments = [], getTriggerfile, initialContent }) => {
    // const [comment, setComment] = useState([]);
    const textArea = useRef(null)
    const fileInputRef = useRef(null);
    const [fileAttachment, setFileAttachment] = useState([]);
    const [fileAllData, setFileAllData] = useState([]);
    const [loader, setLoader] = useState(false)
    const { userProfile } = useAppContextProvider();

    const handleSave = () => {
        if (textArea.current.innerHTML) {
            onSaveComment({ 'comment': textArea.current.innerHTML, 'fileattached': fileAttachment });
            textArea.current.innerHTML = '';
            setFileAttachment([]);
        } else {
            toastError('Please enter valuable comments; the comment is currently empty.')
        }
    };

    useEffect(() => {
        let docId = userProfile.docid;
        getDetails();
    }, [userProfile])

    const applyStyle = (command) => {
        const currentSelection = window.getSelection();
        if (currentSelection.rangeCount > 0) {
            const range = currentSelection.getRangeAt(0);
            const parentElement = range.commonAncestorContainer.parentNode;
            if (command === 'superscript' || command === 'subscript') {
                if (parentElement.tagName.toLowerCase() === (command === 'superscript' ? 'sup' : 'sub')) {
                    const grandparent = parentElement.parentNode;
                    while (parentElement.firstChild) {
                        grandparent.insertBefore(parentElement.firstChild, parentElement);
                    }
                    grandparent.removeChild(parentElement);
                } else {
                    document.execCommand(command, false, null);
                }
            } else {
                document.execCommand(command, false, null);
            }
        }
    };

    const handleCancel = () => {
        // Clear the text area content
        if (textArea.current) {
            textArea.current.innerHTML = '';
        }
        // Clear file attachments
        setFileAttachment([]);
        // Call the parent's cancel handler
        onCancelComment();
    };    

    const handleUpload = (e) => {
        let dataCheck = fileAllData.filter((item) => item.Filename === e.target.files[0].name);
        if (dataCheck.length === 0) {
            let file = e.target.files[0];
            const maxFileSize = 40 * 1024 * 1024;
            console.log(file)
            if (file.size > maxFileSize) {
                toastError('The file size should not exceed more than 40 MB')
                e.target.value = null;
                return;
            }
            handleFileupload(e.target.files[0])
        } else {
            toastError(`${e.target.files[0].name} filename already uploaded. Please rename and retry.`)
        }
        e.target.value = null;
    }

    const handleFileupload = (filename) => {
        setLoader(true)
        if (fileAttachment[0]?.Filename === filename.name) {
            toastError(`${filename.name} filename already uploaded. Please rename and retry.`);
            setLoader(false)
        } else {
            try {
                let GetDetailDataString = localStorage.getItem(`${userProfile?.docid}_init-getdetails`);
                let GetDetailData = JSON.parse(GetDetailDataString);
                var formdata = new FormData();
                formdata.append("jobGUID", GetDetailData.Articledetails.ArticleGUID);
                formdata.append("ActivityID", GetDetailData.ActivtyDetails.ActivityID);
                formdata.append("docid", userProfile?.docid);
                formdata.append("type", "commentAttachment");
                formdata.append("files", filename);
                const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].upload_attachment}`;
                axios.post(url, formdata, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }
                ).then((response) => {
                    if (response.data.SuccessFiles.length !== 0) {
                        response.data.SuccessFiles.map((item) =>
                            toastSuccess(`${item.data[0].Filename} - ${item.message}`)
                        )
                    }
                    if (response.data.FailedFiles.length !== 0) {
                        response.data.FailedFiles.map((item) =>
                            toastError(`${item.data[0].Filename} - ${item.message}`)
                        )
                    }
                    if (response.data.success === true) {
                        getDetails(filename)
                        getTriggerfile(true)
                    }
                }
                ).catch(error => {
                    console.log(error);
                    toastError(error.message);
                    setLoader(false);
                })
            } catch (error) {
                console.log(error);
                toastError(error.message);
                setLoader(false)
            }
        }
    }

    const getDetails = (filename) => {
        let GetDetailDataString = localStorage.getItem(`${userProfile?.docid}_init-getdetails`);
        let GetDetailData = JSON.parse(GetDetailDataString);
        const getUrl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].getAttachment}`;
        const body = {
            "Proof_Type": GetDetailData.jobdetails.ProofType,
            "JobGUID": GetDetailData.jobdetails.JobGUID,
            "docid": userProfile?.docid
        }
        axios.post(getUrl, body).then((response) => {
            let dataChange = response.data.data.map((item) => {
                return {
                    ...item,
                    filetype: item.Filename.split('.')[1]
                }
            }
            )
            let commentType = dataChange.filter((item) => item.AttachType === 'commentAttachment')
            if (filename) {
                let files = commentType.filter((item) => item.Filename === filename.name)
                setFileAttachment(files)
            } else {
                setFileAllData(commentType)
            }
            setLoader(false)
        }
        ).catch((error) => {
            console.log(error)
            toastError(error.message);
            setLoader(false);
        })
    }

    const deleteConfirm = (value) => {
        let GetDetailDataString = localStorage.getItem(`${userProfile?.docid}_init-getdetails`);
        let GetDetailData = JSON.parse(GetDetailDataString);
        let data = {
            "ArticleGUID": value.ArticleGUID,
            "AttachID": value.AttachID,
            "Proof_Type": GetDetailData.jobdetails.ProofType,
            "AttachFlag": value.AttachFlag,
            "docid": userProfile?.docid
        }
        const deleteUrl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].deleteAttachment}`
        axios.post(deleteUrl, data).then((response) => {
            toastSuccess(`${response.data.data[0].Filename} - ${response.data.message}`)
            getDetails()
            setFileAttachment([])
        }
        ).catch((error) => {
            console.log(error)
            toastError(error.message);
        })
    }

useEffect(() => {
    console.log("Initial content:", initialContent);
    if (initialContent && textArea.current) {
        textArea.current.innerHTML = initialContent;
    }
}, [initialContent]);


    return (
        <div className="comment-box">
            <div className="reply-btns">
                <div>
                    <Button onClick={() => applyStyle('bold')} title='Bold'>
                        <FaBold />
                    </Button>
                    <Button onClick={() => applyStyle('italic')} title='Italic'>
                        <FaItalic />
                    </Button>
                    <Button onClick={() => applyStyle('underline')} title='Underline'>
                        <FaUnderline />
                    </Button>
                    <Button onClick={() => applyStyle('superscript')} title='SuperScript'>
                        <FaSuperscript />
                    </Button>
                    <Button onClick={() => applyStyle('subscript')} title='SubScript'>
                        <FaSubscript />
                    </Button>
                    <Button className="attchcomment-btn" title='Attachment'>
                        <FaPaperclip />
                        <input type="file" onChange={(e) => handleUpload(e)} ref={fileInputRef}></input>
                    </Button>
                </div>
                {/* <div>
                    <Button onClick={handleCancel}><FaTimes /></Button>
                </div> */}
            </div>
            <div className="CommentContent">
                <div contentEditable="true" id="TypeContent" className="CommentTextEntry UIFont Cmdtxtcontent" ref={textArea}></div>
            </div>
            {fileAttachment.length !== 0 ? <div className='comment-attached'>
                {fileAttachment.map((item, index) =>
                    <div className='comment-attached-list' key={index}>
                        <p>{item.Filename}</p>
                        <label>size - {item.Filesize}</label>
                        <button onClick={() => deleteConfirm(item)}><FaRegTrashAlt /></button>
                    </div>
                )}
            </div> : loader ? <div className='fileupload-loder'><span className="loader"></span></div> : ''
            }
            <div className="comment-footer">
                <Button onClick={handleSave} className="save-btn"><FaSave /> Save</Button>
                <Button onClick={handleCancel} className="cancel-btn"><FaTimes /> Cancel</Button>
                </div>
        </div >
    );
};

export default CommentBox;
