import { useEffect, useRef, useState, useCallback } from "react";
import { BiRefresh } from "react-icons/bi";
import axios from 'axios';
import { toastError, toastSuccess } from '../toaster';
import pathconfig from '../../pathconfig.json';
import { FaCheckCircle, FaExclamationCircle, FaCode } from "react-icons/fa";
import { Button } from 'react-bootstrap';
import { FaFloppyDisk } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import packjson from '../../../package.json';
import { useAppContextProvider } from '../../contextapi';
import PDFViewerComponent from './pdf-view';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfEditor = (props) => {
    const [fileContent, setFileContent] = useState('');
    const [currentFile, setCurrentFile] = useState(null);
    const [compileAction, setCompileAction] = useState(true);
    const [showPdf, setShowpdf] = useState(false);
    const [pdfData, setPdfdata] = useState(null)
    const [pdfStatus, setPdfStatus] = useState('')
    const saveButton = useRef(null);
    const CompileButton = useRef(null);
    const [time, setTime] = useState(0);
    const [timeComplete, setTimeComplete] = useState(false)
    const [timer, setTimer] = useState(false);
    const location = useLocation();
    const [downloadPercentage, setDownloadPercentage] = useState(0);
    const [downloadBar, setDownloadBar] = useState(false);
    const [showconfirm, setConfirm] = useState(false)
    const [showProgress, setShowprogress] = useState(false);
    // const [trackValue, setTrackvalue] = useState([]);
    const [trackSpreate, setTrackSpreate] = useState({});
    const [submitData, setSubmitData] = useState(false);
    const [progressDetails, setProgressDetails] = useState('Please wait file is progess to submit.');
    const [saveStatus, setSaveStatus] = useState(false)
    const [pdfUrl, setPdfUrl] = useState('')
    const navigate = useNavigate();
    const { userProfile, authorQuery, microSave, setMicroSave, fullData, saveTrigger, setSaveTrigger, trackContent, trackChanges, editorContent, setLayout, editorStyle, readOnly, supplementaryData, changedComment } = useAppContextProvider();
    let CompileCode = true;
    let CompileCheck = true;

    const compileCode = async (value) => {
        if (userProfile.role === 'Author') {
            if (CompileCheck && value !== 'submitHandle') {
                let validationItem = await validationcheck('compilercheck', value);
                if (validationItem?.length > 0) {
                    return;
                }
            }
        }
        setCompileAction(false);
        setTimer(true);
        setTime(0);
        setCompileAction(true);
        setTimeComplete(false);
        setProgressDetails(!CompileCode ? 'Uncited Element Found. Please wait Re-compiler in progress.' : 'Please wait compiler in progress.');
        setShowprogress(true);
        if (value === 'submitHandle') {
            setProgressDetails('Please wait auto compiler in progress.');
            setShowprogress(true);
        }
        try {
            if (!fileContent || !currentFile) {
                toastError(`Cannot able to fetch the file for compiling. Please contact journal manager`);
                setCompileAction(false);
                return;
            }
            // let tagInsertion = await trackchangeTagInsertion(fileContent, props.trackData)
            let data = {
                data: fileContent,
                filename: userProfile.docid,
                is_Submit: value === 'submitHandle' ? true : false,
                role: userProfile.role
            }
            const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].compiler_api}`;
            const response = await axios.post(url, data);
            // If the code reaches here, it means the compile was successful
            try {
                const pdfResponse = await blobUpload(response.data);
                //setPdfdata(response.data.pdfBase64);
                props.LogDataFile(true)
                setPdfdata(response.data.pdfBase64);
                let logContent = atob(response.data.logBase64);
                let checkText = 'LaTeX Warning: There were undefined references';
                if (CompileCode) {
                    if (logContent.includes(checkText)) {
                        setProgressDetails('Uncited Element Found. Please wait Re-compiler in progress.');
                        // setCompileCode(true)
                        CompileCheck = false;
                        CompileCode = false;
                        setShowprogress(true);
                        await compileCode();
                        setShowprogress(false);
                    }
                }
                // setBase64LogContent(response.data.logBase64)
                if (response.data?.errors.length === 0) {
                    setPdfStatus('success');
                    if (value === 'submitHandle') {
                        setSubmitData(true);
                        setProgressDetails('Please wait file is progess to submit.');
                        setShowprogress(false);
                    } else {
                        toastSuccess(`Compilation completed Successfully.`);
                        setShowprogress(false)
                    }
                    setShowpdf(true);
                    setTimeComplete(true);
                    setPdfStatus('');
                    setCompileAction(false);
                    // Call onSave after successful compilation
                    await onSave();
                }
                else if (response.data.errors.filter(item => item.type == 'Error').length == 0) {
                    if (response.data && response.data.errors && response.data.errors) {
                        if (Array.isArray(response.data.errors) || typeof response.data.errors === 'object') {
                            props.compileError(response.data.errors);
                        }
                    }
                    if (value === 'submitHandle') {
                        setSubmitData(true);
                        setProgressDetails('Please wait file is progess to submit.');
                        setShowprogress(false)
                    } else {
                        setPdfStatus('error');
                        toastSuccess(`The file has been successfully compiled with warnings.`);
                        setShowprogress(false);
                        CompileCode = true;
                    }
                    setShowpdf(true);
                    setTimeComplete(true);
                    setPdfStatus('');
                    setCompileAction(false);
                    // Call onSave after compilation with warnings
                    await onSave();
                }
                else {
                    if (response.data && response.data.errors && response.data.errors) {
                        if (Array.isArray(response.data.errors) || typeof response.data.errors === 'object') {
                            props.compileError(response.data.errors);
                        }
                    }
                    setPdfStatus('error');
                    toastSuccess(`The file has been successfully compiled with Errors.`);
                    setCompileAction(false);
                    setShowprogress(false);
                    CompileCode = true;
                    setPdfStatus('');
                    if (value === 'submitHandle') {
                        setProgressDetails('Please wait file is progess to submit.');
                        setShowprogress(false)
                    }
                    // Call onSave even after compilation with errors
                    await onSave();
                }
            } catch (error) {
                // Handle the error from blobupload here
                toastError(`upload error - ${error.message}`);
                throw new Error(`upload error - ${error.message}`);
            }

        } catch (error) {
            if (error.response && error.response.data && error.response.data.details) {
                if (Array.isArray(error.response.data.details) || typeof error.response.data.details === 'object') {
                    props.compileError(error.response.data.details);
                }
            }
            toastError(`Error compiling code - ${error.message}`);
            setPdfStatus('error');
            setCompileAction(false);
            setShowprogress(false);
            setPdfStatus('');
        }
    };

    useEffect(() => {
        if (downloadBar === true) {
            let percentage = 0;
            const interval = setInterval(() => {
                percentage++;
                setDownloadPercentage(percentage);
                if (percentage >= 100) {
                    clearInterval(interval);
                }
            }, 50);
        }
    }, [downloadBar]);

    useEffect(() => {
        if (trackChanges) {
            // Set initial track value
            // setTrackvalue(props.trackData);

            const docId = userProfile.docid;
            const GetDetailDataString = localStorage.getItem(`${docId}_init-getdetails`);
            const GetDetailData = GetDetailDataString ? JSON.parse(GetDetailDataString) : {};
            const WorkFlowID = GetDetailData?.ActivtyDetails?.WfdID;

            const filterByRole = (role) => {
                const { fileItems } = props;

                // Filter track data based on WorkFlowID and role
                const TrackValue = trackChanges?.filter((item) => {
                    if (WorkFlowID === 85 && role === "Editor") {
                        return ["Author", "Editor"].includes(item.role);
                    }
                    return item?.role === role;
                });
                // Filter comments based on WorkFlowID and role
                const Comment = changedComment?.comment?.filter((item) => {
                    if (WorkFlowID === 85 && role === "Editor") {
                        return ["Author", "Editor"].includes(item.role);
                    }
                    return item?.role === role;
                });

                // Filter attachments based on role
                const Attachment = fileItems?.filter((item) => {
                    const roleArray = JSON.parse(item?.Rolename);
                    const roleName = roleArray[0].RoleName;
                    if (WorkFlowID === 85 && role === "Editor") {
                        return ["Author", "Editor"].includes(roleName);
                    }
                    return roleName === role;
                }) || [];

                // Calculate specific lengths for "Editor" role
                const CommentLen = Comment?.filter(item => item?.role === "Editor");
                const TrackLen = TrackValue?.filter(item => item?.role === "Editor");

                return {
                    AuthorQuery: authorQuery,
                    TrackValue,
                    Comment,
                    Attachment,
                    CommentLen,
                    TrackLen,
                };
            };

            // Get filtered results based on user role
            const filteredResults = filterByRole(userProfile.role);

            // Filter for deletions and insertions
            const deletion = filteredResults.TrackValue?.filter((item) => item.type === "deletion") || [];

            const insertion =
                filteredResults.TrackValue?.filter((item) => item.type === "insertion") || [];
            // filter by editor role    
            const deletionEditor = filteredResults.TrackLen?.filter((item) => item.type === "deletion") || [];
            const insertionEditor = filteredResults.TrackLen?.filter((item) => item.type === "insertion") || [];
            // Set track separation values
            setTrackSpreate({
                Comment: filteredResults.Comment?.length || 0,
                AuthorQuery: filteredResults.AuthorQuery?.length || 0,
                deletion: deletion?.length || 0,
                insertion: insertion?.length || 0,
                deletionEditor: deletionEditor?.length || 0,
                insertionEditor: insertionEditor?.length || 0,
                editorTrackTotal: deletionEditor?.length + insertionEditor?.length,
                commentEditor: filteredResults.CommentLen?.length || 0,
                Attachment: filteredResults.Attachment?.length || 0,
                AttachmentEditor: filteredResults.Attachment?.length || 0,
            });
        }
    }, [trackChanges]);

    useEffect(() => {
        if (props.downloadTrigger === true) {
            pdfDownlad();
        }
    }, [props.downloadTrigger])

    useEffect(() => {
        if (editorStyle) {
            setFileContent(editorStyle);
        }
    }, [editorStyle])

    const onSave = useCallback(async () => {
        props.fileSave(true);
        setSaveStatus(false)
        try {
            if (!fileContent || !currentFile) {
                toastError(`Cannot able to fetch the file for compiling. Please contact journal manager`)
                return;
            }
            const docId = userProfile.docid;
            let GetDetailDataString = localStorage.getItem(`${docId}_init-getdetails`);
            let GetDetailData = JSON.parse(GetDetailDataString);
            let authorquerys = authorQuery;
            let comments = changedComment;
            // let blobcomments = commentRange;
            let Trackchange = trackChanges;
            let data = {
                htmlcontent: fileContent,
                QueryContent: JSON.stringify(authorquerys),
                CommentContent: JSON.stringify(comments),
                //BlobComment: JSON.stringify(blobcomments),
                trackChanges: JSON.stringify(Trackchange),
                supplementary: JSON.stringify(supplementaryData),
                istexfile: true,
                docid: docId,
                activityID: GetDetailData.ActivtyDetails.ActivityID,
                isAutoSave: false,
                ArticleGUID: GetDetailData.Articledetails.ArticleGUID,
            }
            const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].save_api}`;
            await axios.post(url, data)
                .then(({ data }) => {
                    props.fileSave(false);
                    props.fileSaveStatus(true);
                    setSaveTrigger(false);
                    setSaveStatus(data.is_success === false ? true : true)
                    //toastSuccess(`Saved Successfully.`)
                })
                .catch(error => {
                    props.fileSave(false);
                    toastError(`Error compiling code - ${error.message}`);
                });
        } catch (error) {
            toastError(`Error compiling code - ${error.message}`);
        }
    }, [fileContent, currentFile, location.search, props]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                onSave();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onSave]);

    async function blobUpload(responseData) {
        try {
            const docId = userProfile.docid;
            const GetDetailDataString = localStorage.getItem(`${docId}_init-getdetails`);
            const GetDetailData = JSON.parse(GetDetailDataString);
            const outPath = GetDetailData.Articledetails.OutputPath.substring(0, GetDetailData.Articledetails.OutputPath.lastIndexOf('/'));
            const filename = GetDetailData.Articledetails.ArticleName;
            const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobPDFupload}`;
            const data = {
                docid: docId,
                outPath: outPath,
                filename: filename
            }
            const response = await axios.post(url, data);
            console.log(response.data)
            // if (response.data.length) {
            //     console.log(response.data)
            //     toastSuccess(`Files saved successfully.`);
            // };
            return response;
        } catch (error) {
            // throw new Error(`Upload error - ${error.message}`);
            console.log(`Upload error - ${error.message}`);
        }
    }

    async function trackchangeTagInsertion(fileContent, trackvalue, commentsValue, value) {
        try {
            let latexContent = fileContent;
            // latexContent = latexContent.replace(/(\r\n)+/ig, '\r\n');
            // latexContent = latexContent.replace(/(\n)+/ig, '\n');
            // latexContent = latexContent.replace(/^\t/img, '');
            let ranges = [...(trackvalue || []), ...(commentsValue || [])];
            let trachranges = ranges.sort((a, b) => {
                if (a.from === b.from) {
                    return b.to - a.to;
                }
                return b.from - a.from;
            });
            const extractDeletionText = (decoration) => {
                return decoration?.widget?.text ||
                    decoration?.spec?.widget?.text ||
                    decoration?.spec?.text ||
                    '';
            }
            const insertText = (originalText, range, type) => {
                let before = originalText.slice(0, range.from);
                let after = originalText.slice(range.to);
                if (value === true) {
                    if (type === 'cmt') {
                        return before + `\\cmt[${JSON.stringify(range).replace(/\\"/g, 'itempslash"').replace(/_/g, '\\_').replace(/\$/g, '\\$').replace(/%/g, '\\%').replace(/#/g, '\\#').replace(/\[/g, 'iosb').replace(/\]/g, 'icsb').replace(/\{/g, 'iocb').replace(/\}/g, 'iccb')}]{` + range.selectText + "}" + after;
                    }
                    else if (type === 'del')
                        return before + `\\del[${JSON.stringify(range).replace(/\\"/g, 'itempslash"').replace(/_/g, '\\_').replace(/\$/g, '\\$').replace(/%/g, '\\%').replace(/#/g, '\\#').replace(/\[/g, 'iosb').replace(/\]/g, 'icsb').replace(/\{/g, 'iocb').replace(/\}/g, 'iccb')}]{` + range.decoration.spec.text + "}" + after;
                    else {
                        return before + `\\ins[${JSON.stringify(range).replace(/\\"/g, 'itempslash"').replace(/_/g, '\\_').replace(/\$/g, '\\$').replace(/%/g, '\\%').replace(/#/g, '\\#').replace(/\[/g, 'iosb').replace(/\]/g, 'icsb').replace(/\{/g, 'iocb').replace(/\}/g, 'iccb')}]{` + range.text + "}" + after;
                    }
                }
                else {
                    if (type === 'del')
                        return before + "\\del{" + range.decoration.spec.text + "}" + after;
                    else {
                        return before + "\\ins{" + range.text + "}" + after;
                    }
                }
            }
            for (const range of trachranges) {
                if (range.cmdtype === 'Comments') {
                    latexContent = insertText(latexContent, range, 'cmt');
                }
                else if (range.type === 'insertion') {
                    latexContent = insertText(latexContent, range, 'ins');
                }
                else {
                    latexContent = insertText(latexContent, range, 'del');
                }
            }
            console.log(latexContent);
            return latexContent;
        } catch (error) {
            throw new Error(`trackchange tag insertion error - ${error.message}`);
        }
    }
    useEffect(() => {
        if (submitData) {
            const submitFun = async () => {
                setShowprogress(true)
                try {
                    const docId = userProfile.docid;
                    let GetDetailDataString = localStorage.getItem(`${docId}_init-getdetails`);
                    let GetDetailData = JSON.parse(GetDetailDataString);
                    const OutputPath = GetDetailData.Articledetails.OutputPath;
                    const ActivityName = GetDetailData.ActivtyDetails.ActivityName;
                    const lastSlashIndex = OutputPath.lastIndexOf('/');
                    const OutputdirectoryPath = OutputPath.substring(0, lastSlashIndex);
                    const OutputPathfileName = OutputPath.substring(lastSlashIndex + 1);
                    let pageInfoData = await fetchPageInfoData(OutputPath);
                    let totalPage = extractTotalPage(pageInfoData);
                    let authorquerys = authorQuery;
                    let comments = changedComment;
                    // let blobcomments = commentRange;
                    let trackchange = trackChanges;
                    let tagInsertion = ["L2 QA", "Master Copier"].includes(ActivityName) ? await trackchangeTagInsertion(fileContent, trackChanges, changedComment.comment, submitData) : fileContent;
                    let data = {
                        htmlcontent: fileContent,
                        OutputTexContent: tagInsertion,
                        QueryContent: JSON.stringify(authorquerys),
                        CommentContent: JSON.stringify(comments),
                        // BlobComment: JSON.stringify(blobcomments),
                        trackChanges: JSON.stringify(trackchange),
                        supplementary: JSON.stringify(supplementaryData),
                        istexfile: true,
                        docid: docId,
                        getjobDetails: GetDetailData,
                        activityID: GetDetailData.ActivtyDetails.ActivityID,
                        isAutoSave: false,
                        ArticleGUID: GetDetailData.Articledetails.ArticleGUID,
                        filename: currentFile, // Assuming this is the filename to compile
                        editorChangeDetails: { trackchanges: { 'deletion': trackSpreate.deletion, 'insertion': trackSpreate.insertion, 'totalCount': trackSpreate.deletion + trackSpreate.insertion }, comments: trackSpreate.Comment, AuthorQuery: trackSpreate.AuthorQuery, Attachment: trackSpreate.Attachment, TotalPDFPageCount: totalPage },
                        editorChangeFilename: 'ArticleDetails.json',
                        resourcepath: GetDetailData.Articledetails.ArtResourcePath,
                        OutputdirectoryPath: OutputdirectoryPath,
                        OutputPathfileName: OutputPathfileName.replace('.xml', '.tex')
                    };
                    const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].submit_all_api}`; // Backend endpoint to handle all operations
                    const response = await axios.post(url, data);
                    if (response.data.is_success === true) {
                        setShowprogress(false)
                        // toastSuccess(`Submitted Successfully.`);
                        navigate(`/notification?docid=${docId}`, { state: { 'status': 'success', 'role': userProfile.role } })
                        localStorage.removeItem(`${docId}_init-getdetails`);
                        localStorage.removeItem(`${docId}_captchaVerified`);
                        localStorage.removeItem(`${docId}_logstatus`);
                    } else {
                        throw new Error('Submit API returned an error.');
                    }
                } catch (error) {
                    setShowprogress(false)
                    toastError(`Error: ${error.message}`);
                }
            }
            submitFun();
        }
    }, [submitData])

    const handleSubmit = async () => {
        setConfirm(false)
        compileCode('submitHandle')
    };

    async function fetchPageInfoData(OutputPath) {
        const fileurl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobFileExistorNot}`;
        const filedata = { InputPath: OutputPath.replace('.xml', '.pgInfo') };
        const isFileexist = await axios.post(fileurl, filedata);
        if (isFileexist.data.exist) {
            const response = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_preview_file}`, filedata);
            return response.data;
        }
        return '';
    }

    function extractTotalPage(pageInfoData) {
        const totalPageMatch = pageInfoData.match(/<TotalPage>(\d+)<\/TotalPage>/);
        return totalPageMatch ? parseInt(totalPageMatch[1], 10) : 0;
    }

    useEffect(() => {
        if (fullData) {
            setCurrentFile(fullData.Filename)
            if (editorContent.length === 0) {
                setFileContent(fullData.Content);
                if (fullData.pdfContent !== '{}') {
                    setPdfdata(fullData.pdfContent);
                    setShowpdf(true);
                }
                if (userProfile?.role === 'Author' && trackContent !== '') {
                    setFileContent(trackContent);
                }
            }
        }
        if (editorContent !== "") {
            setFileContent(editorContent);
        }
    }, [fullData, editorContent])

    useEffect(() => {
        if (compileAction === true) {
            let Timer = null;
            if (timeComplete === false) {
                Timer = setInterval(() => {
                    setTime((prevState) => prevState + 1);
                }, 1000);
            } else {
                clearInterval(Timer);
            }
            return () => {
                clearInterval(Timer);
            };
        }
    }, [compileAction, timeComplete]);

    useEffect(() => {
        if (pdfData) {
            const byteCharacters = atob(pdfData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            setPdfUrl(url)
            return () => {
                URL.revokeObjectURL(url);
            };
        }
    }, [pdfData]);

    const formatTime = (totalSeconds) => {
        const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
        const seconds = String(totalSeconds % 60).padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    const pdfDownlad = async () => {
        try {
            setDownloadBar(true)
            const docId = userProfile.docid;
            const linkPath = JSON.parse(localStorage.getItem(`${docId}_init-getdetails`));
            const pdfReplace = linkPath.Articledetails.OutputPath.replace('.xml', '.pdf')
            //const url2 = linkPath.Articledetails.blobDomain + pdfReplace; // Replace with your file URL
            const data = {
                InputPath: pdfReplace,
            }
            const isFileexist = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobFileExistorNot}`, data);
            if (isFileexist.data.exist === true) {
                const response = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].download}`, {
                    blobUrl: pdfReplace,
                    type: 'application/pdf'
                }, {
                    responseType: 'blob',// Important               
                });
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${linkPath.Articledetails.ArticleName}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                setDownloadBar(false)
                toastSuccess(`File Downloaded successfully.`);
            }
            else {
                setDownloadBar(false)
                toastError(`Compilation Required: Please compile the document before downloading the PDF.`)
            }
        } catch (error) {
            console.error('Error downloading file:', error);
            setDownloadBar(false)
            toastError(`File Downloaded Error.`);
        }
    }

    const validationcheck = async (value, submitvalue) => {
        try {
            let validitionlist = [];
            let count = 0;
            if (value !== 'compilercheck') {
                if (userProfile.role === 'Author' || userProfile.role === 'Editor') {
                    let authorquerydata = authorQuery;
                    for (const query of authorquerydata) {
                        if (query?.replies === undefined || query?.replies.length === 0) {
                            if (query?.repliesMark === undefined || query?.repliesMark === false) {
                                if (query?.aqCheck === undefined || query?.answerCheck === undefined || query?.answerCheck === 'no') {
                                    validitionlist.push({
                                        'serial': count + 1,
                                        'message': `${query.label} : Please respond to the query before submitting.`,
                                        'position': 'Query'
                                    });
                                    count++;
                                }
                            }
                        }
                    }
                }
            }
            if (userProfile.role === 'Author' || userProfile.role === 'Editor') {
                if (fileContent !== '') {
                    // Step 1: Split LaTeX content into lines
                    const latexLines = fileContent.split('\n');
                    // Step 2: Extract all citations with their line numbers
                    let citations = [];
                    latexLines.forEach((line, index) => {
                        // Updated regex to match any of the specified citation commands, with or without a star (*)
                        const matches = line.match(/\\cite(t|p|alp|alt|author|year)?\*?\{([^}]+)\}/g);
                        if (matches) {
                            matches.forEach(match => {
                                // Extract the citation keys by removing the command and braces
                                const citationKeys = match.replace(/\\cite(t|p|alp|alt|author|year)?\*?\{|\}/g, '').split(',').map(citation => citation.trim());
                                citationKeys.forEach(citation => {
                                    citations.push({
                                        value: citation,        // citation key
                                        lineNumber: index + 1   // 1-based line number
                                    });
                                });
                            });
                        }
                    });

                    // Step 3: Extract all bibitem entries with line numbers
                    let bibItems = [];
                    latexLines.forEach((line, index) => {
                        const bibItemMatch = line.match(/\\bibitem(?:\[[^\]]*\])?\{([^}]+)\}/);
                        if (bibItemMatch) {
                            bibItems.push({
                                value: bibItemMatch[1].trim(),  // Capture the content inside {}
                                lineNumber: index + 1           // 1-based line number for the bibitem
                            });
                        }
                    });

                    // Step 4: Find missing citations (citations without bibitem entries)
                    let missingReferences = [];
                    citations.forEach(citation => {
                        if (!bibItems.some(bibItem => bibItem.value === citation.value)) {
                            missingReferences.push(citation);
                        }
                    });

                    // Step 5: Find bibitems without corresponding citations
                    let missingCitations = [];
                    bibItems.forEach(bibItem => {
                        const isCited = citations.some(citation => citation.value === bibItem.value);
                        if (!isCited) {
                            missingCitations.push(bibItem);
                        }
                    });

                    // Output missing citations with line numbers
                    missingReferences.forEach(missingRef => {
                        validitionlist.push({
                            'serial': count + 1,
                            'message': `Citation (${missingRef.value}) found without a corresponding reference.`,
                            'position': Number(missingRef.lineNumber)
                        });
                        count++;
                    });

                    // Output references without citations with their line numbers
                    missingCitations.forEach(missingCite => {
                        validitionlist.push({
                            'serial': count + 1,
                            'message': `Reference (${missingCite.value}) found without a corresponding citation.`,
                            'position': Number(missingCite.lineNumber)  // Line number for uncited reference
                        });
                        count++;
                    });
                }
            }
            if (validitionlist.length === 0 && submitvalue === 'submitHandle') {
                setConfirm(true);
            } else {
                props.validation(validitionlist);
                return validitionlist;
            }
        } catch (error) {
            toastError(`Error validationcheck:, ${error}`);
            //console.error('Error validationcheck:', error);
        }
    }

    useEffect(() => {
        if (!readOnly) {
            if (saveStatus || saveTrigger) {
                //Set up interval to auto-save every 5 seconds
                const intervalId = setInterval(() => {
                    if (saveButton.current) {
                        saveButton.current.click();
                    }
                }, 60000);
                // Clean up the interval on component unmount
                return () => clearInterval(intervalId);
            }
        }
    }, [saveStatus, saveTrigger]);

    useEffect(() => {
        if (microSave === true) {
            onSave();
            setMicroSave(false);
        }
    }, [microSave]);

    const PdfcompleteLoader = () => {
        setCompileAction(false);
    };

    return (
        <div className="pdf-viewer">
            <div className={`modal-outer ${showconfirm ? 'active' : ''}`}>
                <div className="modal confirmation-popup">
                    <div className="modal-body">
                        <h1>Are you sure you want to submit?</h1>
                        <div className="change-group">
                            <div className="change-status">
                                <div className="change-status-inner">
                                    <p>{userProfile.role} changes: {userProfile.role === "Editor" ? trackSpreate.editorTrackTotal : (trackSpreate.deletion + trackSpreate.insertion)}</p>
                                    <div className="change-status-info">
                                        <p>Insertion: {userProfile.role === "Editor" ? trackSpreate.insertionEditor : trackSpreate.insertion}</p>
                                        <p>Deletion: {userProfile.role === "Editor" ? trackSpreate.deletionEditor : trackSpreate.deletion}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="change-status">
                                <div className="change-status-inner">
                                    <p>Comments: {userProfile.role === "Editor" ? trackSpreate.commentEditor : trackSpreate.Comment}</p>
                                </div>
                            </div>
                            <div className="change-status">
                                <div className="change-status-inner">
                                    <p>Query Responses: {trackSpreate.AuthorQuery}</p>
                                </div>
                            </div>
                            <div className="change-status">
                                <div className="change-status-inner">
                                    <p>Attachment: {userProfile.role === "Editor" ? trackSpreate.AttachmentEditor : trackSpreate.Attachment}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="primary" onClick={handleSubmit}>Yes</button>
                        <button className="outline-secondary" onClick={() => setConfirm(false)}>No</button>
                    </div>
                </div>
            </div>
            <div className={`modal-outer ${showProgress ? 'active' : ''}`}>
                <div className="modal confirmation-popup">
                    <div className="modal-body">
                        <p>{progressDetails}</p>
                        <span className="loader"></span>
                    </div>
                </div>
            </div>
            <div className='pdf-btns-group'>
                <div>
                </div>
                <div className='pdf-btns-group-inner'>
                    <Button className="com-btn firststep" onClick={compileCode} ref={CompileButton}><BiRefresh /><span> {timer === true ? `Compile - ${formatTime(time)}` : 'Compile'}</span></Button>
                    <Button className="com-btn" onClick={onSave} ref={saveButton}><FaFloppyDisk /> Save</Button>
                    <Button className="com-btn tenthstep" onClick={() => validationcheck('', 'submitHandle')}><FaFloppyDisk /> Submit</Button>
                    <Button className="switchbutton" onClick={() => setLayout('editoronly')}> <FaCode /> Switch to editor</Button>
                </div>
            </div>
            {compileAction === true ?
                <div className="preview_animation">
                    <div className="loadPDF">
                        <p><u></u><u></u><u></u><u></u></p>
                        <p><u></u><u></u><u></u><u></u></p>
                        {pdfStatus === 'success' ? <FaCheckCircle className="success" /> :
                            pdfStatus === 'error' ? <FaExclamationCircle className="error" /> : ''}
                    </div>
                </div> : ""}
            <div className={showPdf ? "pdf-area active" : "pdf-area"}>
                {downloadBar ?
                    <div className="pdf_download_popup_outer">
                        <div className="pdf_download_popup">
                            <p>PDF download.</p>
                            <div className="progess_bar">
                                <div className="progess_bar_inner" style={{ width: `${downloadPercentage}%` }}></div>
                            </div>
                        </div>
                    </div> : ''}
                {/* <div className="pdf-container" ref={containerRef} style={{ overflowX: 'scroll', overflowY: 'scroll' }} /> */}
                <PDFViewerComponent pdfUrl={pdfUrl} docid={userProfile.docid} completeLoader={PdfcompleteLoader} />
            </div>
        </div>
    )
}
export default PdfEditor;