import {
    memo,
    MouseEventHandler,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import * as pdfjs from 'pdfjs-dist';
import './pdf-view.css';
// import '../../pdf-dist/web/viewer.css'; // Ensure you have the proper viewer styles
import * as PDFJSViewer from 'pdfjs-dist/web/pdf_viewer';
import axios from 'axios';
import { buildHighlightElement } from './highlight';
import pathconfig from '../../pathconfig.json';
import packjson from '../../../package.json';
import { toastError } from '../toaster';
import { useAppContextProvider } from '../../contextapi';
import { BiHighlight } from "react-icons/bi";
import { TbAbc } from "react-icons/tb";
import { LuWholeWord } from "react-icons/lu";


// Set the worker source (ensure this path is correct)
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

const PDFViewerComponent = ({ docid, pdfUrl, completeLoader }) => {
    const viewerContainerRef = useRef(null); // Reference to the container where PDF will be rendered
    const viewerRef = useRef(null);
    const eventBusRef = useRef(null);
    const findInputRef = useRef(null);
    const { highlights, setLinePostion } = useAppContextProvider();
    const [mainviewer, setMainViewer] = useState();
    const MATCHES_COUNT_LIMIT = 1000;

    useEffect(() => {
        const loadPDFViewer = async () => {
            // Initialize the EventBus
            const eventBus = new PDFJSViewer.EventBus();
            eventBusRef.current = eventBus;
            const linkService = new PDFJSViewer.PDFLinkService({
                eventBus,
                externalLinkTarget: 2,
                externalLinkRel: 'noopener',
            })

            // Initialize the PDFViewer
            const viewer = new PDFJSViewer.PDFViewer({
                container: viewerContainerRef.current,  // Directly use viewerContainerRef for the container        
                viewer: viewerRef.current,
                linkService,
                eventBus,                               // Pass the event bus
                textLayerMode: 2,                       // Enable text layer for search/selection
                defaultRenderingQueue: true,            // Queue pages for rendering
                annotationLayerMode: 2,                 // Enable annotations (if any)
                enableScripting: false, // default is false, but set explicitly to be sure
                enableXfa: false, // default is false (2021-10-12), but set explicitly to be sure
                renderInteractiveForms: false,
                maxCanvasPixels: 8192 * 8192, // default is 4096 * 4096, increased for better resolution at high zoom levels
                annotationMode: pdfjs.AnnotationMode?.ENABLE, // enable annotations but not forms
                annotationEditorMode: pdfjs.AnnotationEditorType?.DISABLE, // disable annotation editing
                defaultScale: 0.8, // Sets initial zoom to 80%

                findController: new PDFJSViewer.PDFFindController({
                    linkService,
                    eventBus,
                }),
            });
            linkService.setViewer(viewer)
            try {
                // Load the PDF document from the provided URL
                const loadingTask = pdfjs.getDocument(pdfUrl);
                const pdfDocument = await loadingTask.promise;

                // Set the document to the viewer
                viewer.setDocument(pdfDocument);
                linkService.setDocument(pdfDocument)
                // Set up the event listener for 'textlayerrendered'
                eventBus.on('textlayerrendered', (textLayer) => {
                    console.log('Text layer rendered on page:', textLayer.pageNumber);
                    // You can perform custom actions here after the text layer is rendered
                    const textLayerDiv =
                        textLayer.source.textLayerDiv ?? textLayer.source.textLayer.div

                    if (!textLayerDiv.dataset.listeningForDoubleClick) {
                        textLayerDiv.dataset.listeningForDoubleClick = true

                        const doubleClickListener = async (event) => {
                            const clickPosition = fngetclickPosition(
                                event,
                                textLayerDiv.closest('.page').querySelector('canvas'),
                                textLayer.pageNumber - 1, viewer
                            )
                            // const clickPosition = fngetclickPosition(viewer);
                            console.log(clickPosition);
                            let data = {
                                pageNo: textLayer.pageNumber,
                                Hvalue: clickPosition.offset.left,
                                Vvalue: clickPosition.offset.top,
                                docid: docid
                            }
                            let url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].syncpdftotext}`
                            const response = await axios.post(url, data);
                            console.log(response)
                            if (response.data?.tex) {
                                setLinePostion(response.data?.tex)
                            }
                        }

                        textLayerDiv.addEventListener('dblclick', doubleClickListener)
                    }
                });
                completeLoader(false)
                setMainViewer(viewer)
            } catch (error) {
                toastError(`Error loading PDF: ${error}`);
            }
        };
        function fngetclickPosition(event, canvas, page, viewer) {
            if (!canvas) {
                return
            }
            // const mouseX = event.clientX;
            // const mouseY = event.clientY;

            // // Get the position and dimensions of the clicked element
            // const textLayerDiv = event.target;
            // const rect = textLayerDiv.getBoundingClientRect();

            // const elementWidth = rect.width;
            // const elementHeight = rect.height;
            // const elementTop = rect.top;
            // const elementLeft = rect.left;

            // console.log('Mouse Position:', mouseX, mouseY);
            // console.log('Text Layer Position:', elementTop, elementLeft);
            // console.log('Text Layer Dimensions:', elementWidth, elementHeight);
            // return {
            //     page,
            //     offset: {
            //         elementLeft,
            //         top: elementTop,
            //     },
            // }
            const { viewport } = viewer.getPageView(page)

            const pageRect = canvas.getBoundingClientRect()

            const dx = event.clientX - pageRect.left
            const dy = event.clientY - pageRect.top

            const [left, top] = viewport.convertToPdfPoint(dx, dy)

            return {
                page,
                offset: {
                    left,
                    top: viewport.viewBox[3] - top,
                },
            }
        }
        
        function fngetclickPosition1(viewer) {
            const pageIndex = viewer.currentPageNumber - 1
            const pageView = viewer.getPageView(pageIndex)
            const pageRect = pageView.div.getBoundingClientRect()

            const containerRect = viewer.container.getBoundingClientRect()
            const dy = containerRect.top - pageRect.top
            const dx = containerRect.left - pageRect.left
            const [left, top] = pageView.viewport.convertToPdfPoint(dx, dy)
            const [, , width, height] = pageView.viewport.viewBox

            return {
                page: pageIndex,
                offset: { top, left },
                pageSize: { height, width },
            }
        }


        if (pdfUrl) {
            loadPDFViewer();
        }

        // Clean up the viewer when the component unmounts or PDF changes
        return () => {
            if (viewerContainerRef.current) {
                // viewerContainerRef.current.innerHTML = ''; // Clear the viewer container
            }
            if (eventBusRef.current) {
                // Remove the event listener on cleanup
                eventBusRef.current.off('textlayerrendered');
            }
        };
    }, [pdfUrl]); // Re-run if pdfUrl changes

    useEffect(() => {
        const timers = []
        let intersectionObserver;
        if (viewerRef.current && highlights?.length) {
            // watch for the highlight elements to scroll into view
            intersectionObserver = new IntersectionObserver(
                entries => {
                    for (const entry of entries) {
                        if (entry.isIntersecting) {
                            intersectionObserver.unobserve(entry.target)

                            const element = entry.target

                            // fade the element in and out
                            element.style.opacity = '0.5'

                            timers.push(
                                window.setTimeout(() => {
                                    element.style.opacity = '0'
                                }, 1100)
                            )
                        }
                    }
                },
                {
                    threshold: 1.0, // the whole element must be visible
                }
            )
            const elements = [];
            for (const highlight of highlights) {
                try {
                    const element = buildHighlightElement(highlight, mainviewer, pdfjs)
                    elements.push(element)
                    intersectionObserver.observe(element)
                } catch (error) {
                    // ignore invalid highlights
                }
            }

            const [firstElement] = elements

            if (firstElement) {
                // scroll to the first highlighted element
                // Briefly delay the scrolling after adding the element to the DOM.
                timers.push(
                    window.setTimeout(() => {
                        firstElement.scrollIntoView({
                            block: 'center',
                            inline: 'start',
                            behavior: 'smooth',
                        })
                    }, 100)
                )
            }

            return () => {
                for (const timer of timers) {
                    window.clearTimeout(timer)
                }
                for (const element of elements) {
                    element.remove()
                }
                intersectionObserver?.disconnect()
            }
        }
    }, [highlights])

    useEffect(() => {
        // Ensure mainviewer is fully initialized
        if (!mainviewer) return;

        const viewFindButton = document.getElementById('viewFindButton');
        const findbar = document.getElementById('findbar');
        const findInput = document.getElementById('findInput');
        const findPreviousButton = document.getElementById('findPreviousButton');
        const findNextButton = document.getElementById('findNextButton');

        // Additional find options
        const findHighlightAll = document.getElementById('findHighlightAll');
        const findMatchCase = document.getElementById('findMatchCase');
        const findMatchDiacritics = document.getElementById('findMatchDiacritics');
        const findEntireWord = document.getElementById('findEntireWord');

        const toggleFindbar = () => {
            findbar.classList.toggle('hidden');
            viewFindButton.setAttribute('aria-expanded',
                findbar.classList.contains('hidden') ? 'false' : 'true'
            );
            findInputRef.current.focus();
        };

        // Event listener for closing the popup when clicking outside
        // document.addEventListener("click", (event) => {
        //     if (!findbar.contains(event.target) && event.target !== viewFindButton) {
        //         findbar.classList.add('hidden');
        //         viewFindButton.setAttribute('aria-expanded', 'false');
        //     }
        // });

        // Ensure find controller is properly set up
        if (!mainviewer.findController) {
            mainviewer.findController = new PDFJSViewer.PDFFindController({
                linkService: mainviewer.linkService,
                eventBus: mainviewer.eventBus,
            });
        }

        const performFind = () => {
            if (!findInput || !findInput.value) return;
        
            mainviewer.eventBus.dispatch('find', {
                source: window,
                type: 'findagain',
                query: findInput.value,
                caseSensitive: findMatchCase?.checked || false,
                highlightAll: findHighlightAll?.checked || false,
                entireWord: findEntireWord?.checked,
                matchDiacritics: findMatchDiacritics?.checked || false,
                phraseSearch: true,
                // findPrevious: false,
            });
        };
        

        const findPrevious = () => {
            if (!findInput || !findInput.value) return;

            mainviewer.eventBus.dispatch('find', {
                source: window,
                type: 'again',
                query: findInput.value,
                caseSensitive: findMatchCase?.checked || false,
                highlightAll: findHighlightAll?.checked || false,
                findPrevious: true,
                entireWord: findEntireWord?.checked,
            });
        };

        const findNext = () => {
            if (!findInput || !findInput.value) return;

            mainviewer.eventBus.dispatch('find', {
                source: window,
                type: 'again',
                query: findInput.value,
                caseSensitive: findMatchCase?.checked || false,
                highlightAll: findHighlightAll?.checked || false,
                findPrevious: false,
                entireWord: findEntireWord?.checked,
            });
        };

        // Event listeners
        if (viewFindButton && findbar) {
            viewFindButton.addEventListener('click', toggleFindbar);
        }

        if (findHighlightAll) {
            findHighlightAll.addEventListener('click', performFind);
        }

        if (findMatchCase) {
            findMatchCase.addEventListener('click', performFind);
        }

        if (findEntireWord) {
            findEntireWord.addEventListener('click', performFind);
        }

        if (findInput) {
            findInput.addEventListener('input', () => {
                if (!findInput.value) {
                    // Clear matches when input is empty
                    mainviewer.eventBus.dispatch('find', {
                        source: window,
                        type: 'find',
                        query: '', // Empty query
                        caseSensitive: findMatchCase?.checked || false,
                        highlightAll: findHighlightAll?.checked || false,
                        findPrevious: false,
                        entireWord: findEntireWord?.checked,
                        matchDiacritics: findMatchDiacritics?.checked || false,
                    });
                } else {
                    findInput.setAttribute("data-status", "");
                    performFind();
                }
            });

            findInput.addEventListener('keydown', (event) => {
                if (event.key === 'Enter') {
                    findNext();
                }
            });
        }

        if (findPreviousButton) {
            findPreviousButton.addEventListener('click', findPrevious);
        }

        if (findNextButton) {
            findNextButton.addEventListener('click', findNext);
        }

        // Listen for find results
        const findResultsListener = (evt) => {
            const { matchesCount: { current, total } } = evt
            const findResultsCount = document.getElementById('findResultsCount');
            const findMsg = document.getElementById('findMsg');

            if (findResultsCount) {
                findResultsCount.textContent = `${current} of ${total
                    } matches`;
            }

            if (findMsg) {
                findInput.setAttribute("data-status", findInput.value && total === 0 ? "notFound" : "");
                findMsg.textContent =
                    total === 0 ? 'Phrase not found' : '';
            }

            if (!findInput.value && current === 0 && total === 0) {
                if (total > 0) {
                    const limit = MATCHES_COUNT_LIMIT;

                    findResultsCount.setAttribute(
                        "data-l10n-id",
                        total > limit
                            ? "pdfjs-find-match-count-limit"
                            : "pdfjs-find-match-count"
                    );
                    findResultsCount.setAttribute(
                        "data-l10n-args",
                        JSON.stringify({ limit, current, total })
                    );
                } else {
                    findResultsCount.removeAttribute("data-l10n-id");
                    findResultsCount.textContent = "";
                    findMsg.textContent = "";
                }
            }

        };
        
    
        mainviewer.eventBus.on('updatefindmatchescount', findResultsListener);
        mainviewer.eventBus.on('updatefindcontrolstate', findResultsListener);
        
        // Cleanup function
        return () => {
            if (viewFindButton) {
                viewFindButton.removeEventListener('click', toggleFindbar);
            }
            if (findInput) {
                findInput.removeEventListener('input', performFind);
            }
            if (findPreviousButton) {
                findPreviousButton.removeEventListener('click', findPrevious);
            }
            if (findNextButton) {
                findNextButton.removeEventListener('click', findNext);
            }

            mainviewer.eventBus.off('updatefindmatchescount', findResultsListener);
            mainviewer.eventBus.off('updatefindcontrolstate', findResultsListener);
        };
    }, [mainviewer]); // Depend on mainviewer to ensure it's available



    useEffect(() => {
        if (mainviewer && mainviewer.pdfDocument) {
            const previousButton = document.getElementById('previous');
            const nextButton = document.getElementById('next');
            const pageNumberInput = document.getElementById('pageNumber');
            const numPagesSpan = document.getElementById('numPages');

            // Set total pages
            if (numPagesSpan) {
                numPagesSpan.textContent = `of ${mainviewer.pdfDocument.numPages}`;
            }

            // Update page number input max attribute
            if (pageNumberInput) {
                pageNumberInput.max = mainviewer.pdfDocument.numPages;
            }

            // Page navigation functions
            const handlePreviousPage = () => {
                if (mainviewer.currentPageNumber > 1) {
                    mainviewer.currentPageNumber--;
                }
            };

            const handleNextPage = () => {
                if (mainviewer.currentPageNumber < mainviewer.pdfDocument.numPages) {
                    mainviewer.currentPageNumber++;
                }
            };

            const handlePageNumberChange = (event) => {
                const pageNumber = parseInt(event.target.value, 10);
                if (pageNumber >= 1 && pageNumber <= mainviewer.pdfDocument.numPages) {
                    mainviewer.currentPageNumber = pageNumber;
                }
            };

            // Add event listeners
            if (previousButton) {
                previousButton.addEventListener('click', handlePreviousPage);
            }

            if (nextButton) {
                nextButton.addEventListener('click', handleNextPage);
            }

            if (pageNumberInput) {
                pageNumberInput.addEventListener('change', handlePageNumberChange);
            }

            // Update page number input when page changes
            const pageChangingHandler = (evt) => {
                if (pageNumberInput) {
                    pageNumberInput.value = evt.pageNumber;
                }
            };

            mainviewer.eventBus.on('pagechanging', pageChangingHandler);

            // Cleanup function
            return () => {
                if (previousButton) {
                    previousButton.removeEventListener('click', handlePreviousPage);
                }
                if (nextButton) {
                    nextButton.removeEventListener('click', handleNextPage);
                }
                if (pageNumberInput) {
                    pageNumberInput.removeEventListener('change', handlePageNumberChange);
                }
                mainviewer.eventBus.off('pagechanging', pageChangingHandler);
            };
        }
    }, [mainviewer]); // Depend on mainviewer to ensure it's available

    useEffect(() => {
        // After initializing the viewer, set up zoom controls
        if (mainviewer) {
            const zoomOutButton = document.getElementById('zoomOutButton');
            const zoomInButton = document.getElementById('zoomInButton');
            const scaleSelect = document.getElementById('scaleSelect');

            // Set initial auto zoom to 120%
            // mainviewer.currentScaleValue = 1.2;

            scaleSelect?.addEventListener('change', (event) => {
                const selectedScale = event.target.value;

                switch (selectedScale) {
                    case 'auto':
                        mainviewer.currentScaleValue = 1.2; // 120%
                        break;
                    case 'page-actual':
                        mainviewer.currentScaleValue = 1.0; // 100%
                        break;
                    case 'page-fit':
                        mainviewer.currentScaleValue = 0.6; // 60%
                        break;
                    case 'page-width':
                        mainviewer.currentScaleValue = 'page-width';
                        break;
                    default:
                        mainviewer.currentScaleValue = parseFloat(selectedScale);
                }
            });

            zoomInButton?.addEventListener('click', () => {
                const currentScale = mainviewer.currentScale;
                const increment = currentScale >= 1.2 ? 0.2 : 0.1; // 20% above 120%, 10% below
                const newScale = Math.round((currentScale + increment) * 100) / 100;
                mainviewer.currentScale = newScale;
                updateCustomScaleOption(newScale);
            });

            zoomOutButton?.addEventListener('click', () => {
                const currentScale = mainviewer.currentScale;
                const decrement = currentScale > 1.2 ? 0.1 : 0.2; // 10% above 120%, 20% below
                const newScale = Math.round((currentScale - decrement) * 100) / 100;
                mainviewer.currentScale = newScale;
                updateCustomScaleOption(newScale);
            });

            function updateCustomScaleOption(scale) {
                const customOption = document.getElementById('customScaleOption');
                if (customOption) {
                    const scalePercentage = Math.round(scale * 100);
                    customOption.textContent = `${scalePercentage}%`;
                    customOption.value = scale;
                    customOption.selected = true;
                    customOption.hidden = false;
                    customOption.disabled = false;
                }
            }
        }
    }, [mainviewer]);

    useEffect(() => {
        if (mainviewer && viewerContainerRef.current) {
            // Define the update function
            function updateCustomScaleOption(scale) {
                const customOption = document.getElementById('customScaleOption');
                if (customOption) {
                    const scalePercentage = Math.round(scale * 100);
                    customOption.textContent = `${scalePercentage}%`;
                    customOption.value = scale;
                    customOption.selected = true;
                    customOption.hidden = false;
                    customOption.disabled = false;
                }
            }

            const handleWheel = (event) => {
                if (event.ctrlKey || event.metaKey) {
                    event.preventDefault();

                    const currentScale = mainviewer.currentScale;
                    let newScale;

                    if (event.deltaY < 0) {
                        const increment = currentScale >= 1.2 ? 0.2 : 0.1;
                        newScale = Math.round((currentScale + increment) * 100) / 100;
                    } else {
                        const decrement = currentScale > 1.2 ? 0.1 : 0.2;
                        newScale = Math.round((currentScale - decrement) * 100) / 100;
                    }

                    mainviewer.currentScale = newScale;
                    updateCustomScaleOption(newScale);
                }
            };

            viewerContainerRef.current.addEventListener('wheel', handleWheel, { passive: false });

            return () => {
                viewerContainerRef.current?.removeEventListener('wheel', handleWheel);
            };
        }
    }, [mainviewer]);


    return (
        <div id="outerContainer">
            <div id="mainContainer">
                <div className="toolbar">
                    <div id="toolbarContainer">
                        <div id="toolbarViewer" className="toolbarHorizontalGroup">
                            <div id="toolbarViewerLeft" className="toolbarHorizontalGroup">
                                {/*<button id="sidebarToggleButton" className="toolbarButton" type="button" title="Toggle Sidebar" tabIndex={0} data-l10n-id="pdfjs-toggle-sidebar-button" aria-expanded="false" aria-haspopup="true" aria-controls="sidebarContainer">
                                    <span data-l10n-id="pdfjs-toggle-sidebar-button-label">Toggle Sidebar</span>
                                </button>*/}
                                <div className="toolbarButtonSpacer" />
                                <div className="toolbarButtonWithContainer">
                                    <button id="viewFindButton" className="toolbarButton" type="button" title="Find in Document" tabIndex={0} data-l10n-id="pdfjs-findbar-button" aria-expanded="false" aria-controls="findbar">
                                        <span data-l10n-id="pdfjs-findbar-button-label">Find</span>
                                    </button>
                                    <div className="hidden doorHanger toolbarHorizontalGroup" id="findbar">
                                        <div id="findInputContainer" className="toolbarHorizontalGroup">
                                            <span className="loadingInput end toolbarHorizontalGroup">
                                                <input ref={findInputRef} id="findInput" className="toolbarField" title="Find" placeholder="Find in document…" tabIndex={0} data-l10n-id="pdfjs-find-input" aria-invalid="false" />
                                            </span>
                                            <div className="toolbarHorizontalGroup">
                                                <button id="findPreviousButton" className="toolbarButton" type="button" title="Find the previous occurrence of the phrase" tabIndex={0} data-l10n-id="pdfjs-find-previous-button">
                                                    <span data-l10n-id="pdfjs-find-previous-button-label">Previous</span>
                                                </button>
                                                <div className="splitToolbarButtonSeparator" />
                                                <button id="findNextButton" className="toolbarButton" type="button" title="Find the next occurrence of the phrase" tabIndex={0} data-l10n-id="pdfjs-find-next-button">
                                                    <span data-l10n-id="pdfjs-find-next-button-label">Next</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div id="findbarOptionsOneContainer" className="toolbarHorizontalGroup">
                                            <div className="toggleButton toolbarLabel">
                                                <input type="checkbox" id="findHighlightAll" tabIndex={0} title='Hightlight All' />
                                                <label htmlFor="findHighlightAll" data-l10n-id="pdfjs-find-highlight-checkbox" title='Hightlight All'>
                                                <BiHighlight size={18}/>
                                                </label>
                                            </div>
                                            <div className="toggleButton toolbarLabel">
                                                <input type="checkbox" id="findMatchCase" tabIndex={0} title='Match Case' />
                                                <label htmlFor="findMatchCase" data-l10n-id="pdfjs-find-match-case-checkbox-label" title='Match Case'>
                                                    <TbAbc size={18}/>
                                                    </label>
                                            </div>
                                        </div>
                                        <div id="findbarOptionsTwoContainer" className="toolbarHorizontalGroup">
                                            {/* <div className="toggleButton toolbarLabel">
                                                <input type="checkbox" id="findMatchDiacritics" tabIndex={0} />
                                                <label htmlFor="findMatchDiacritics" data-l10n-id="pdfjs-find-match-diacritics-checkbox-label">Match Diacritics</label>
                                            </div> */}
                                            <div className="toggleButton toolbarLabel">
                                                <input type="checkbox" id="findEntireWord" tabIndex={0} title='Whole Words'/>
                                                <label htmlFor="findEntireWord" data-l10n-id="pdfjs-find-entire-word-checkbox-label" title='Whole Words'>
                                                    <LuWholeWord size={18}/>
                                                    </label>
                                            </div>
                                        </div>
                                        <div id="findbarMessageContainer" className="toolbarHorizontalGroup" aria-live="polite">
                                            <span id="findResultsCount" className="toolbarLabel"></span>
                                            <span id="findMsg" className="toolbarLabel"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="toolbarHorizontalGroup hiddenSmallView">
                                    <button className="toolbarButton" title="Previous Page" type="button" id="previous" tabIndex={0} data-l10n-id="pdfjs-previous-button">
                                        <span data-l10n-id="pdfjs-previous-button-label">Previous</span>
                                    </button>
                                    <div className="splitToolbarButtonSeparator" />
                                    <button className="toolbarButton" type="button" title="Next Page" id="next" tabIndex={0} data-l10n-id="pdfjs-next-button">
                                        <span data-l10n-id="pdfjs-next-button-label">Next</span>
                                    </button>
                                </div>
                                <div className="toolbarHorizontalGroup">
                                    <span className="loadingInput start toolbarHorizontalGroup">
                                        <input type="number" id="pageNumber" className="toolbarField" title="Page" defaultValue={1} min={1} tabIndex={0} data-l10n-id="pdfjs-page-input" autoComplete="off" />
                                    </span>
                                    <span id="numPages" className="toolbarLabel" />
                                </div>
                            </div>
                            <div id="toolbarViewerMiddle" className="toolbarHorizontalGroup">
                                <div className="toolbarHorizontalGroup">
                                    <button id="zoomOutButton" className="toolbarButton" type="button" title="Zoom Out" tabIndex={0} data-l10n-id="pdfjs-zoom-out-button">
                                        <span data-l10n-id="pdfjs-zoom-out-button-label">Zoom Out</span>
                                    </button>
                                    <div className="splitToolbarButtonSeparator" />
                                    <button id="zoomInButton" className="toolbarButton" type="button" title="Zoom In" tabIndex={0} data-l10n-id="pdfjs-zoom-in-button">
                                        <span data-l10n-id="pdfjs-zoom-in-button-label">Zoom In</span>
                                    </button>
                                </div>
                                <span id="scaleSelectContainer" className="dropdownToolbarButton">
                                    <select id="scaleSelect" title="Zoom" tabIndex={0} data-l10n-id="pdfjs-zoom-select">
                                        <option id="pageAutoOption" title value="auto" selected="selected" data-l10n-id="pdfjs-page-scale-auto">Automatic Zoom</option>
                                        <option id="pageActualOption" title value="page-actual" data-l10n-id="pdfjs-page-scale-actual">Actual Size</option>
                                        <option id="pageFitOption" title value="page-fit" data-l10n-id="pdfjs-page-scale-fit">Page Fit</option>
                                        <option id="pageWidthOption" title value="page-width" data-l10n-id="pdfjs-page-scale-width">Page Width</option>
                                        <option id="customScaleOption" title value="custom" disabled="disabled" hidden="true" data-l10n-id="pdfjs-page-scale-percent" data-l10n-args="{ &quot;scale&quot;: 0 }">0%</option>
                                        <option title value="0.5" data-l10n-id="pdfjs-page-scale-percent" data-l10n-args="{ &quot;scale&quot;: 50 }">50%</option>
                                        <option title value="0.75" data-l10n-id="pdfjs-page-scale-percent" data-l10n-args="{ &quot;scale&quot;: 75 }">75%</option>
                                        <option title value={1} data-l10n-id="pdfjs-page-scale-percent" data-l10n-args="{ &quot;scale&quot;: 100 }">100%</option>
                                        <option title value="1.25" data-l10n-id="pdfjs-page-scale-percent" data-l10n-args="{ &quot;scale&quot;: 125 }">125%</option>
                                        <option title value="1.5" data-l10n-id="pdfjs-page-scale-percent" data-l10n-args="{ &quot;scale&quot;: 150 }">150%</option>
                                        <option title value={2} data-l10n-id="pdfjs-page-scale-percent" data-l10n-args="{ &quot;scale&quot;: 200 }">200%</option>
                                        <option title value={3} data-l10n-id="pdfjs-page-scale-percent" data-l10n-args="{ &quot;scale&quot;: 300 }">300%</option>
                                        <option title value={4} data-l10n-id="pdfjs-page-scale-percent" data-l10n-args="{ &quot;scale&quot;: 400 }">400%</option>
                                    </select>
                                </span>
                            </div>
                        </div>
                        <div id="loadingBar">
                            <div className="progress">
                                <div className="glimmer">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={viewerContainerRef} id="viewerContainer">
                    <div id="viewer" ref={viewerRef} className="pdfViewer" />
                </div>
            </div>
        </div>
    );
};

export default PDFViewerComponent;
