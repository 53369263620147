import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AppContextProvider } from './contextapi';

function Main() {
  return (
    <AppContextProvider>
      <App />
    </AppContextProvider>
  )
}

let MainPage = document.getElementById("main");
const root = ReactDOM.createRoot(MainPage);
root.render(<Main />);
