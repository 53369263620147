import { Decoration, EditorView } from "@codemirror/view";
import { StateEffect, StateField } from "@codemirror/state";
import nspell from "nspell";

let dictionary;

(async () => {
  const aff = await fetch("/dictionaries/en_US.aff").then((response) => response.text());
  const dic = await fetch("/dictionaries/en_US.dic").then((response) => response.text());
  dictionary = nspell(aff, dic);
})();

// Define a function to check if text is LaTeX content
const isLatexContent = (text) => {
  const latexPatterns = [
    /\\[a-zA-Z]+\{[^}]*\}/,
    /\$[^$]*\$/,
    /\$\$[^$]*\$\$/,
    /\\begin\{[^}]*\}/,
    /\\end\{[^}]*\}/,
    /\\[a-zA-Z]+(?:\[[^\]]*\])?\{[^}]*\}/,
    /%.+$/,
  ];
  return latexPatterns.some((pattern) => pattern.test(text));
};

// State field for handling spell check decorations
const spellCheckField = StateField.define({
  create() {
    return Decoration.none;
  },
  update(decorations, transaction) {
    // if (!transaction.docChanged) return decorations;

    const builder = [];
    const text = transaction.state.doc.toString();
    const wordRegex = /(?<!\\|\{|\[|%)\b[a-zA-Z]{3,}\b(?!\}|\]|\$)/g;
    let match;

    while ((match = wordRegex.exec(text)) !== null) {
      const word = match[0];
      const context = text.slice(
        Math.max(0, match.index - 20),
        match.index + word.length + 20
      );

      if (!isLatexContent(context) && !dictionary?.correct(word)) {
        builder.push(
          Decoration.mark({
            class: "cm-spelling-error",
            attributes: {
              title: `Possible spelling error: ${word}`,
              "data-word": word,
            },
          }).range(match.index, match.index + word.length)
        );
      }
    }
    return Decoration.set(builder, true);
  },
  provide: (field) => EditorView.decorations.from(field),
});

const spellChecker = [
  spellCheckField,
  EditorView.updateListener.of((update) => {
    if (update.docChanged) {
      update.view.dispatch({
        effects: StateEffect.appendConfig.of(spellCheckField),
      });
    }
  }),
];

const spellCheckTheme = EditorView.baseTheme({
  ".cm-spelling-error": {
    textDecoration: "underline dotted red",
    textDecorationSkipInk: "none",
    textDecorationThickness: "2px",
  },
});

export { spellChecker as default, spellCheckTheme };
