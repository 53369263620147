import './style/style.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './home';
import Notification from './components/notification';
import Introduction from './components/introduction';

function App() {
  return (
    <>
      <BrowserRouter>      
        <Routes>
          <Route path="/introduction" element={<Introduction/>}/>
          <Route path="/editor" element={<Home />} />
          <Route path="/notification" element={<Notification />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
