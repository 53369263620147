import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { emitToolbarEvent } from './extensions/toolbar/utils/analytics.ts';
import { setSectionHeadingLevel } from './extensions/toolbar/sections.ts';
import { FaBold, FaItalic, FaUnderline, FaList, FaListOl, FaOutdent, FaIndent, FaTimes, FaLink, FaTag, FaBook, FaAngleDown } from "react-icons/fa";
import { IoIosUndo, IoIosRedo } from "react-icons/io";
import { ImOmega } from "react-icons/im";

const levels = new Map([
  ['text', 'Normal text'],
  ['section', 'Section'],
  ['subsection', 'Subsection'],
  ['subsubsection', 'Subsubsection'],
  ['paragraph', 'Paragraph'],
  ['subparagraph', 'Subparagraph'],
]);

const levelsEntries = [...levels.entries()];

const symbols = {
  'α': '\\alpha', 'β': '\\beta', 'γ': '\\gamma', 'δ': '\\delta', 'ε': '\\epsilon',
  'Ζ': '\\zeta', 'η': '\\eta', 'θ': '\\theta', '𝜗': '\\vartheta', 'ι': '\\iota',
  'λ': '\\lambda', 'μ': '\\mu', 'ν': '\\nu', 'κ': '\\kappa', 'ξ': '\\xi', 'Ω': '\\Omega',
  'π': '\\pi', 'ϖ': '\\varpi', 'ρ': '\\rho', 'ϱ': '\\varrho', 'σ': '\\sigma', 'S': '\\varsigma', 'T': '\\tau', 'υ': '\\upsilon',
};

const categorizedSymbols = {
  Greek: [
    { symbol: "α", name: "\\alpha" }, { symbol: "ε", name: "\\epsilon" }, { symbol: "β", name: "\\beta" },
    { symbol: "γ", name: "\\gamma" }, { symbol: "δ", name: "\\delta" }, { symbol: "ε", name: "\\varepsilon" },
    { symbol: "Ζ", name: "\\zeta" }, { symbol: "η", name: "\\eta" }, { symbol: "θ", name: "\\theta" },
    { symbol: "𝜗", name: "\\vartheta" }, { symbol: "ι", name: "\\iota" }, { symbol: "λ", name: "\\lambda" },
    { symbol: "μ", name: "\\mu" }, { symbol: "ν", name: "\\nu" }, { symbol: "κ", name: "\\kappa" },
    { symbol: "ξ", name: "\\xi" }, { symbol: "π", name: "\\pi" }, { symbol: "ϖ", name: "\\varpi" },
    { symbol: "ρ", name: "\\rho" }, { symbol: "ϱ", name: "\\varrho" }, { symbol: "σ", name: "\\sigma" },
    { symbol: "S", name: "\\varsigma" }, { symbol: "T", name: "\\tau" }, { symbol: "υ", name: "\\upsilon" },
    { symbol: "φ", name: "\\phi" }, { symbol: "X", name: "\\chi" }, { symbol: "ψ", name: "\\psi" },
    { symbol: "3", name: "\\omega" }, { symbol: "Γ", name: "\\Gamma" }, { symbol: "Δ", name: "\\Delta" },
    { symbol: "Θ", name: "\\Theta" }, { symbol: "Λ", name: "\\Lambda" }, { symbol: "Ξ", name: "\\Xi" },
    { symbol: "Π", name: "\\Pi" }, { symbol: "Σ", name: "\\Sigma" }, { symbol: "Υ", name: "\\Upsilon" },
    { symbol: "Ψ", name: "\\Psi" }, { symbol: "Ω", name: "\\Omega" }
  ],
  Math: [
    { symbol: '+', name: 'plus' }, { symbol: '-', name: 'minus' }, { symbol: '=', name: 'equals' },
    { symbol: '×', name: 'times' }, { symbol: '÷', name: 'divide' }, { symbol: '∑', name: 'sum' },
    { symbol: '∏', name: 'product' }, { symbol: '∫', name: 'integral' }, { symbol: '√', name: 'square root' }
  ],
  Function: [
    { symbol: 'sec', name: "\\sec" }, { symbol: 'sin', name: "\\sin" }, { symbol: 'tan', name: "\\tan" },
    { symbol: 'tanh', name: "\\tanh" }, { symbol: 'sinh', name: "\\sinh" }, { symbol: 'arccos', name: "\\arccos" },
    { symbol: 'arcsin', name: "\\arcsin" }, { symbol: 'arctan', name: "\\arctan" }, { symbol: 'arg', name: "\\arg" },
    { symbol: 'COS', name: "\\cos" }, { symbol: 'cosh', name: "\\cosh" }, { symbol: 'cot', name: "\\cot" },
    { symbol: 'coth', name: "\\coth" }, { symbol: 'CSC', name: "\\csc" }, { symbol: 'deg', name: "\\deg" },
    { symbol: 'det', name: "\\det" }, { symbol: 'dim', name: "\\dim" }, { symbol: 'exp', name: "\\exp" },
    { symbol: 'gcd', name: "\\gcd" }, { symbol: 'hom', name: "\\hom" }, { symbol: 'inf', name: "\\inf" },
    { symbol: 'ker', name: "\\ker" }, { symbol: 'lg', name: "\\lg" }, { symbol: 'lim', name: "\\lim" },
    { symbol: 'liminf', name: "\\liminf" }, { symbol: 'limsup', name: "\\limsup" }, { symbol: 'In', name: "\\ln" },
    { symbol: 'log', name: "\\log" }, { symbol: 'max', name: "\\max" }, { symbol: 'min', name: "\\min" },
    { symbol: 'Pr', name: "\\Pr" }, { symbol: 'sup', name: "\\sup" }
  ],
  Logic: [
    { symbol: '∀', name: 'for all' }, { symbol: '∃', name: 'exists' }, { symbol: '¬', name: 'not' },
    { symbol: '∧', name: 'and' }, { symbol: '∨', name: 'or' }, { symbol: '⊃', name: 'implies' },
    { symbol: '⊥', name: 'bottom' }, { symbol: '⊤', name: 'top' }, { symbol: '⊢', name: 'proves' },
    { symbol: '⊨', name: 'models' }
  ],
  Relational_and_set: [
    { symbol: "=", name: "Equals" }, { symbol: "≠", name: "Not equal to" }, { symbol: "<", name: "Less than" },
    { symbol: ">", name: "Greater than" }, { symbol: "≤", name: "Less than or equal to" },
    { symbol: "≥", name: "Greater than or equal to" }, { symbol: "≈", name: "Approximately equal to" },
    { symbol: "∼", name: "Similar to" }, { symbol: "≡", name: "Identical to" },
    { symbol: "≃", name: "Asymptotically equal to" }, { symbol: "≺", name: "Precedes" },
    { symbol: "≻", name: "Succeeds" }, { symbol: "⊂", name: "Proper subset of" },
    { symbol: "⊃", name: "Proper superset of" }, { symbol: "⊆", name: "Subset of" },
    { symbol: "⊇", name: "Superset of" }, { symbol: "∅", name: "Empty set" }, { symbol: "ℕ", name: "Set of natural numbers" },
    { symbol: "ℤ", name: "Set of integers" }, { symbol: "ℚ", name: "Set of rational numbers" },
    { symbol: "ℝ", name: "Set of real numbers" }, { symbol: "ℂ", name: "Set of complex numbers" },
    { symbol: "∪", name: "Union" }, { symbol: "∩", name: "Intersection" }, { symbol: "\\", name: "Set difference" },
    { symbol: "⊆", name: "Subset" }, { symbol: "⊇", name: "Superset" }, { symbol: "∈", name: "Element of" },
    { symbol: "∉", name: "Not an element of" }, { symbol: "∋", name: "Contains as an element" },
    { symbol: "∌", name: "Does not contain as element" }, { symbol: "∖", name: "Set minus" },
    { symbol: "⋂", name: "Intersection over family" }, { symbol: "⋃", name: "Union over family" },
    { symbol: "∏", name: "Product over family" }, { symbol: "∑", name: "Sum over family" }
  ],
  Arrows: [
    { symbol: '→', name: 'Right arrow' }, { symbol: '←', name: 'Left arrow' }, { symbol: '↑', name: 'Up arrow' },
    { symbol: '↓', name: 'Down arrow' }, { symbol: '↔', name: 'Left-right arrow' }, { symbol: '↕', name: 'Up-down arrow' },
    { symbol: '⇒', name: 'Double right arrow' }, { symbol: '⇐', name: 'Double left arrow' }, { symbol: '⇑', name: 'Double up arrow' },
    { symbol: '⇓', name: 'Double down arrow' }, { symbol: '⇔', name: 'Double left-right arrow' },
    { symbol: '↦', name: 'Right arrow with tail' }, { symbol: '↤', name: 'Left arrow with tail' },
    { symbol: '↥', name: 'Up arrow with tail' }, { symbol: '↧', name: 'Down arrow with tail' },
    { symbol: '↩', name: 'Left arrow with hook' }, { symbol: '↪', name: 'Right arrow with hook' },
    { symbol: '↫', name: 'Left arrow with loop' }, { symbol: '↬', name: 'Right arrow with loop' },
    { symbol: '↱', name: 'Upwards arrow from bar' }, { symbol: '↲', name: 'Downwards arrow from bar' },
    { symbol: '↳', name: 'Right arrow with corner down' }, { symbol: '↴', name: 'Down arrow with corner left' },
    { symbol: '↵', name: 'Downwards arrow with hook' }, { symbol: '↶', name: 'Anticlockwise open circle arrow' },
    { symbol: '↷', name: 'Clockwise open circle arrow' }, { symbol: '↺', name: 'Anticlockwise circle arrow' },
    { symbol: '↻', name: 'Clockwise circle arrow' }, { symbol: '⤴', name: 'Arrow pointing rightwards then curving upwards' },
    { symbol: '⤵', name: 'Arrow pointing rightwards then curving downwards' }, { symbol: '⬆', name: 'Upwards white arrow' },
    { symbol: '⬇', name: 'Downwards white arrow' }, { symbol: '⬈', name: 'North east arrow' },
    { symbol: '⬉', name: 'North west arrow' }, { symbol: '⬊', name: 'South east arrow' },
    { symbol: '⬋', name: 'South west arrow' }, { symbol: '⇆', name: 'Left right arrow with stroke' },
    { symbol: '⇇', name: 'Left double arrow with stroke' }, { symbol: '⇈', name: 'Up double arrow with stroke' },
    { symbol: '⇊', name: 'Down double arrow with stroke' }, { symbol: '⇉', name: 'Right double arrow with stroke' },
    { symbol: '⤌', name: 'Leftwards dashed arrow' }, { symbol: '⤍', name: 'Rightwards dashed arrow' },
    { symbol: '⤎', name: 'Upwards dashed arrow' }, { symbol: '⤏', name: 'Downwards dashed arrow' },
    { symbol: '⇝', name: 'Right wavy arrow' }, { symbol: '⇜', name: 'Left wavy arrow' },
    { symbol: '⇝', name: 'Right wavy arrow' }, { symbol: '⇞', name: 'Upwards double arrow' },
    { symbol: '⇟', name: 'Downwards double arrow' }, { symbol: '⇸', name: 'Long rightwards squiggle arrow' },
    { symbol: '⇠', name: 'Leftwards double arrow' }, { symbol: '⇡', name: 'Upwards double arrow' },
    { symbol: '⇢', name: 'Rightwards double arrow' }, { symbol: '⇣', name: 'Downwards double arrow' }
  ],
};

const refOptions = {
  '\\ref{}': 'insertRef',
  '\\eqref{}': 'insertEqref',
  '\\pageref{}': 'insertPageref'
};

export const ToolbarButton = function ToolbarButton({
  view,
  id,
  className,
  label,
  command,
  active = false,
  disabled,
  icon,
  shortcut,
  handleSymbolClick, // Prop to handle symbol click
  handleRefOptionSelect, // Prop to handle ref option select

}) {
  const [showSymbols, setShowSymbols] = useState(false);
  const [showRefOptions, setShowRefOptions] = useState(false); // State to control ref dropdown
  const [modalShow, setModalShow] = useState(false);
  const dropdownRef = useRef(null); // Create a ref for the dropdown container


  const handleClickOutsideDropdown = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowSymbols(false);
      setShowRefOptions(false); // Hide tag options when clicking outside

    }
  }, []);

  useEffect(() => {
    if (showSymbols || showRefOptions) {
      document.addEventListener('mousedown', handleClickOutsideDropdown);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
    };
  }, [showSymbols, showRefOptions, handleClickOutsideDropdown]);


  const handleMouseDown = useCallback(event => {
    event.preventDefault()
  }, [])

  const handleClick = useCallback(
    event => {
      emitToolbarEvent(view, id)
      if (command) {
        event.preventDefault()
        command(view)
        view.focus()
      }
      if (icon === "omega") {
        setShowSymbols(!showSymbols);
      }
      if (icon === "tag") {
        setShowRefOptions(!showRefOptions);
      }
    },
    [command, view, id, icon, showSymbols]
  )

  const handleSymbolSelect = useCallback(symbol => {
    handleSymbolClick(symbol); // Call prop function with symbol data
    setShowSymbols(false);
  }, [handleSymbolClick]);

  const getSymbolName = symbol => symbols[symbol];
  const getRefName = option => refOptions[option];

  const handleMoreSymbolsClick = () => {
    setModalShow(true);
  }

  const handleRefSelect = useCallback(option => {
    handleRefOptionSelect(option); // Send selected ref option to parent
    setShowRefOptions(false);
  }, [handleRefOptionSelect]);

  const handleMouseEnter = useCallback(() => {
    if (icon === "tag") {
      setShowRefOptions(true);
    }
  }, [icon]);

  const buttons = (
    <>
      <button
        title={label}
        onClick={!disabled ? handleClick : undefined}
        // onMouseDown={handleMouseDown}
        // onMouseEnter={handleMouseEnter} // Handle hover event for tag icon
        className={className}>
        {
          icon === "undo" ? <IoIosUndo /> :
            icon === "repeat" ? <IoIosRedo /> :
              icon === "bold" ? <FaBold /> :
                icon === "italic" ? <FaItalic /> :
                  icon === "underline" ? <FaUnderline /> :
                    icon === "link" ? <FaLink /> :
                      icon === "list-ul" ? <FaList /> :
                        icon === "list-ol" ? <FaListOl /> :
                          icon === "outdent" ? <FaOutdent /> :
                            icon === "indent" ? <FaIndent /> :
                              icon === "tag" ? <><FaTag /> <FaAngleDown className='downlcons' /></> :
                                icon === "book" ? <FaBook /> :
                                  icon === "omega" ? <><ImOmega /> <FaAngleDown className='downlcons' /></> :
                                    ""
        }
        {showSymbols && icon === "omega" && (
          <div ref={dropdownRef} className="symbols-dropdown">
            {Object.keys(symbols).map(symbol => (
              <div
                key={symbol}
                className="symbol-item"
                onMouseDown={e => e.preventDefault()}
                onClick={() => handleSymbolSelect(symbol)}
                title={getSymbolName(symbol)} // Tooltip showing the name of the symbol
              >
                {symbol}
              </div>
            ))}
            <button className='symbols-modal' onClick={handleMoreSymbolsClick}>
              More Symbols
            </button>
          </div>
        )}
        {showRefOptions && icon === "tag" && (
          <div ref={dropdownRef} className="ref-dropdown">
            {Object.keys(refOptions).map(option => (
              <div
                key={option}
                className="ref-item"
                onMouseDown={e => e.preventDefault()}
                onClick={() => handleRefSelect(option)}
                title={getRefName(option)} // Tooltip showing the name of the symbol
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </button>
      <SymbolModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        handleSymbolSelect={handleSymbolSelect}
      />
    </>
  )
  if (!label) {
    return buttons
  }
  return (
    buttons
  )
}

export const Toolbardropdown = function Toolbardropdown({ view, id, command }) {
  const handleClick = useCallback(
    event => {
      emitToolbarEvent(view, 'section-level-change')
      setSectionHeadingLevel(view, event.target.value)
      view.focus()
    },
    [command, view, id]
  )

  const SelectDropdown = (
    <select id="contentTypeDropdown" onChange={handleClick} className="div-border">
      {levelsEntries.map(([level, label]) =>
        <option value={level} key={label}>{label}</option>
      )}
    </select>
  )
  return (SelectDropdown)
}

// Modal Component
export const SymbolModal = function SymbolModal({ show, onHide, handleSymbolSelect }) {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredSymbols = Object.entries(categorizedSymbols).reduce((acc, [category, symbols]) => {
    const filtered = symbols.filter(({symbol, name}) => 
      symbol.toLowerCase().includes(searchTerm.toLowerCase()) || 
      name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filtered.length > 0) {
      acc[category] = filtered;
    }
    return acc;
  }, {});

  return (
    <div className={`modal-outer confirmation-popup ${show ? 'active' : ''}`}>
      <div className="modal">
        <div className="modal-header">
          More Symbols
          <button className="closebtn" title="Close" onClick={onHide}><FaTimes /></button>
        </div>
        <div className="modal-search">
          <input
            type="text"
            placeholder="Search symbols..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="symbol-search-input"
          />
        </div>
        <div className="modal-body">
          {Object.keys(filteredSymbols).map(category => (
            <Row key={category}>
              <Col>
                <h5>{category}</h5>
                {filteredSymbols[category].map(({ symbol, name }, index) => (
                  <div
                    key={`${category}-${symbol}-${index}`}
                    className="symbol-item"
                    onClick={() => handleSymbolSelect(symbol)}
                    style={{ cursor: 'pointer', display: 'inline-block', margin: '5px' }}
                    title={name}
                  >
                    {symbol}
                  </div>
                ))}
              </Col>
            </Row>
          ))}
        </div>
        <div className="modal-footer">
          <button className="primary" onClick={onHide} style={{width:'80px'}}>Close</button>
        </div>
      </div>
    </div>
  );
}

