import React, { useEffect, useRef, useContext, createContext, useState, useCallback } from 'react';
import { basicSetup } from 'codemirror';
import { EditorState, StateEffect, StateField, RangeSetBuilder, Transaction } from '@codemirror/state';
import { EditorView, keymap, Decoration, WidgetType, MatchDecorator, ViewPlugin, ViewUpdate } from '@codemirror/view';
import { LaTeXLanguage } from './languages/latex/latex-language.ts'
import { LanguageSupport, } from '@codemirror/language'
import { linter, lintGutter } from '@codemirror/lint';
import { indentWithTab } from "@codemirror/commands"
import { openAutocomplete } from './languages/latex/open-autocomplete.ts'
import { latexIndentService } from './languages/latex/latex-indent-service.ts'
import { shortcuts } from './languages/latex/shortcuts.ts';
import { html } from '@codemirror/lang-html';
import axios from 'axios';
import {
  argumentCompletionSources,
  explicitCommandCompletionSource,
  inCommandCompletionSource,
  beginEnvironmentCompletionSource,
} from './languages/latex/complete.ts'
import { isVisual } from './extensions/visual/visual.ts'
import { metadata } from './languages/latex/metadata.ts'
import { documentCommands } from './languages/latex/document-commands.ts'
import { documentEnvironmentNames } from './languages/latex/document-environment-names.ts'
import LintWorker from './languages/latex/linter/latex-linter.worker.js'
import { ToolbarItems } from './toolbar-item.js';
import { FaRegFilePdf, FaRegComment, FaEllipsisH, } from "react-icons/fa";
import { Button } from 'react-bootstrap';
import { styleEditing, referanceOrdering, clearCacheFiles, CleanupEditor, iRefEditor, XMPCreation, issueUpdation} from './button-function.js';
import { toastError, toastSuccess } from '../toaster';
import moment from 'moment';
import pathconfig from '../../pathconfig.json';
import packjson from '../../../package.json';
import { useAppContextProvider } from '../../contextapi';
import spellCheckExtension, { spellCheckTheme } from './extension/spellChecker.js';
import { CommentUpdateFunction } from './button-function.js';
const lintWorker = new LintWorker();
let userDetails;
const completionSources = [
  ...argumentCompletionSources,
  inCommandCompletionSource,
  explicitCommandCompletionSource,
  beginEnvironmentCompletionSource
]
const autocompleteTheme = EditorView.baseTheme({
  '.cm-tooltip.cm-tooltip-autocomplete': {
    // shift the tooltip, so the completion aligns with the text
    marginLeft: '-4px',
  },
  '&light .cm-tooltip.cm-tooltip-autocomplete, &light .cm-tooltip.cm-completionInfo':
  {
    border: '1px lightgray solid',
    background: '#fefefe',
    color: '#111',
    boxShadow: '2px 3px 5px rgb(0 0 0 / 20%)',
  },
  '&dark .cm-tooltip.cm-tooltip-autocomplete, &dark .cm-tooltip.cm-completionInfo':
  {
    border: '1px #484747 solid',
    boxShadow: '2px 3px 5px rgba(0, 0, 0, 0.51)',
    background: '#25282c',
    color: '#c1c1c1',
  },

  // match editor font family and font size, so the completion aligns with the text
  '.cm-tooltip.cm-tooltip-autocomplete > ul': {
    fontFamily: 'var(--source-font-family)',
    fontSize: 'var(--font-size)',
  },
  '.cm-tooltip.cm-tooltip-autocomplete li[role="option"]': {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: 1.4, // increase the line height from default 1.2, for a larger target area
    outline: '1px solid transparent',
  },
  '&light .cm-tooltip.cm-tooltip-autocomplete li[role="option"]:hover': {
    outlineColor: '#abbffe',
    backgroundColor: 'rgba(233, 233, 253, 0.4)',
  },
  '&dark .cm-tooltip.cm-tooltip-autocomplete li[role="option"]:hover': {
    outlineColor: 'rgba(109, 150, 13, 0.8)',
    backgroundColor: 'rgba(58, 103, 78, 0.62)',
  },
  '.cm-tooltip.cm-tooltip-autocomplete ul li[aria-selected]': {
    color: 'inherit',
  },
  '&light .cm-tooltip.cm-tooltip-autocomplete ul li[aria-selected]': {
    background: '#cad6fa',
  },
  '&dark .cm-tooltip.cm-tooltip-autocomplete ul li[aria-selected]': {
    background: '#3a674e',
  },
  '.cm-completionMatchedText': {
    textDecoration: 'none', // remove default underline,
  },
  '&light .cm-completionMatchedText': {
    color: '#2d69c7',
  },
  '&dark .cm-completionMatchedText': {
    color: '#93ca12',
  },
  '.ol-cm-completionType': {
    paddingLeft: '1em',
    paddingRight: 0,
    width: 'auto',
    fontSize: '90%',
    fontFamily: 'var(--source-font-family)',
    opacity: '0.5',
  },
  '.cm-completionInfo .ol-cm-symbolCompletionInfo': {
    margin: 0,
    whiteSpace: 'normal',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  '.cm-completionInfo .ol-cm-symbolCharacter': {
    fontSize: '32px',
  },
})
const trackChangesTheme = EditorView.baseTheme({
  '.cm-line': {
    overflowX: 'hidden', // needed so the callout elements don't overflow (requires line wrapping to be on)
  },
  '&light .ol-cm-change-i': {
    backgroundColor: '#2c8e304d',
  },
  '&dark .ol-cm-change-i': {
    backgroundColor: 'rgba(37, 107, 41, 0.15)',
  },
  '&light .ol-cm-change-c': {
    backgroundColor: '#f3b1114d',
  },
  '&dark .ol-cm-change-c': {
    backgroundColor: 'rgba(194, 93, 11, 0.15)',
  },
  '.ol-cm-change': {
    padding: 'var(--half-leading, 0) 0',
  },
  '.ol-cm-change-d': {
    borderLeft: '2px dotted #c5060b',
    marginLeft: '-1px',
  },
  '.ol-cm-change-callout': {
    position: 'relative',
    pointerEvents: 'none',
    padding: 'var(--half-leading, 0) 0',
  },
  '.ol-cm-change-callout-inner': {
    display: 'inline-block',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100vw',
    borderBottom: '1px dashed black',
  },
  // disable callout line in Firefox
  '@supports (-moz-appearance:none)': {
    '.ol-cm-change-callout-inner': {
      display: 'none',
    },
  },
  '.ol-cm-change-callout-i .ol-cm-change-callout-inner': {
    borderColor: '#2c8e30',
  },
  '.ol-cm-change-callout-c .ol-cm-change-callout-inner': {
    borderColor: '#f3b111',
  },
  '.ol-cm-change-callout-d .ol-cm-change-callout-inner': {
    borderColor: '#c5060b',
  }
})

const changeMark = (text, meta) => Decoration.mark({
  tagName: 'span',
  class: 'ol-cm-change ol-cm-change-i',
  attributes: { 'data-text': text, 'data-meta': JSON.stringify(meta) }
});

const commentMark = (comment, cid, commentmeta) => Decoration.mark({
  tagName: 'span',
  class: 'ol-cm-change ol-cm-change-c',
  attributes: { 'data-comment': comment, 'data-id': cid, 'data-meta': JSON.stringify(commentmeta) },
});

class ChangeDeletedWidget extends WidgetType {
  constructor(text) {
    super()
    this.text = text;
  }

  toDOM() {
    const widget = document.createElement('span')
    widget.classList.add('ol-cm-change')
    widget.classList.add('ol-cm-change-d')
    // widget.attributes.add({ 'data-deleted-text': this.text });

    return widget
  }

  eq() {
    return true
  }
}

const deleteMark = (text, metadata, id, opType) => Decoration.widget({
  widget: new ChangeDeletedWidget(text, metadata),
  class: 'ol-cm-change ol-cm-change-d',
  side: 1,
  id,
  metadata: JSON.stringify(metadata),
  text,
  opType
})

// Change tracking effect and field
const insertionEffect = StateEffect.define();
const deletionEffect = StateEffect.define();
const commentEffect = StateEffect.define();
const clearCommentEffect = StateEffect.define();
const clearEffect = StateEffect.define();

const changeField = StateField.define({
  create(state) {
    if (state.doc.length > 0)
      return loadTrackChanges(state);
    else
      return Decoration.none;
  },
  update(decorations, tr) {

    let builder = new RangeSetBuilder();

    // Map existing decorations through the changes
    decorations = decorations.map(tr.changes);

    // Collect new decorations from effects
    let newDecorations = [];
    let clearDecorations = [];
    for (let effect of tr.effects) {
      if (effect.is(insertionEffect)) {
        newDecorations.push({ from: effect.value.from, to: effect.value.to, opType: 'i', decoration: changeMark(effect.value.text, { Username: userDetails?.username, role: userDetails.role, Date_Time: new Date().toLocaleString(), cmdtype: 'trackchanges', status: '' }), direction: '' });
      } else if (effect.is(deletionEffect)) {

        newDecorations.push({ from: effect.value.from, to: effect.value.to, opType: 'd', decoration: deleteMark(effect.value.text, { Username: userDetails?.username, role: userDetails.role, Date_Time: new Date().toLocaleString(), cmdtype: 'trackchanges', status: '' }), direction: effect.value.direction });
      }
      else if (effect.is(commentEffect)) {
        newDecorations.push({ from: effect.value.from, to: effect.value.to, opType: 'c', decoration: commentMark(effect.value.comment, effect.value.cid, effect.value.commentmeta), direction: '' });
      }
      else if (effect.is(clearCommentEffect)) {
        // newDecorations = newDecorations.filter(deco => !(deco.from === effect.value.from && deco.to === effect.value.to && deco.decoration.spec.class === 'ol-cm-change ol-cm-change-c'));
        clearDecorations.push({ from: effect.value.from, to: effect.value.to, opType: 'rc', direction: '' })
      }
      else if (effect.is(clearEffect)) {
        // Clear decorations within the range specified by the clearEffect
        clearDecorations.push({ from: effect.value.from, to: effect.value.to, opType: 'r', direction: '' });
      }
    }

    // Preserve existing decorations
    decorations.between(0, tr.newDoc.length, (from, to, value) => {
      if (value.spec.class != undefined) {
        let temptype = '';
        if (value.spec.class.includes('ol-cm-change-i'))
          temptype = 'i';
        else if (value.spec.class.includes('ol-cm-change-c'))
          temptype = 'c';
        else if (value.spec.class.includes('ol-cm-change-d'))
          temptype = 'd';

        if (temptype == 'd') {
          let res = clearDecorations.filter(d => d.to == from || d.from == from);
          if (res.length > 0) {
            let newtextlength = res[0].to - res[0].from;
            if (newtextlength > 0 && newtextlength != value.spec.text.length) {
              let str = value.spec.text;
              let start = 0;
              let end = newtextlength;
              let newStr = str.substring(0, start) + str.substring(end);
              value.spec.text = newStr;
              newDecorations.push({
                from,
                to,
                opType: temptype,
                decoration: value
                , direction: ''
              });
            }
          }
          else {
            res = clearDecorations.filter(d => d.to == from && d.from == from)
            if (res.length == 0) {
              newDecorations.push({
                from,
                to,
                opType: temptype,
                decoration: value,
                direction: ''
              });
            }
          }
        }
        else if (temptype == 'i') {
          let res = clearDecorations.filter(d => d.from == from && d.to == to);
          if (res == undefined || res.length == 0) {
            let newStr = tr.startState.doc.sliceString(from, to);
            if (newStr.length > 0) {
              let str = value.spec.attributes['data-text'];
              if (str != newStr)
                value.spec.attributes['data-text'] = newStr;
              newDecorations.push({
                from,
                to,
                opType: temptype,
                decoration: value,
                direction: ''
              });
            }
            else {
              newDecorations.push({
                from,
                to,
                opType: temptype,
                decoration: value,
                direction: ''
              });
            }
          }
        }
        else {
          let res = clearDecorations.filter(d => d.from == from && d.to == to);
          if ((res == undefined || res.length == 0) && (to > 0)) {
            newDecorations.push({
              from,
              to,
              opType: temptype,
              decoration: value,
              direction: ''
            });
          }
        }

      }
    });

    // Sort decorations by the `from` position
    newDecorations = newDecorations.sort((a, b) => {
      if (a.from === b.from && a.opType === b.opType) {
        return a.to - b.to;
      }
      return a.from - b.from;
    });

    // Add sorted decorations to the builder, ensuring no duplicates
    let lastFrom = -1, lastTo = -1, lastDecoration = {};
    let finalDecorations = [];
    for (let { from, to, decoration, opType, direction } of newDecorations) {
      if (from !== lastFrom || to !== lastTo || decoration !== lastDecoration) {
        finalDecorations.push({ from, to, decoration, opType, direction });
        lastFrom = from;
        lastTo = to;
        lastDecoration = decoration;

      }
    }

    lastFrom = -1;
    lastTo = -1;
    let ind = 0;
    let lasttype = '';
    let text = '';
    for (let { from, to, decoration, opType, direction } of finalDecorations) {
      if (ind === 0) {
        lastFrom = from;
        lastTo = to;
        lastDecoration = decoration;
        lasttype = opType;
        text = textmap(opType, decoration, text);
      }
      else if (opType !== lasttype) {
        let mdata = createmeta(lastDecoration, lastFrom, lastTo, lasttype)
        builder = addbuilder(builder, lasttype, lastFrom, lastTo, text, mdata, lastDecoration);
        lastFrom = from;
        lastTo = to;
        lastDecoration = decoration;;
        lasttype = opType;
        text = textmap(opType, decoration, '');
      }
      else if (opType === 'd' && lasttype === 'd') {
        if (lasttype === 'd') {
          if (lastFrom === from) {

            text = textmap(opType, decoration, text, direction);
          } else if (lastTo > from) {
            text = textmap(opType, decoration, text, direction);
          } else {
            let mdata = createmeta(lastDecoration, lastFrom, lastTo, lasttype)
            builder = addbuilder(builder, lasttype, lastFrom, lastTo, text, mdata, lastDecoration);
            lastFrom = from;
            lastTo = to;
            lastDecoration = decoration;
            lasttype = opType;
            text = textmap(opType, decoration, '');
          }
        }
      }
      else if (opType === 'i' && lasttype === 'i') {
        if (lasttype === 'i') {
          if (lastFrom === from || lastTo >= from) {
            text = textmap(opType, decoration, text);
            if (lastTo < to)
              lastTo = to;
          } else {
            let mdata = createmeta(lastDecoration, lastFrom, lastTo, lasttype)
            builder = addbuilder(builder, lasttype, lastFrom, lastTo, text, mdata, lastDecoration);
            lastFrom = from;
            lastTo = to;
            lastDecoration = decoration;
            lasttype = opType;
            text = textmap(opType, decoration, '');
          }
        }
      }
      else {
        let mdata = createmeta(lastDecoration, lastFrom, lastTo, lasttype)
        builder = addbuilder(builder, lasttype, lastFrom, lastTo, text, mdata, lastDecoration);
        lastFrom = from;
        lastTo = to;
        lastDecoration = decoration;
        lasttype = opType;
        text = textmap(opType, decoration, '');
      }
      ind = ind + 1;
      if (finalDecorations.length === ind) {

        let mdata = createmeta(lastDecoration, lastFrom, lastTo, lasttype)
        builder = addbuilder(builder, lasttype, lastFrom, lastTo, text, mdata, lastDecoration);
      }
    }
    saveTrackChanges(tr.startState);
    return builder.finish();
  },
  provide: field => EditorView.decorations.from(field),
});

const createmeta = (decoration, from, to, type) => {
  const meta = {
    Username: '',
    role: '',
    Date_Time: new Date().toLocaleString(),
    cmdtype: '',
    status: ''
  }
  let metadata = {};
  if (type === 'd') {
    metadata = JSON.parse(decoration.spec.metadata)
  }
  else {
    metadata = JSON.parse(decoration.spec.attributes['data-meta'] ? decoration.spec.attributes['data-meta'] : '{}');
  }

  if (type === 'c') {
    meta.from = from;
    meta.to = to;
    meta.Username = metadata.Username;
    meta.role = '';
    meta.Date_Time = metadata.Date_Time;
    meta.cmdtype = 'Comments';
  } else {
    meta.Username = metadata.Username;
    meta.role = metadata.role;
    meta.Date_Time = metadata.Date_Time;
    meta.cmdtype = 'trackchanges';
    meta.status = '';
    meta.from = from;
    meta.to = to;
  }
  return meta;
}

const textmap = (type, decoration, text, direction = '') => {
  if (type === 'd') {
    if (direction === 'B') {
      text = decoration.spec.text + text;
    }
    else
      text = text + decoration.spec.text;
  }
  else if (type === 'i')
    text = text + decoration.spec.attributes['data-text']
  else if (type === 'c')
    text = text + decoration.spec.attributes['data-comment']
  return text;
}

const addbuilder = (builder, type, lastFrom, lastTo, text, mdata, deco) => {
  // debugger
  if (type === 'd')
    builder.add(lastFrom, lastFrom, deleteMark(text, mdata, '', type));
  else if (type === 'i')
    builder.add(lastFrom, lastTo, changeMark(text, mdata));
  else if (type === 'c')
    builder.add(lastFrom, lastTo, commentMark(text, deco.decoration !== undefined ? deco.decoration.spec.attributes['data-id'] : deco.spec.attributes['data-id'], mdata));
  return builder;
}

// Function to deserialize decorations
function deserializeDecorations(parsedData, docLength) {
  let decorations = [];

  // Parse and collect all decorations
  for (let deco of parsedData) {
    if (deco.type === 'insertion') {
      decorations.push({
        from: deco.from,
        to: deco.to,
        type: 'i',
        Username: deco.Username,
        text: deco.text,
        status: deco.status,
        Date_Time: deco.Date_Time,
        cmdtype: deco.cmdtype,
        decoration: deco.decoration,
        startSide: 0
      });
    } else if (deco.type === 'deletion') {
      decorations.push({
        from: deco.from,
        to: deco.to,
        type: 'd',
        Username: deco.Username,
        text: deco.text,
        status: deco.status,
        Date_Time: deco.Date_Time,
        cmdtype: deco.cmdtype,
        decoration: deco.decoration,
        startSide: 0
      });
    } else if (deco.cmdtype === 'Comments') {
      decorations.push({
        from: deco.from,
        to: deco.to,
        decoration: commentMark(deco.Cmdcomtents, deco.cid, {
          Username: deco.Username,
          text: deco.Cmdcomtents,
          status: '',
          Date_Time: deco.Date_Time,
          cmdtype: deco.cmdtype
        }),
        type: 'c',
        Username: deco.Username,
        text: deco.text,
        status: deco.status,
        Date_Time: deco.Date_Time,
        cmdtype: deco.cmdtype,
        startSide: 0
      });
    }
  }

  // Sort decorations by from position and type priority
  decorations.sort((a, b) => {
    if (a.from !== b.from) {
      return a.from - b.from;
    }
    const typePriority = { d: 1, i: 2, c: 3 };
    return typePriority[a.type] - typePriority[b.type];
  });

  let builder = new RangeSetBuilder();
  let lastFrom = -1;

  // Add sorted decorations
  for (let deco of decorations) {
    // Ensure we're not adding a decoration at a position before the last one
    if (deco.from < lastFrom) {
      continue;
    }

    let text = textmap(deco.type, deco.decoration, '');
    let mdata = createmeta(deco.decoration, deco.from, deco.to, deco.type);
    builder = addbuilder(builder, deco.type, deco.from, deco.to, text, mdata, deco);
    lastFrom = deco.from;
  }

  return builder.finish();
}

// Function to serialize decorations
function serializeDecorations(decorations, doc) {
  let serialized = [];
  let serializedcomment = [];
  decorations.between(0, doc.length, (from, to, decoration) => {
    if (decoration.spec.class.includes('ol-cm-change-i')) {
      let mdata = JSON.parse(decoration.spec.attributes['data-meta']);
      let text = decoration.spec.attributes['data-text'];
      serialized.push({
        type: 'insertion',
        from,
        to,
        decoration: decoration,
        text: text,
        Username: mdata.Username,
        Date_Time: mdata.Date_Time,
        cmdtype: mdata.cmdtype,
        status: mdata.status,
        role: mdata.role
      });
    } else if (decoration.spec.class.includes('ol-cm-change-d')) {
      let mdata = JSON.parse(decoration.spec.metadata);
      serialized.push({
        type: 'deletion',
        from,
        to,
        decoration: decoration,
        text: mdata.text,
        Username: mdata.Username,
        Date_Time: mdata.Date_Time,
        cmdtype: mdata.cmdtype,
        status: mdata.status,
        role: mdata.role
      });
    } else if (decoration.spec.class.includes('ol-cm-change-c')) {
      // serialized.push({ type: 'comment', from, to, });
      serializedcomment.push({ type: 'comment', from, to, decoration, comment: decoration.spec.attributes['data-comment'], cid: decoration.spec.attributes['data-id'], commentmeta: decoration.spec.attributes['data-meta'] });
    }
  });
  return { serialized, serializedcomment };
}

let Comment;
let TrackchangesArrayValue;
let docid;
let ActivityID;
// Function to save the track changes
function saveTrackChanges(state) {
  let doc = state.doc;
  let decorations = state.field(changeField);
  let serializedDecorations = serializeDecorations(decorations, doc);
  Comment = serializedDecorations.serializedcomment;
  TrackchangesArrayValue = serializedDecorations.serialized;
}

// Function to load the track changes
function loadTrackChanges(state) {
  let savedData = TrackchangesArrayValue;
  let commentsData = Comment;
  if (savedData === undefined)
    savedData = [];
  if (commentsData === undefined)
    commentsData = [];
  let data = [...commentsData, ...savedData];
  if (data.length === 0)
    return Decoration.none;
  else
    return deserializeDecorations(data);
}

const annotationsTheme = EditorView.baseTheme({
  '.cm-gutter-lint': {
    order: -1,
  },
})

// Example of a simple custom linter for TeX
const texLinter = linter(view => {
  let diagnostics = [];
  const docText = view.state.doc.toString();
  let errors = lintWorker.Parse(docText).errors;
  for (const error of errors) {
    diagnostics.push(
      {
        from: error.startPos,
        to: error.endPos,
        severity: error.type,
        message: error.text,
      }
    )
  }
  return diagnostics;
});
class HighlightWidget extends WidgetType {
  constructor(content, cid) {
    super();
    this.content = content;
    this.cid = cid;
  }

  eq(other) {
    return other.text === this.text && other.cid === this.cid;
  }

  toDOM() {
    let span = document.createElement("span");
    span.className = "highlighted-text";
    span.textContent = this.content;
    span.setAttribute("cid", this.cid);
    return span;
  }

  ignoreEvent() {
    return false;
  }
}

const highlightMatcher = new MatchDecorator({
  regexp: /\[([^\]]+)\]\(([^)]+)\)/g,
  decoration: match => Decoration.replace({
    widget: new HighlightWidget(match[1], match[2])
  })
});

const placeholders = ViewPlugin.fromClass(class {
  constructor(view) {
    this.placeholders = highlightMatcher.createDeco(view);
  }
  update(update) {
    this.placeholders = highlightMatcher.updateDeco(update, this.placeholders);
  }
}, {
  decorations: instance => instance.placeholders,
  provide: plugin => EditorView.atomicRanges.of(view => {
    return view.plugin(plugin)?.placeholders || Decoration.none;
  })
});

const CodeMirrorEditor = (props) => {
  const editorRef = useRef();
  const [state, setState] = useState(null);
  const [view, setView] = useState(null);
  const [lang, setLang] = useState('latex');
  const [visual, setVisual] = useState(true);
  const [fileContent, setFileContent] = useState('');
  const [trackContent, setTrackContent] = useState('')
  const [currentFile, setCurrentFile] = useState(null);
  const [stateDoc, setStateDoc] = useState({});
  const [selectedText, setSelectedText] = useState({ linenumber: 0 });
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [comments, setComments] = useState({});
  const [textwrap, setTextWrap] = useState(true);
  const prevCid = useRef(null); // Ref for previous cid value
  const buttonResize = useRef(null);
  const subChildButton = useRef(null);
  const [ActivityName, setActivityName] = useState('')
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0 });
  const [query, setQuery] = useState([]);
  const [showProgress, setShowprogress] = useState(false);
  const [progressDetails, setProgressDetails] = useState(false);
  const [ApiStatusRunning, setApiStatusRunning] = useState('')
  const { userProfile, setTrackChanges, trackAction, lineFocus, setViewDoc, authorQuery, setAuthorQuery, authorQueryChange, trackChangeBlob, linepostion, commentValue, setCommentSidebar, commentDelete, fullData, setSaveTrigger, setEditorContent, setLayout, editorStyle, readOnly, setTrackfileComment, setHighlights, setMainView, setFileContentData, setCommentValue, setChangedComment, setCloseErrorLog } = useAppContextProvider();

  const tagArray = ['\\\\textbf{', '\\\\textit{', '\\\\underline{', '\\\\href{', '\\\\begin{itemize}', '\\\\begin{enumerate}', '\\\\section{', '\\\\subsection{', '\\\\subsubsection', '\\\\paragraph', '\\\\subparagraph'];

  const handleUndo = EditorView.updateListener.of((update) => {
    if (["Editor", "Author", "Copy Editor"].includes(userProfile.role)) {
      if (update.transactions.some(tr => tr.docChanged)) {
        let trackdetails = [];
        if (TrackchangesArrayValue?.length > 0)
          trackdetails = TrackchangesArrayValue;
        update.transactions.forEach(tr => {
          let effects = [];
          const userEvent = tr.annotation(Transaction.userEvent);
          if (userEvent !== 'undo' && userEvent !== 'redo') {
            tr.changes.iterChanges((fromA, toA, fromB, toB) => {
              if (fromA !== toA) {
                if (userEvent === undefined) {
                  const deletedText = update.startState.doc.sliceString(fromA, toA);
                  effects.push(deletionEffect.of({ from: fromA, to: toA, text: deletedText, direction: '' }));
                }
                else if (userEvent.includes('delete.selection') || userEvent.includes('delete.backward') || userEvent.includes('delete.forward') || userEvent.includes('input.type')) {
                  let fres = trackdetails.filter(deco => deco?.from <= fromA && deco?.to >= toA && deco?.decoration?.spec?.class?.includes('ol-cm-change-i'))
                  if (fres == undefined || fres.length <= 0) {
                    const deletedText = update.startState.doc.sliceString(fromA, toA);
                    if (userEvent.includes('delete.backward'))
                      effects.push(deletionEffect.of({ from: fromA, to: toA, text: deletedText, direction: 'B' }));
                    else if (userEvent.includes('delete.forward'))
                      effects.push(deletionEffect.of({ from: fromA, to: toA, text: deletedText, direction: 'F' }));
                    else
                      effects.push(deletionEffect.of({ from: fromA, to: toA, text: deletedText, direction: '' }));
                  }
                }
              }
              if (fromB !== toB) {
                if (userEvent === 'deletereject') {
                  effects.push(clearCommentEffect.of({ fromB, fromB }));
                }
                else {
                  const text = update.state.doc.sliceString(fromB, toB);
                  effects.push(insertionEffect.of({ from: fromB, to: toB, text: text }));
                }
              }
            });
          } else if (userEvent == 'undo' || userEvent == 'redo') {
            tr.changes.iterChanges((fromA, toA, fromB, toB) => {
              effects.push(clearEffect.of({ from: fromA, to: toB }));
            });
          }
          if (effects.length === 2 && userEvent !== 'undo' && userEvent !== 'redo') {
            if (effects[0].value.from === effects[1].value.from) {
              let cnt = effects[1].value.text;
              let cnt1 = effects[0].value.text;
              tagArray.forEach(element => {
                //create regex pattern using element
                let pattern = new RegExp('^' + element, 'i');
                //match pattern in cnt
                let firstmatch = pattern.exec(cnt.trim());
                let secondmatch = pattern.exec(cnt1.trim());
                if (firstmatch != null) {
                  // pattern = new RegExp('//end{(?:itemize|enumerate)}$', 'i');
                  // const isMatch = pattern.test(cnt);
                  // if (isMatch) {
                  // }
                  effects.shift();
                }
                else if (secondmatch != null) {
                  let fres = trackdetails.filter(deco => deco.from <= effects[1].value.from && deco.to >= effects[1].value.to && deco.decoration.spec.class.includes('ol-cm-change-i'))
                  if (fres != undefined && fres.length > 0) {
                    let eff = clearEffect.of({ from: effects[1].value.from, to: effects[1].value.to });
                    effects = [];
                    effects.push(eff);
                  }
                  else {
                    // effects.shift();
                  }
                }
              });
            }
          }
          if (effects.length > 0)
            update.view.dispatch({ effects });
        });
      }
    }
  });

  const handleReplace = EditorView.updateListener.of((update) => {
    if (update.transactions.some(tr => tr.docChanged)) {
      update.transactions.forEach(tr => {
        const userEvent = tr.annotation(Transaction.userEvent);
        if (userEvent === 'input.replace' || userEvent === 'input.replace.all') {
          const searchText = document.querySelector('input[name="search"]').value;
          const replaceText = document.querySelector('input[name="replace"]').value;
          console.log(state, 'state')
          if (state) {
            // Step 1: Count occurrences
            const docText = fileContent;
            const regex = new RegExp(searchText, 'g');
            const count = (docText.match(regex) || []).length;
            if (count > 0) {
              // Step 2: Confirm replacement
              const userConfirmed = window.confirm(`Found ${count} occurrences. Do you want to replace them with '${replaceText}'?`);
              if (userConfirmed) {
                // Step 3: Replace text
                state.dispatch(
                  state.update({
                    changes: {
                      from: 0,
                      to: docText.length,
                      insert: docText.replace(regex, replaceText),
                    },
                  })
                );
                //setReplacementCount(count);
              }
            } else {
              alert('No occurrences found.');
            }
          }
        }
      });
    }
  });

  const latex = new LanguageSupport(LaTeXLanguage,
    [shortcuts(),
      documentCommands,
      documentEnvironmentNames,
    latexIndentService(),
    // EditorState.lineSeparator("\r\n"),
    EditorState.tabSize.of(4),
    metadata(),
    openAutocomplete(),
    ...completionSources.map(completionSource =>
      LaTeXLanguage.data.of({
        autocomplete: completionSource,
      })
    ),
      autocompleteTheme,
      changeField,
      handleUndo,
      handleReplace,
      trackChangesTheme,
    textwrap ? EditorView.lineWrapping : [],
    ]);

  useEffect(() => {
    docid = userProfile.docid;
    userDetails = userProfile;
    let a = JSON.parse(localStorage.getItem(`${docid}_init-getdetails`));
    let activityName = a.ActivtyDetails.ActivityName;
    // userProfile = a.ActivtyDetails.userDetails;
    ActivityID = a.ActivtyDetails.ActivityID;
    setActivityName(activityName);
  }, []);

  // Handle fullData changes
  useEffect(() => {
    if (fullData) {
      setFileContent(fullData.Content);
      setFileContentData(fullData.Content);
      setCurrentFile(fullData.Filename);
      if (userProfile.role === 'Author') {
        setTrackContent(fullData.Content);
      }
      // if (userProfile?.role === 'Copy Editor') {
      //   setTrackContent(props.onlineData.Content);
      // }
    }
  }, [fullData, userProfile.role]);

  // Handle font size changes
  useEffect(() => {
    if (props.fontSize && editorRef.current) {
      const editor = editorRef.current.querySelector('.cm-editor');
      if (editor) {
        editor.style.fontSize = `${props.fontSize}px`;
      }
    }
  }, [props.fontSize]);

  // Handle text wrap changes
  useEffect(() => {
    if (props.textWrap) {
      setTextWrap(props.textWrap !== 'No');
    }
  }, [props.textWrap]);

  // Handle editor style value changes
  useEffect(() => {
    if (editorStyle) {
      setFileContent(editorStyle);
      setFileContentData(editorStyle);
    }
  }, [editorStyle]);

  useEffect(() => {
    Comment = commentValue?.comment;
    TrackchangesArrayValue = trackChangeBlob;
    loadTrackChanges(state);
    if (commentValue?.comment?.length > 0) {
      const lastComment = commentValue.comment[commentValue.comment.length - 1];
      highlightCommentedText(lastComment);
      const commentIndex = commentValue.comment[commentValue.changeIndex];
      if (commentIndex) {
        addComment(view, commentIndex.from, commentIndex.to, commentIndex.selectText, commentIndex.cid, JSON.stringify(commentIndex));
      }
    }
  }, [commentValue, trackChangeBlob]);

  // Handle track content updates
  useEffect(() => {
    if (trackContent) {
      if (trackContent.length !== 0) {
        if (!editorRef.current) return;
        function processHTMLString(htmlString) {
          const trackChanges = [];
          const comments = [];
          const regex = /\\(ins|del|cmt)\[([\s\S]*?)\]\{((?:[^{}]*|\{(?:[^{}]*|\{[^{}]*\})*\})*)\}/g;
          let match;
          htmlString = htmlString.replace(/\r\n/g, '\n');
          while ((match = regex.exec(htmlString)) !== null) {
            let [fullMatch, command, jsonString, content] = match;
            let jsonStringModified = jsonString
              .replace(/itempslash"/g, '\\"')   // Adjust backslashes
              .replace(/\\_/g, '_')
              .replace(/\\\$/g, '$')
              .replace(/\\%/g, '%')
              .replace(/\\#/g, '#')
              .replace(/iocb/g, '{')
              .replace(/iccb/g, '}')
              .replace(/iosbicsb/g, '{}');

            // Attempt to parse JSON safely
            let valueObj;
            try {
              valueObj = JSON.parse(jsonStringModified);
            } catch (error) {
              console.error("JSON parsing error:", error);
              continue;
            }

            const fromPosition = match.index;
            valueObj.from = fromPosition;
            let toPosition;
            if (command === 'del') {
              htmlString = htmlString.slice(0, fromPosition) + htmlString.slice(fromPosition + fullMatch.length);
              toPosition = fromPosition;
              valueObj.to = toPosition;
              trackChanges.push(valueObj);
              regex.lastIndex = fromPosition; // Reset to allow capturing immediately following tags
            } else if (command === 'ins') {
              htmlString = htmlString.slice(0, fromPosition) + content + htmlString.slice(fromPosition + fullMatch.length);
              toPosition = fromPosition + content.length;
              valueObj.to = toPosition;
              trackChanges.push(valueObj);
              regex.lastIndex = fromPosition + content.length; // Advance normally for `ins`
            } else if (command === 'cmt') {
              htmlString = htmlString.slice(0, fromPosition) + content + htmlString.slice(fromPosition + fullMatch.length);
              toPosition = fromPosition + content.length;
              valueObj.to = toPosition;
              comments.push(valueObj);
              regex.lastIndex = fromPosition + content.length; // Advance normally for `cmt`
            }
          }
          return { modifiedHTML: htmlString, trackChanges, comments };
        }
        const result = processHTMLString(trackContent);
        console.log('Modified HTML String:\n', result.modifiedHTML);
        console.log('Extracted Array of track Objects:\n', result.trackChanges);
        console.log('Extracted Array of comment Objects:\n', result.comments)
        setTimeout(() => {
          setFileContent(result.modifiedHTML);
          setFileContentData(result.modifiedHTML);
          TrackchangesArrayValue = result.trackChanges;
          Comment = result.comments;
          setTrackfileComment(result.comments);
          setTrackContent(result.modifiedHTML)
        }, 1000);
      }
    }
  }, [trackContent]);

  // Handle stateDoc changes
  useEffect(() => {
    const stateValue = async () => {
      if (stateDoc?.doc?.lines >= 1) {
        await AQFinder(state.doc);
      }
    };
    if (stateDoc) stateValue();
  }, [stateDoc]);

  // Handle line focus changes
  useEffect(() => {
    if (lineFocus) {
      if (lineFocus?.line || lineFocus?.position) {
        focusOnLineError(lineFocus);
      } else {
        focusOnLine(lineFocus);
      }
    }
  }, [lineFocus]);

  // Handle comment and track changes
  useEffect(() => {

    if (TrackchangesArrayValue) {
      let dataChange = TrackchangesArrayValue.map((item) => {
        return {
          ...item,
          Username: item.Username?.length === 0 ? userProfile.Name : item.Username,
          role: item.role?.length === 0 ? userProfile.roleName : item.role,
          ActivityID: (item.ActivityID?.length === 0 || item.ActivityID === undefined) ? ActivityID : item.ActivityID,
          Date_Time: item.type === 'insertion'
            ? JSON.parse(item.decoration?.spec.attributes['data-meta']).Date_Time
            : JSON.parse(item.decoration?.spec.metadata).Date_Time,
          cmdtype: 'trackchanges',
          status: ''
        }
      })
      let filter = dataChange.filter((item) => item.type === 'deletion' || item.type === 'insertion')
      setTrackChanges(filter);
    }
  }, [TrackchangesArrayValue]);

  // Handle comment deletion
  useEffect(() => {
    if (commentDelete) {
      // AuthorQueryDelete(commentDelete);
      removeHighlightedText(commentDelete);
      if (Object.keys(commentValue?.comment).length > 0) {
        const updatedRange = commentValue?.comment.filter((item) => item.cid !== commentDelete.cid);
        setTimeout(() => {
          let CommentsChange = { comment: updatedRange, changeIndex: null }
          setCommentValue(CommentsChange);
        }, 100);
      }
    }
  }, [commentDelete]);

  // Handle author query changes
  useEffect(() => {
    if (authorQueryChange) {
      AuthorQueryUpdate(authorQueryChange);
    }
  }, [authorQueryChange]);

  // Handle queries
  useEffect(() => {
    if (query?.length > 0) {
      setAuthorQuery(query);
    }
  }, [query]);

  // Handle track action
  useEffect(() => {
    // debugger
    if (!trackAction || !view) return;
    if (trackAction?.status === "Accept") {
      removeComment(view, trackAction?.list?.from, trackAction?.list?.to);
    } else {
      const from = trackAction?.list?.from;
      const to = trackAction?.list?.to;
      let transaction = {};

      if (trackAction?.list?.type === 'deletion') {
        removeComment(view, trackAction?.list?.from, trackAction?.list?.to);
        transaction = view.state.update({
          changes: {
            from: from,
            to: to,
            insert: trackAction?.list?.decoration?.spec?.text
          },
          annotations: Transaction.userEvent.of('deletereject')
        });
      } else {
        transaction = view.state.update({
          changes: { from, to },
          annotations: Transaction.userEvent.of('reject')
        });
      }

      view.dispatch(transaction);
    }
  }, [trackAction, view]);


  const readOnlyRanges = EditorView.decorations.compute(['doc'], state => {
    const decorations = []

    const regex = /\\(?:(?:journal(?:volume|id|code|title|issue)|abbjournaltitle|[pe]?issn|publisher(?:name|loc)|article(?:type|id|number)|DOI|PII|(?:received|revised|accepted)|pubdate|copyright(?:statement|year|holder|type)|license)\{[^}]*\}|\b(?:received|revised|accepted)\[[^\]]*\]\{[^}]*\})/g;
    let match
    while ((match = regex.exec(state.doc.toString())) !== null) {
      decorations.push(Decoration.replace({
        widget: new ReadOnlyWidget(match[0]),
        inclusive: true,
        block: false
      }).range(match.index, match.index + match[0].length))
    }
    return Decoration.set(decorations)
  })
  class ReadOnlyWidget extends WidgetType {
    constructor(content) {
      super()
      this.content = content
    }
    eq(other) { return other.content === this.content }
    toDOM() {
      let span = document.createElement("span")
      span.className = "cm-readonly"
      span.textContent = this.content
      return span
    }
    ignoreEvent() { return true }
  }

  const readOnlyTheme = EditorView.baseTheme({
    '.cm-readonly': {
      background: '#f0f0f0',
      color: '#888',
      cursor: 'not-allowed',
      userSelect: 'none'
    }
  })

  const preventReadOnlyEdit = EditorState.transactionFilter.of(tr => {
    if (!tr.docChanged) return tr
    let changes = []
    tr.changes.iterChanges((fromA, toA, fromB, toB, inserted) => {
      let inReadOnly = false
      tr.startState.field(changeField).between(fromA, toA, (from, to, value) => {
        if (value.spec.class && value.spec.class.includes('cm-readonly')) {
          if (from < toA && to > fromA) inReadOnly = true
        }
      })
      // if (!inReadOnly) {
      //   changes.push({from: fromA, to: toA, insert: inserted})
      // }
    })
    return changes.length ? [tr, { changes }] : tr
  })

  useEffect(() => {
    if (!editorRef.current) return;
    // Conditional extensions based on role
    const readOnlyExtensions = userProfile.role === "Author"
      ? [readOnlyRanges, readOnlyTheme, preventReadOnlyEdit]
      : [];

    const startState = EditorState.create({
      doc: fileContent,
      extensions: [
        basicSetup,
        latex,
        html(),
        texLinter,
        placeholders,
        spellCheckExtension,
        spellCheckTheme,
        updateListener,
        EditorState.readOnly.of(readOnly),
        lintGutter({
          hoverTime: 0,
        }),
        annotationsTheme,
        keymap.of([indentWithTab]),
        ...readOnlyExtensions, // Conditionally applied extensions
      ],
    });

    const view = new EditorView({
      state: startState,
      parent: editorRef.current,
      dispatch: async (transaction) => {
        view.update([transaction]);
        const selection = view.state.selection;
        let text = '';
        let line = null;
        let from = null;
        let to = null;
        for (const range of selection.ranges) {
          text += view.state.doc.sliceString(range.from, range.to);
          line = view.state.doc.lineAt(range.from).number;
          from = range.from;
          to = range.to;
        }
        if (line !== "") {
          setShowCommentButton(false);
          setSelectedText({ linenumber: line, selectText: text, fromLine: from, toLine: to });
          setCommentSidebar({ selectValue: { linenumber: line, selectText: text, fromLine: from, toLine: to } });
        }
      }
    });

    let isApiCallInProgress = false;
    let lastCallTimestamp = 0;
    const DEBOUNCE_TIMEOUT = 300;

    const handleDoubleClick = async (event) => {
      const currentTime = Date.now();
      if (isApiCallInProgress || currentTime - lastCallTimestamp < DEBOUNCE_TIMEOUT) {
        return;
      }

      const pos = view.posAtCoords({ x: event.clientX, y: event.clientY });
      if (pos !== null) {
        isApiCallInProgress = true;
        lastCallTimestamp = currentTime;

        try {
          const line = view.state.doc.lineAt(pos).number;
          const cacheKey = `synctex_${line}_${userProfile.docid}`;
          const cachedResult = sessionStorage.getItem(cacheKey);

          if (cachedResult) {
            setHighlights(JSON.parse(cachedResult));
            setCloseErrorLog(true);
          } else {
            const data = {
              line: line,
              column: 1,
              docid: userProfile.docid
            }

            const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].synctexttopdf}`;
            const response = await axios.post(url, data);
            const pdfdata = response.data?.pdf;

            sessionStorage.setItem(cacheKey, JSON.stringify(pdfdata));
            setHighlights(pdfdata);
            setCloseErrorLog(true);
          }
        } finally {
          isApiCallInProgress = false;
        }
      }
    };

    editorRef.current.addEventListener('dblclick', handleDoubleClick);

    const handleContextMenu = (event) => {
      event.preventDefault();
      const { clientX, clientY } = event;
      const { left, top } = editorRef.current.getBoundingClientRect();
      const selection = view.state.selection;
      let text = '';
      for (const range of selection.ranges) {
        text += view.state.doc.sliceString(range.from, range.to);
      }
      //const selectedText1 = view.state.getSelection();
      if (text.length !== 0) {
        setContextMenu({
          visible: true,
          x: clientX - (left + 20),
          y: clientY - top,
        });
      };
    }
    const handleClickOutside = () => {
      setContextMenu({
        visible: false,
        x: 0,
        y: 0,
      });
    }
    //right click
    editorRef.current.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('click', handleClickOutside);

    setStateDoc(startState);
    setLang(startState.facet(latex)?.name);
    setState(startState);
    setView(view);
    if (view) setMainView(view);
    setViewDoc(view);
    setVisual(isVisual(view));

    setTimeout(() => {
      view.focus(); // Programmatically focus the editor
      const clickEvent = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      view.dom.dispatchEvent(clickEvent); // Dispatch the click event
    }, 100);
    return () => {
      view.destroy();
    };
  }, [fileContent, userProfile]);

  // Create an update listener

  const updateListener = EditorView.updateListener.of((update) => {
    if (update.docChanged) {
      const newContent = update.state.doc.toString();
      setCurrentFile(newContent);
      setEditorContent(newContent);
      setSaveTrigger(true);
    }
  });

  useEffect(() => {
    setTimeout(() => {
      let getComment = CommentUpdateFunction(Comment, commentValue?.comment);
      setChangedComment(getComment);
    }, 1000);
  }, [Comment]);


  function isYesNoQuestion(sentence) {
    // Define regex patterns for yes/no questions
    const yesNoPatterns = [/^(is|are|do|does|did|can|could|will|would|should|has|have|am|was|were)\b/i];
    // Check if the sentence matches any of the yes/no patterns
    return yesNoPatterns.some(pattern => pattern.test(sentence));
  }

  // const AQFinder = async (value) => {
  //   const doc = value;
  //   const lineCount = doc?.lines;
  //   const extractedQueries = [];
  //   const queryRegex = /\\AQ(?:\[[^\]]*\])?\{([^{}]*)\}\{((?:[^{}]|{(?:[^{}]|{[^{}]*})*})*)\}(?:{})?/gis;

  //   // Extract all queries first
  //   for (let i = 1; i <= lineCount; i++) {
  //     const lineContent = doc?.line(i).text;
  //     let match;
  //     while ((match = queryRegex.exec(lineContent)) !== null) {
  //       extractedQueries.push({
  //         linenumber: i,
  //         query: match[2],
  //         label: match[1],
  //         wholematch: match[0],
  //         attachment: [],
  //         ActivityID: userProfile.ActivityID,
  //         Role: userProfile.role,
  //         Date: moment(new Date()).format("DD/MM/YYYY")
  //       });
  //     }
  //   }
  //   if (Object.keys(authorQuery)?.length !== 0) {
  //     if (extractedQueries?.length === authorQuery.length) {
  //       const extractedQueriesList = authorQuery.map((itemA) => {
  //         const a = extractedQueries.find((itemB) => itemB?.label === itemA?.label);
  //         return a ? { ...itemA, linenumber: a.linenumber, ...a, } : { ...itemA }
  //       }
  //       )
  //       let checkQuery = extractedQueriesList.map((item) => {
  //         return {
  //           ...item,
  //           aqCheck: isYesNoQuestion(item?.query)
  //         }
  //       }
  //       )
  //       setQuery(checkQuery)
  //     } else {
  //       let checkQuery = extractedQueries.map((item) => {
  //         return {
  //           ...item,
  //           aqCheck: isYesNoQuestion(item?.query)
  //         }
  //       })
  //       setQuery(checkQuery)
  //     }
  //   } else {
  //     let checkQuery = extractedQueries.map((item) => ({
  //       ...item,
  //       aqCheck: isYesNoQuestion(item?.query)
  //     }));
  //     setQuery(checkQuery);
  //   }
  // };

  const AQFinder = async (value) => {
    const doc = value;
    const lineCount = doc?.lines;
    const extractedQueries = [];
    const queryRegex = /\\AQ(?:\[[^\]]*\])?\{([^{}]*)\}\{((?:[^{}]|{(?:[^{}]|{[^{}]*})*})*)\}(?:{})?/gis;

    // Extract all queries with their current labels
    for (let i = 1; i <= lineCount; i++) {
      const lineContent = doc?.line(i).text;
      let match;
      while ((match = queryRegex.exec(lineContent)) !== null) {
        const currentLabel = match[1];
        extractedQueries.push({
          linenumber: i,
          query: match[2],
          label: currentLabel, // This will now contain the updated label
          wholematch: match[0],
          attachment: [],
          ActivityID: userProfile.ActivityID,
          Role: userProfile.role,
          Date: moment(new Date()).format("DD/MM/YYYY")
        });
      }
    }

    // Update queries with latest labels
    if (Object.keys(authorQuery)?.length !== 0) {
      // Find new queries by comparing labels
      const existingLabels = authorQuery.map(q => q.label);
      const newQueries = extractedQueries.filter(q => !existingLabels.includes(q.label));
      
      // Merge existing queries with updates
      const updatedExistingQueries = extractedQueries
          .filter(q => existingLabels.includes(q.label))
          .map(itemA => {
              const matchingQuery = authorQuery.find(q => q.label === itemA.label);
              return {
                  ...itemA,
                  aqCheck: isYesNoQuestion(itemA?.query),
                  replies: matchingQuery?.replies || [],
                  repliesMark: matchingQuery?.repliesMark || false,
                  answerCheck: matchingQuery?.answerCheck,
                  replied: matchingQuery?.replied || false,
                  showReplyArea: matchingQuery?.showReplyArea || false,
                  showReplyButton: matchingQuery?.showReplyButton
              };
          });

      // Add new queries with basic properties
      const processedNewQueries = newQueries.map(item => ({
          ...item,
          aqCheck: isYesNoQuestion(item?.query),
          replies: [],
          repliesMark: false,
          answerCheck: false,
          replied: false,
          showReplyArea: false,
          showReplyButton: true
      }));

      // Combine and set final result
      setQuery([...updatedExistingQueries, ...processedNewQueries]);
  } else {
      // Handle case when there are no existing queries
      const newQueries = extractedQueries.map(item => ({
          ...item,
          aqCheck: isYesNoQuestion(item?.query)
      }));
      setQuery(newQueries);
  }
  };


  function addComment(view, from, to, comment, cid, commentmeta) {
    if (view) {
      view.dispatch({
        effects: commentEffect.of({ from, to, comment, cid, commentmeta })
      });
      view.focus();
    }
  }

  const highlightCommentedText = useCallback((comments) => {
    // console.log(comments, 'comments');
    if (comments) {
      // if (view && view.state) {
      //   comments.forEach((comment) => {
      const { from, to, cid } = comments;
      // Only add the comment if the cid is different from the previous one processed
      if (cid !== prevCid.current) {
        // Use the selectText directly from the comment
        const commentText = comments?.selectText;
        // Call addComment directly with the comment data
        addComment(view, from, to, commentText, cid, comments);
        // Update the state with the new comment
        setComments((prevComments) => ({
          ...prevComments,
          [cid]: [...(prevComments[cid] || []), comments]
        }));
        // Update the previous cid
        prevCid.current = cid;
      }
      // });
      // }
    }
  }, [view, setComments, prevCid]);

  const removeHighlightedText = useCallback((cid) => {
    if (comments != null && cid.cid != undefined) {
      let { from, to } = cid;
      removeComment(view, from, to);
      setComments((prevComments) => {
        const commentArray = prevComments[cid?.cid] || [];
        return {
          ...prevComments,
          [cid.cid]: commentArray.filter(comment => comment?.from !== from || comment?.to !== to)
        };
      });
    }
  }, [view, comments]);

  function removeComment(view, from, to) {
    view.dispatch({ effects: clearCommentEffect.of({ from, to }) });
    view.focus();
    // Update the track changes data
    saveTrackChanges(view?.state);
  }

  // const focusOnLine = (item) => {debugger
  //   // Get the position of the start of the line
  //   if (item.linenumber !== undefined) {
  //     const pos = view.state.doc.line(item.linenumber).from;
  //     let from = item.from;
  //     let to = item.to;
  //     if (from || to) {
  //       view.dispatch({
  //         selection: { anchor: from, head: to },
  //         scrollIntoView: true
  //       });
  //     } else {
  //       view.dispatch({
  //         effects: EditorView.scrollIntoView(pos, { y: 'center' }),
  //         selection: { anchor: pos }
  //       });
  //     }
  //   } else if (item.linenumber === undefined) {
  //     let from = item.from;
  //     let to = item.to;
  //     view.dispatch({
  //       selection: { anchor: from, head: to },
  //       scrollIntoView: true
  //     });
  //   }
  //   else {
  //     const pos = view.state.doc.line(item).from;
  //     view.dispatch({
  //       effects: EditorView.scrollIntoView(pos, { y: 'center' }),
  //       selection: { anchor: pos }
  //     });
  //   }
  //   view.focus();
  //   // Update track changes data for the focused line
  //   saveTrackChanges(view.state);
  // };

  const focusOnLine = (item) => {
    // 1. Check if view exists
    if (!view) return;

    try {
      // 2. Handle case with line number and from/to positions
      if (item.linenumber !== undefined) {
        const pos = view.state.doc.line(item.linenumber).from;
        const from = item.from;
        const to = item.to;

        if (from !== undefined && to !== undefined) {
          // 3. Select text range and center it
          view.dispatch({
            selection: { anchor: from, head: to },
            effects: EditorView.scrollIntoView(from, {
              y: 'center',
              x: 'center',
              yMargin: 80 // Add margin for better visibility
            })
          });
        } else {
          // 4. Just center the line if no from/to
          view.dispatch({
            selection: { anchor: pos },
            effects: EditorView.scrollIntoView(pos, {
              y: 'center',
              x: 'center',
              yMargin: 80
            })
          });
        }
      }
      // 5. Handle case with only from/to positions
      else if (item.from !== undefined && item.to !== undefined) {
        view.dispatch({
          selection: { anchor: item.from, head: item.to },
          effects: EditorView.scrollIntoView(item.from, {
            y: 'center',
            x: 'center',
            yMargin: 80
          })
        });
      }
      // 6. Handle case with just a line number
      else if (typeof item === 'number') {
        const pos = view.state.doc.line(item).from;
        view.dispatch({
          selection: { anchor: pos },
          effects: EditorView.scrollIntoView(pos, {
            y: 'center',
            x: 'center',
            yMargin: 80
          })
        });
      }

      // 7. Focus the editor
      view.focus();

      // 8. Update track changes
      saveTrackChanges(view.state);

    } catch (error) {
      console.error('Error in focusOnLine:', error);
    }
  };

  useEffect(() => {
    // debugger
    if (linepostion?.line) {
      focusOnLineError(linepostion);
    }
  }, [linepostion]);

  const focusOnLineError = (item) => {
    // debugger
    // Get the position of the start of the line
    const pos = view.state.doc.line(item.line === undefined ? item.position : item.line).from;
    view.dispatch({
      effects: EditorView.scrollIntoView(pos, { y: 'center' }),
      selection: { anchor: pos }
    });
    view.focus();
    // Update track changes data for the focused line
    saveTrackChanges(view.state);
  };

  const AuthorQueryDelete = (QueryDelete) => {
    if (!view) return;
    const { linenumber, Content: wholeMatch } = QueryDelete;
    // Adjusting to 1-based indexing for line numbers
    if (linenumber <= 0 || linenumber > view.state.doc.lines) {
      // console.error(`Invalid line number ${linenumber}`);
      return;
    }
    const line = view.state.doc.line(linenumber);
    const lineContent = line.text;
    if (lineContent !== undefined) {
      const updatedLineContent = lineContent.replace(wholeMatch, '');
      const transaction = view.state.update({
        changes: {
          from: line.from,
          to: line.to,
          insert: updatedLineContent
        }
      });
      view.dispatch(transaction);
    }
  };

  const AuthorQueryUpdate = (QueryDetails) => {
    if (!view) return;
    const lineNumber = QueryDetails.linenumber;
    const editedContent = QueryDetails.changecontent;
    const label = QueryDetails.label;

    if (lineNumber <= 0 || lineNumber > view.state.doc.lines) {
      return;
    }

    const line = view.state.doc.line(lineNumber);
    const lineContent = line.text;

    if (lineContent !== undefined) {
      const regex = /\\AQ(?:\[[^\]]*\])?\{([^{}]*)\}\{((?:[^{}]|{(?:[^{}]|{[^{}]*})*})*)\}(?:{})?/;
      const match = QueryDetails.oldcontent.match(regex);

      if (match) {
        const updatedContent = lineContent.replace(
          match[0],
          `\\AQ{${label}}{${editedContent}}`
        );

        const tr = view.state.update({
          changes: {
            from: line.from,
            to: line.to,
            insert: updatedContent
          }
        });
        view.dispatch(tr);
      }
    }
  };


  const commentAction = () => {
    setCommentSidebar({ selectValue: selectedText, showSidebar: 'rightbar', commentValue: 'comment' })
  }

  // Handle symbol data received from ToolbarItems
  const handleSymbolData = useCallback((symbol) => {
    if (view) {
      const { state } = view;
      const { selection } = state;
      if (selection.main.from !== null) {
        const cursorPos = selection.main.to;
        const symbolText = symbol; // Assuming symbol is the text you want to insert
        const symbolLength = symbolText.length;
        const newPos = cursorPos + symbolLength;
        view.dispatch({
          changes: {
            from: cursorPos,
            to: cursorPos,
            insert: symbolText,
          },
          selection: {
            anchor: newPos,
            head: newPos,
          },
        });
      }
    }
  }, [view]);

  const handleButton = async (type) => {
    setShowprogress(true)
    const docid = userProfile.docid;
    switch (type) {
      case "styleediting": {
        setProgressDetails(true);
        setProgressDetails("Please wait Style Editing is progress.")
        const data = { 'texfilename': currentFile, 'texcontent': fileContent, "s5filename": " _S2950338824000081-20240617_080312_S5.xml ", "s5content": "UTF-8 base64 encoded cnt", "inifilename": " ELS_user_preedit-mapping.ini", "inicontent": "UTF-8 base64 encoded cnt", "docid": docid };
        const result = await styleEditing(data);
        props.styleEditingError(result);
        setShowprogress(false)
        break;
      }
      case "issueupdation": {
        setProgressDetails(true);
        setProgressDetails("Please wait Issue Updation is progress.")
        const data = { 'texfilename': currentFile, 'texcontent': fileContent, "docid": docid };
        const result = await issueUpdation(data);
        props.issueUpdationError(result);
        setShowprogress(false)
        break;
      }
      case "XMPCreation": {
        setProgressDetails(true);
        setProgressDetails("Please wait XMP Creation is progress.")
        const data = { 'texfilename': currentFile, 'texcontent': fileContent, "docid": docid };
        const result = await XMPCreation(data);
        props.XMPCreationError(result);
        setShowprogress(false)
        break;
      }
      case "referanceordering": {
        setProgressDetails(false);
        setProgressDetails("Please wait Reference Ordering is progress -")
        const runningApi = (val) => {
          setApiStatusRunning(val)
        }
        const refData = { 'texfilename': currentFile, 'texcontent': fileContent };
        const refResult = await referanceOrdering(refData, runningApi);
        props.refrenceStrucutre(refResult);
        setShowprogress(false)
        break;
      }
      case "clearcachedfiles": {
        if (docid !== undefined && docid !== null) {
          setProgressDetails("Please wait clear cache files is progress.");
          const clearCache = await clearCacheFiles(docid);
          setShowprogress(false)
          console.log(clearCache, 'clearCache')
          if (clearCache.isDeleted) {
            toastSuccess(`${clearCache.message}`);
          } else {
            toastError(`${clearCache.details}`);
          }
        } else {
          toastError('Docid is missing.');
        }
        break;
      }
      case "cleanup": {
        setProgressDetails(false);
        setProgressDetails("Please wait clean up is progress")
        const cleanData = { 'texfilename': currentFile, 'texcontent': fileContent, "docid": docid };
        const cleanResult = await CleanupEditor(cleanData);
        props.MacroCleanUp(cleanResult);
        setShowprogress(false)
        break;
      }
      case "iref": {
        setProgressDetails(false);
        setProgressDetails("Please wait iref is progress");
        const irefData = { 'texfilename': currentFile, 'texcontent': fileContent, "docid": docid };
        const irefResult = await iRefEditor(irefData);
        props.iRefProgress(irefResult);
        console.log(irefResult, 'irefResult')
        setShowprogress(false);
        break;
      }
      default:
        console.log(`Unhandled button type: ${type}`);
        break;
    }
  }

  return (
    <div className="latex-editor">
      <div className={`modal-outer ${showProgress ? 'active' : ''}`}>
        <div className="modal confirmation-popup">
          <div className="modal-body">
            <p>{progressDetails} {ApiStatusRunning.length !== 0 ? <span>{ApiStatusRunning}</span> : ''} </p>
            <span className="loader"></span>
          </div>
        </div>
      </div>
      <div className='editor-btns-group'>
        <div className='editor-btn-inner'>
          <div style={{ display: "flex" }}>
            <div className='buttonTools-area-size'>
              <div ref={buttonResize} className='buttonTools-area'>
                <ToolbarItems
                  state={state}
                  view={view}
                  overflowed={false}
                  languageName={lang}
                  visual={visual}
                  disabled={showCommentButton}
                  handleSymbolData={handleSymbolData} // Pass function down to ToolbarItems
                />
                {/* <button disabled={showCommentButton} onClick={commentAction} className='div-border' title="Comment"><FaRegComment /></button> */}
              </div>
              {!["Author", "Editor"].includes(userProfile?.role) && (
                <div className='buttonTool-child'>
                  <button className='div-border'><FaEllipsisH /></button>
                  <div className='buttonTool-child-inner' ref={subChildButton}>
                    <button className='title-icon-btn' title="Cleanup" onClick={() => handleButton('cleanup')}>Cleanup</button>
                    <button className='title-icon-btn' title="Pre Structuring">Pre Structuring</button>
                    {ActivityName === "L1" ? <><button className='title-icon-btn' title="Style Editing" onClick={() => handleButton('styleediting')}>Style Editing</button>
                      <button className='title-icon-btn' title="Referance Ordering" onClick={() => handleButton('referanceordering')}>Reference Ordering</button></> : ''}
                    <button className='title-icon-btn' title="XML Conversion">XML Conversion</button>
                    <button className='title-icon-btn' title="iAutopage" onClick={() => handleButton('iAutopage')}>iAutopage</button>
                    <button className='title-icon-btn' title="iRef" onClick={() => handleButton('iref')}>iRef</button>
                    <button className='title-icon-btn' title="Compiler">Compiler</button>
                    <button className='title-icon-btn' title="iprintQC">iprintQC</button>
                    <button className='title-icon-btn' title="XMP Creation" onClick={() => handleButton('issueupdation')}>Issue Updation</button>
                    <button className='title-icon-btn' title="XMP Creation" onClick={() => handleButton('XMPCreation')}>XMP Creation</button>
                    <button className='title-icon-btn' title="Clear cached files" onClick={() => handleButton('clearcachedfiles')}>Clear cached files</button>
                  </div>
                </div>
              )}
            </div>
            {/* <div className='switch_track div-border'>
          <Form>
            <Form.Check
              type="switch"
              id="track_changes"
              label="Track Changes"
              onChange={(e) => trackToggle(e)}
              disabled="true"
            />
          </Form>
        </div> */}
          </div>
        </div>
        <div className='editor-btn-inner'>
          <Button className="switchbutton" onClick={() => setLayout('pdfonly')}> <FaRegFilePdf /> Switch to pdf</Button>
        </div>
      </div>
      <div ref={editorRef} />
      {contextMenu.visible && (
        <div className='quickbar-toolbar'
          style={{
            top: contextMenu.y,
            left: contextMenu.x,
          }}
        >
          <ToolbarItems
            state={state}
            view={view}
            overflowed={false}
            languageName={lang}
            visual={visual}
            disabled={showCommentButton}
            handleSymbolData={handleSymbolData} // Pass function down to ToolbarItems
          />
          <button disabled={showCommentButton} onClick={commentAction} className='div-border' title="Comment"><FaRegComment /></button>
        </div>
      )}
    </div>
  );
};

const CodeMirrorStateContext = createContext < EditorState | undefined > (undefined)

export const useCodeMirrorStateContext = () => {
  const context = useContext(CodeMirrorStateContext)
  if (!context) {
    return null;
  }
  return context
}

const CodeMirrorViewContext = createContext < EditorView | undefined > (undefined)
export const CodeMirrorViewContextProvider = CodeMirrorViewContext.Provider
export const useCodeMirrorViewContext = () => {
  const context = useContext(CodeMirrorViewContext)
  if (!context) {
    return null;
  }
  return context
}


export default CodeMirrorEditor;
