import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { useAppContextProvider } from "../../../contextapi";

function TreeNode(props) {
    const [expanded, setExpanded] = useState(false);
    const {setLineFocus} = useAppContextProvider();

    const toggleExpansion = (expanded) => {
        setExpanded(!expanded);
    };

    const lineFocus = (val) => {
        props.setActiveItemId(val);
        setLineFocus(val)
    }

    return (
        <li>
            {props.node.subsections?.length === 0 ? <div className={props.activeItemId === props.node.linenumber ? "active no-tree-parent" : "no-tree-parent"}><p title={props.node.title} onClick={() => lineFocus(props.node)}><button>{props.node.title}</button></p></div> :
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <div className={props.activeItemId === props.node.linenumber ? "tree-parent active" : "tree-parent"}><Button onClick={() => toggleExpansion(expanded)} aria-label={expanded ? 'expand' : 'collapse'}> {expanded ? <FaChevronDown /> : <FaChevronRight />}</Button> <p title={props.node.title} ><button onClick={() => lineFocus(props.node)}>{props.node.title}</button></p></div>}

            {expanded && (
                <ul>
                    {props.node.subsections.map((child, index) => (
                        <TreeNode key={child.id} node={child} dispatchFoucs={setLineFocus} activeItemId={props.activeItemId} setActiveItemId={props.setActiveItemId} />
                    ))}
                </ul>
            )}
        </li>
    );
}

export default TreeNode;

