import { useState, useEffect } from "react";
import { FaTimes, FaCheck } from "react-icons/fa";
import NodataFound from "../nodata";
import { useAppContextProvider } from '../../contextapi';

const TrackChanges = (props) => {
    const [trackChange, setTrackchange] = useState([]);
    const [trackChangeData, setTrackchangeData] = useState([]);
    const [currentFilter, setCurrentFilter] = useState("all");
    const { trackChanges, setTrackAction, setLineFocus, userProfile, setSidebarClose } = useAppContextProvider();

    useEffect(() => {
        if (trackChanges.length !== 0) {
            setTrackchangeData(trackChanges)
            setTrackchange(trackChanges)
            applyFilter(currentFilter, trackChanges); // Reapply filter
        } else {
            setTrackchangeData([])
            setTrackchange([])
        }
    }, [trackChanges])

    // Apply filter when selected filter changes
    const applyFilter = (filter, data = trackChange) => {
        if (filter === "all") {
            setTrackchangeData(data);
        } else {
            const filteredData = data.filter((item) => item?.role === filter);
            setTrackchangeData(filteredData);
        }
    };

    const actionTrack = (type, id, item) => {
        setTrackAction({ status: type, list: item });
        const updatedData = [...trackChangeData];
        updatedData[id].status = type;
        setTrackchangeData(updatedData);
    };

    const handleSelect = (e) => {
        const selectedFilter = e.target.value;
        setCurrentFilter(selectedFilter); // Update filter state
        applyFilter(selectedFilter);
    };

    const queryScroll = (item) => {
        setLineFocus(item);
    };

    return (
        <div className="track-changes">
            <div className="slider-header">
                <h5>Track Changes - ({trackChangeData.length})</h5>
                <div style={{ width: '20px' }}>
                    <FaTimes onClick={() => setSidebarClose(true)} />
                </div>
            </div>
            <div className="track-changes-content">
                <div className="filterTop">
                    <label>Filter:</label>
                    <select aria-label="Default select example" onChange={(e) => handleSelect(e)}>
                        <option value="all">All</option>
                        <option value="Author">Author</option>
                        <option value="Editor">Editor</option>
                        <option value="Master Copier">Master Copier</option>
                        <option value="Copy Editor">Copy Editor</option>
                    </select>
                </div>
                {trackChangeData.length !== 0 ? trackChangeData.map((item, i) =>
                    <div className="track-group" key={i} onClick={() => queryScroll(item)}>
                        {item.type === "insertion" ? <label className="status insert">inserted</label> : <label className="status delete">deleted</label>}
                        <h5>{item.role === "Copy Editor" ? item.role : item.Username}
                            <span>{item.Date_Time}</span>
                        </h5>
                        <p><label>Text:</label>{item.text === undefined ? item.decoration.spec.text : item.text}</p>
                        <div className="trackfooter">
                            {item.role !== userProfile.role ?
                                <button className="accept-btn" onClick={() => actionTrack('Accept', i, item)}>
                                    <FaCheck /> Accept
                                </button> : ''
                            }
                            <button className="reject-btn" onClick={() => actionTrack('Reject', i, item)}><FaTimes /> Reject</button>
                        </div>
                    </div>
                ) : <NodataFound />}
            </div>
        </div>
    )
}
export default TrackChanges;


