import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FaRegQuestionCircle } from "react-icons/fa";
import Pdfimage from "../../images/pdf.png";
import Captcha from "../captcha/captcha";
import { useLocation, useNavigate } from "react-router-dom";

const Introduction = () => {
    const [showCaption, setShowCaption] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    let docId;

    useEffect(() => {
        docId = location.search.split('?')[1].split('=')[1];
    }, []);

    const CaptchaVerify = (value) => {
        localStorage.setItem(`${docId}_captchaVerified`, true);
        setTimeout(() => {
            navigate(`/editor?docid=${docId}`);
        }, 2000);
    }

    const pageRedirect = (value) => {
        setTimeout(() => {
            if (value === true) {
                navigate(`/editor?docid=${docId}`)
            }
        }, 2000);
    }

    const proceedStatus = () => {
        setShowCaption(true)
    }

    return (
        <div className="introduction_page">
            <div className="introduction_header">
                <div className="header-title">
                    <h1>
                        iAuthor
                    </h1>
                </div>
            </div>
            <div className="introduction_content">
                <div>
                    <h2>Quick Reference Guide</h2>
                    <p><strong>Welcome to iAuthor</strong>, the online collaborative platform tailor made for authoring, copyediting and proofing journal content.</p>
                    <p>Please follow the instructions below:</p>
                    <ul>
                        <li>Review your proof carefully and make necessary <strong>amendments directly into the article</strong>.</li>
                        <li>Resolve (reply or accept) all <strong>Author Queries (AQs)</strong> displayed in the panel on the right-hand side.</li>
                        <li>You can edit author name, tables, equations, citations, references and replace images in iAuthor. <strong>Add a comment</strong> if you are unable to make a certain change.</li>
                        <li><strong>The images in iAuthor are low resolution</strong> to optimise the performance of the tool. To view the typeset PDF proof with high-resolution images, click on the PDF icon <img class="aves_pdf" src={Pdfimage} alt="PDF Icon" /> in the top-right of the window.</li>
                        <li><strong>Submit the article</strong> when you have reviewed the entire article and resolved all the Author Queries.</li>
                        <li>You will receive a copy of the proof with changes via email for your own records.</li>
                        <li>If you need assistance with iAuthor, please contact our support team at  <strong><a href="mailto:product.support@integra.co.in">product.support@integra.co.in</a></strong></li>
                        <li>You can access the Quick Reference Guide and User manual from the Help menu <FaRegQuestionCircle /> in the top-right corner of the screen.</li>
                    </ul>
                </div>
                <div className="introduction_footer">
                    {showCaption ? <Captcha CaptchaVerify={CaptchaVerify} pageRedirect={pageRedirect} /> : ''}
                    <Button onClick={proceedStatus}>Proceed to iAuthor</Button>
                </div>
            </div>
        </div>
    )
}
export default Introduction;
