import { useState, useEffect, useRef } from "react";
import { FaBold, FaItalic, FaUnderline, FaTimes, FaReply, FaEdit, FaSave, FaSuperscript, FaSubscript, FaPaperPlane, FaPaperclip, FaRegTrashAlt } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { toastError, toastSuccess } from '../toaster';
import pathconfig from '../../pathconfig.json';
import axios from "axios";
import NodataFound from "../nodata";
import packjson from '../../../package.json';
import { useAppContextProvider } from '../../contextapi';

const QueryTab = (props) => {
    const [query, setQuery] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editedQuery, setEditedQuery] = useState("");
    const [replyIndex, setReplyIndex] = useState(null);
    const [replyContent, setReplyContent] = useState("");
    const [fileAttachment, setFileAttachment] = useState([]);
    const [fileAllData, setFileAllData] = useState([]);
    const [queryFile, setQueryFile] = useState();
    const [queryStatus, setQueryStatus] = useState(false);
    const [queryData, setQueryData] = useState({})
    const fileInputRef = useRef(null);
    const [loader, setLoader] = useState(false);
    const [render, setRender] = useState(false);
    const [answerCount, setAnswerCount] = useState(0);
    const { userProfile, setLineFocus, authorQuery, setAuthorQuery, setAuthorQueryChange, setSidebarClose } = useAppContextProvider();
    let docId;

    useEffect(() => {
        console.log(authorQuery, '<<<<<<<<<<<<<<<<authorquerycheck>>>>>>>>>>>>>>>>')
        docId = userProfile.docid;
        getDetails();
    }, [])
    const handleError = (error, message = error || 'Something went wrong') => {
        console.error(error);
        toastError(message);
    };
    useEffect(() => {
        try {
            if (authorQuery.length !== undefined) {
                const sortedData = authorQuery.sort((a, b) => {
                    const numA = parseInt(a.label.replace(/\D/g, ''));
                    const numB = parseInt(b.label.replace(/\D/g, ''));
                    return numA - numB;
                });
                sortedData.map((item) => item.ActivityID === undefined ? userProfile.ActivityID : item.ActivityID)
                setQuery(sortedData);
            } else {
                setQuery([])
            }
        } catch (error) {
            handleError(error, 'Error processing queries');
        }
    }, [authorQuery]);

    useEffect(() => {
        if (query) {
            if (query.length > 0) {
                setAuthorQuery(query);
                let answerFilter = query.filter((a) =>
                    a?.answerCheck === 'yes' ||
                    a?.repliesMark === true ||
                    (a?.answerCheck === 'no' && a?.replies?.length > 0) ||
                    a?.replied === true
                );
                setAnswerCount(answerFilter.length);
            }
        }
    }, [query]);



    const queryScroll = (number) => {
        setLineFocus(number);
    };

    const handleSave = (lineNumber, wholeMatch, editedContent) => {
        const querychange = { linenumber: lineNumber, oldcontent: wholeMatch, changecontent: editedContent };
        setAuthorQueryChange(querychange);
        const updatedQuery = query.map((item, index) =>
            index === editingIndex ? { ...item, query: editedContent, wholematch: wholeMatch } : item
        );
        setQuery(updatedQuery); // Update the state with the new query
        setEditingIndex(null); // Reset editing state
        setEditedQuery(""); // Clear edited query state
    };

    const handleCancel = () => {
        setEditingIndex(null);
        setEditedQuery("");
        setReplyIndex(null); // Reset reply state
        setReplyContent(""); // Clear reply content state
        setFileAttachment([]); // Clear file attachments
    };

    const handleReply = (index) => {
        setReplyIndex(index);
        setEditingIndex(null); // Cancel editing mode if replying
        setFileAttachment([]); // Clear previous file attachments
        setQueryFile(); // Reset query file
    };

    const handleReplySave = (index) => {
        try {
            const replyContentHtml = document.getElementById("TypeContent").innerHTML;
            // Simulate an error condition for testing
            const simulateError = false; // Set to `true` to trigger the error condition
            if (simulateError) {
                const error = new Error("Simulated error for testing");
                handleError(error, "Error saving reply"); // Call error handler directly
                return; // Prevent further execution
            }
            // Remove HTML tags and check if content has only spaces or empty HTML
            const plainText = replyContentHtml
                .replace(/<[^>]*>/g, "") // Remove all HTML tags
                .replace(/&nbsp;/g, ""); // Remove non-breaking spaces

            if (!plainText.trim()) {
                toastError("Reply content cannot be empty or only spaces");
                return;
            }

            const replyWithRole = {
                content: replyContentHtml,
                role: userProfile.role,
                timestamp: new Date().toISOString()
            };

            const updatedQuery = query.map((item, idx) =>
                idx === index ? {
                    ...item,
                    replies: [...(item.replies || []), replyWithRole],
                    attachment: [...(item.attachment || []), queryFile],
                    repliesMark: false, // Uncheck "no action required" when reply is saved
                    showReplyArea: false,
                    replied: true
                } : item
            );
            setQuery(updatedQuery);
            setReplyIndex(null);
            setReplyContent("");
            setFileAttachment([]);
            setQueryFile();
        } catch (error) {
            handleError(error || "Error saving reply");
        }
    };

    const applyStyle = (command) => {
        const currentSelection = window.getSelection();
        if (currentSelection.rangeCount > 0) {
            const range = currentSelection.getRangeAt(0);
            const parentElement = range.commonAncestorContainer.parentNode;
            if (command === 'superscript' || command === 'subscript') {
                if (parentElement.tagName.toLowerCase() === (command === 'superscript' ? 'sup' : 'sub')) {
                    const grandparent = parentElement.parentNode;
                    while (parentElement.firstChild) {
                        grandparent.insertBefore(parentElement.firstChild, parentElement);
                    }
                    grandparent.removeChild(parentElement);
                } else {
                    document.execCommand(command, false, null);
                }
            } else {
                document.execCommand(command, false, null);
            }
        }
    };

    const handleUpload = (e, index) => {
        let dataCheck = fileAllData.filter((item) => item.Filename === e.target.files[0].name);
        if (dataCheck.length === 0) {
            let file = e.target.files[0];
            const maxFileSize = 40 * 1024 * 1024;
            if (file.size > maxFileSize) {
                toastError('The file size should not exceed more than 40 MB')
                e.target.value = null;
                return;
            }
            handleFileupload(e.target.files[0], index)
        } else {
            toastError(`${e.target.files[0].name} filename already uploaded. Please rename and retry.`)
        }
        e.target.value = null;
    }

    const handleFileupload = (filename, index) => {
        try {
            setLoader(true)
            if (fileAttachment[0]?.Filename === filename.name) {
                toastError(`${filename.name} filename already uploaded. Please rename and retry.`);
                setLoader(false)
            } else {
                try {
                    let GetDetailDataString = localStorage.getItem(`${userProfile.docid}_init-getdetails`);
                    let GetDetailData = JSON.parse(GetDetailDataString);
                    var formdata = new FormData();
                    formdata.append("jobGUID", GetDetailData.Articledetails.ArticleGUID);
                    formdata.append("ActivityID", GetDetailData.ActivtyDetails.ActivityID);
                    formdata.append("docid", userProfile.docid);
                    formdata.append("type", "commentAttachment");
                    formdata.append("files", filename);
                    const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].upload_attachment}`;
                    axios.post(url, formdata, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    }
                    ).then((response) => {
                        if (response.data.SuccessFiles.length !== 0) {
                            response.data.SuccessFiles.map((item) =>
                                toastSuccess(`${item.data[0].Filename} - ${item.message}`)
                            )
                        }
                        if (response.data.FailedFiles.length !== 0) {
                            response.data.FailedFiles.map((item) =>
                                toastError(`${item.data[0].Filename} - ${item.message}`)
                            )
                        }
                        if (response.data.success === true) {
                            getDetails(filename, index)
                        }
                    }
                    ).catch(error => {
                        console.log(error)
                        toastError(error.message);
                    })
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            setLoader(false);
            handleError(error, 'Error uploading file');
        }
    }

    const getDetails = (filename, index) => {
        try {
            props.getTrigger(false)
            let GetDetailDataString = localStorage.getItem(`${userProfile.docid}_init-getdetails`);
            let GetDetailData = JSON.parse(GetDetailDataString);
            const getUrl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].getAttachment}`;
            const body = {
                "Proof_Type": GetDetailData.jobdetails.ProofType,
                "JobGUID": GetDetailData.jobdetails.JobGUID,
                "docid": userProfile.docid
            }
            axios.post(getUrl, body).then((response) => {
                let dataChange = response.data.data.map((item) => {
                    return {
                        ...item,
                        filetype: item.Filename.split('.')[1]
                    }
                }
                )
                let commentType = dataChange.filter((item) => item.AttachType === 'commentAttachment')
                if (filename) {
                    let files = commentType.filter((item) => item.Filename === filename.name)
                    setFileAttachment(files)
                    if (index !== null && index !== undefined) {
                        let a = [...query]
                        a[index].attachment.push(files[0])
                        setQueryFile(a)
                    }
                    else {
                        setQueryFile(files[0])
                    }
                } else {
                    setFileAllData(commentType)
                }
                props.getTrigger(true)
                setLoader(false)
            }).catch((error) => {
                console.log(error)
                toastError(error.message);
                setLoader(false);
            }).catch((error) => {
                console.log(error)
                toastError(error.message);
                setLoader(false);
            })
        } catch (error) {
            props.getTrigger(true);
            setLoader(false);
            handleError(error, 'Error fetching details');
        }
    }

    const deleteConfirm = (value, index, parentIndex) => {
        try {
            let GetDetailDataString = localStorage.getItem(`${userProfile.docid}_init-getdetails`);
            let GetDetailData = JSON.parse(GetDetailDataString);
            let data = {
                "ArticleGUID": value.ArticleGUID,
                "AttachID": value.AttachID,
                "Proof_Type": GetDetailData.jobdetails.ProofType,
                "AttachFlag": value.AttachFlag,
                "docid": userProfile.docid
            }
            const deleteUrl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].deleteAttachment}`
            axios.post(deleteUrl, data).then((response) => {
                toastSuccess(`${response.data.data[0].Filename} - ${response.data.message}`)
                getDetails()
                setFileAttachment([])
                if (index !== null) {
                    let FileDetails = [...query];
                    FileDetails[parentIndex].attachment.splice(index, 1)
                    setQuery(FileDetails);
                }
                setQueryFile()
            }).catch((error) => {
                console.log(error);
                toastError(error.message);
            })
        } catch (error) {
            handleError(error, 'Error deleting attachment');
        }
    }

    const handleMark = (idvalue) => {
        const updatedQuery = query.map((item, idx) =>
            idx === idvalue ? {
                ...item,
                repliesMark: !item.repliesMark,
                showReplyButton: !item.repliesMark, // Toggle reply button visibility
                replies: [] // Clear any existing replies when marking no action
            } : item
        );
        setQuery(updatedQuery);
    }


    const editReply = (id, replyId) => {
        const replyContent = query[id].replies[replyId].content || '';
        const contentDiv = document.createElement('div');
        contentDiv.innerHTML = replyContent;

        setQueryData({
            'value': contentDiv.innerHTML || '',
            'id': replyId,
            'parentID': id
        });
        setQueryStatus(true);
    }

    const handleReplySaved = (id, replyId) => {
        const replyContentHtml = document.getElementById("TypeContentEdit").innerHTML;

        // Remove HTML tags and check if content has only spaces or empty HTML
        const plainText = replyContentHtml
            .replace(/<[^>]*>/g, "") // Remove all HTML tags
            .replace(/&nbsp;/g, ""); // Remove non-breaking spaces

        if (!plainText.trim()) {
            toastError("Reply content cannot be empty or only spaces");
            return;
        }

        let replyArray = [...query]
        replyArray[id].replies[replyId] = {
            content: replyContentHtml,
            role: replyArray[id].replies[replyId].role,
            timestamp: replyArray[id].replies[replyId].timestamp
        };
        setQuery(replyArray)
        setQueryData({ 'value': '', 'id': '' })
        setQueryStatus(false)
    };

    const editClose = () => {
        setQueryData({ 'value': '', 'id': '' })
        setQueryStatus(false)
    }
    const replyDelete = (id, replyId) => {
        let replyArray = [...query];
        replyArray[id].replies.splice(replyId, 1);

        // Show both options when reply is deleted
        if (replyArray[id].replies.length === 0) {
            replyArray[id].repliesMark = false;
            replyArray[id].showReplyButton = replyArray[id].aqCheck ? false : true; // Hide for aqCheck, show for regular
            replyArray[id].answerCheck = undefined; // Reset radio selection
            replyArray[id].replied = false; // Reset replied state
            replyArray[id].hasReplies = false;
        }
        setQuery(replyArray);
    }

    const handleRadio = (e, id) => {
        try {
            const radioValue = e.target.value;
            const updatedQuery = query.map((item, idx) =>
                idx === id ? {
                    ...item,
                    answerCheck: radioValue,
                    repliesMark: radioValue === 'yes' ? true : false,
                    previousReply: radioValue === 'yes' ? item.replies : item.previousReply,
                    // replies: item.replies || [], // Preserve existing replies
                    replies: radioValue === 'yes' ? [] : (item.replies || []),
                    showReplyArea: radioValue === 'no'
                } : item
            );

            if (radioValue === 'no') {
                if (!query[id].replies || query[id].replies.length === 0) {
                    setReplyIndex(id);
                } else {
                    setQueryData({
                        'value': query[id].replies[0],
                        'id': 0,
                        'parentID': id
                    });
                    setQueryStatus(true);
                }
            } else {
                setReplyIndex(null);
                setQueryStatus(false); // This will hide the reply area
                setQueryData({ 'value': '', 'id': '', 'parentID': '' }); // Clear query data
            }
            setQuery(updatedQuery);
            setRender(!render);
        } catch (error) {
            handleError(error, 'Error updating response');
        }
    };

    return (
        <div className="comment-area">
            <div className="slider-header">
                <h5>Author Query - ({answerCount}/{query.length})</h5>
                <div style={{ width: '20px' }}>
                    <button className="close-btn">
                        <FaTimes onClick={() => setSidebarClose(true)} />
                    </button>
                </div>
            </div>
            <div className="comment-group author-query">
                {query.length === 0 ? <NodataFound /> : ''}
                {query.map((item, index) =>
                    <div className={
                        (item.repliesMark ||
                            item.answerCheck === 'yes' ||
                            (item.answerCheck === 'no' && item.replies?.length > 0) || item.replied)  ? "comment-inner replied" : "comment-inner"} key={index} onClick={() => queryScroll(item)}>
                        {/* <h5>{item.Role} <label>{item.Date}</label></h5> */}
                        {editingIndex === index ? (
                            <textarea
                                value={editedQuery}
                                onChange={(e) => setEditedQuery(e.target.value)}
                            />
                        ) : (
                            <p>A{item.label}: {item.query}</p>
                        )}
                        {item.replies && [...new Set(item.replies.map(JSON.stringify))].map(str => JSON.parse(str)).map((reply, idx) =>
                            <>
                                {(idx !== queryData.id && queryData.parentID !== index && item.answerCheck !== 'yes') ?
                                    <div className="reply-group" key={`reply-${item.label}-${idx}`}>
                                        {(idx !== queryData.id && queryData.parentID !== index && item.answerCheck !== 'yes') &&
                                            <div key={idx} className="reply-content">
                                                {/* <p>Reply {idx + 1}:</p> */}
                                                <p>Reply from {reply.role} :</p>
                                                <div dangerouslySetInnerHTML={{ __html: reply.content }}></div>
                                                {/* {userProfile?.role === reply.role && (
                                                <div className="reply-footer">                                                   <button onClick={() => editReply(index, idx)} title="Edit" className="edit">
                                                    <FaEdit />
                                                </button>
                                                    <button onClick={() => replyDelete(index, idx)} title="Delete" className="detele">
                                                        <FaRegTrashAlt />
                                                    </button>
                                                </div>
                                            )} */}
                                            </div>
                                        }
                                        {userProfile?.role === 'Author' && (
                                            <div className="reply-footer">
                                                <button onClick={() => editReply(index, idx)} title="Edit" className="edit">
                                                    <FaEdit />
                                                </button>
                                                <button onClick={() => replyDelete(index, idx)} title="Delete" className="detele">
                                                    <FaRegTrashAlt />
                                                </button>
                                            </div>
                                        )}
                                        {/* Show edit/delete only for Editor's own replies */}
                                        {userProfile?.role === 'Editor' && reply.role === 'Editor' && (
                                            <div className="reply-footer">
                                                <button onClick={() => editReply(index, idx)} title="Edit" className="edit">
                                                    <FaEdit />
                                                </button>
                                                <button onClick={() => replyDelete(index, idx)} title="Delete" className="detele">
                                                    <FaRegTrashAlt />
                                                </button>
                                            </div>
                                        )}
                                        {/* Show reply button for Editor only when there are no Editor replies yet */}
                                        {userProfile?.role === 'Editor' && !item.replies.some(r => r.role === 'Editor') && (
                                            <div className="comment-footer">
                                                <Button className="accept-btn" onClick={() => handleReply(index)}>
                                                    <FaReply /> Reply
                                                </Button>
                                            </div>
                                        )}
                                    </div> : ''}
                                <>
                                    {queryStatus && (index === queryData.parentID && idx === queryData.id) ? <div className="reply-area">
                                        <div className="reply-btns">
                                            <div>
                                                <Button onClick={() => applyStyle('bold')} title='Bold'>
                                                    <FaBold />
                                                </Button>
                                                <Button onClick={() => applyStyle('italic')} title='Italic'>
                                                    <FaItalic />
                                                </Button>
                                                <Button onClick={() => applyStyle('underline')} title='Underline'>
                                                    <FaUnderline />
                                                </Button>
                                                <Button onClick={() => applyStyle('superscript')} title='SuperScript'>
                                                    <FaSuperscript />
                                                </Button>
                                                <Button onClick={() => applyStyle('subscript')} title='SubScript'>
                                                    <FaSubscript />
                                                </Button>
                                                {/* {item.attachment.length !== 0 ? */}
                                                <Button className="attchcomment-btn" title='Attachment'>
                                                    <FaPaperclip />
                                                    <input type="file" onChange={(e) => handleUpload(e, index)} ref={fileInputRef}></input>
                                                </Button>
                                                {/* : ""} */}
                                            </div>
                                            <div>
                                                <Button onClick={editClose}><FaTimes /></Button>
                                            </div>
                                        </div>
                                        <div className="CommentContent">
                                            <div
                                                contentEditable="true"
                                                id="TypeContentEdit"
                                                className="CommentTextEntry UIFont Cmdtxtcontent"
                                                dangerouslySetInnerHTML={{
                                                    __html: queryData.value || "",
                                                }}
                                            />
                                        </div>
                                        {loader ? <div className='fileupload-loder'><span className="loader"></span></div> : ''}
                                        <div className="reply-footer">
                                            <Button className="save-btn" onClick={() => handleReplySaved(index, queryData.id)}><FaSave /> Save</Button>
                                            <Button className="cancel-btn" onClick={editClose}><FaTimes /> Cancel</Button>
                                        </div>
                                    </div> : ""}
                                </>
                            </>
                        )}
                        {item.attachment !== undefined ?
                            <div className='comment-attached' style={{ marginTop: '6px' }}>
                                {item.attachment.map((file, a) =>
                                    (file !== null && file !== undefined) ?
                                        <div className='comment-attached-list' key={a}>
                                            <p>{file?.Filename}</p>
                                            <label>size - {file?.Filesize}</label>
                                            <button onClick={() => deleteConfirm(file, a, index)}><FaRegTrashAlt /></button>
                                        </div> : ''
                                )}
                            </div> : ''}
                        {replyIndex === index && (
                            <div className="reply-area">
                                <div className="reply-btns">
                                    <div>
                                        <Button onClick={() => applyStyle('bold')} title='Bold'>
                                            <FaBold />
                                        </Button>
                                        <Button onClick={() => applyStyle('italic')} title='Italic'>
                                            <FaItalic />
                                        </Button>
                                        <Button onClick={() => applyStyle('underline')} title='Underline'>
                                            <FaUnderline />
                                        </Button>
                                        <Button onClick={() => applyStyle('superscript')} title='SuperScript'>
                                            <FaSuperscript />
                                        </Button>
                                        <Button onClick={() => applyStyle('subscript')} title='SubScript'>
                                            <FaSubscript />
                                        </Button>
                                        {/* {item.attachment.length === 0 ? */}
                                        <Button className="attchcomment-btn" title='Attachment'>
                                            <FaPaperclip />
                                            <input type="file" onChange={(e) => handleUpload(e)} ref={fileInputRef}></input>
                                        </Button>
                                        {/* : ""} */}
                                    </div>
                                    <div>
                                        <Button onClick={handleCancel}><FaTimes /></Button>
                                    </div>
                                </div>
                                <div className="CommentContent">
                                    <div contentEditable="true" id="TypeContent" className="CommentTextEntry UIFont Cmdtxtcontent"></div>
                                </div>
                                {fileAttachment.length !== 0 ? <div className='comment-attached'>
                                    {fileAttachment.map((item, b) =>
                                        <div className='comment-attached-list' key={b}>
                                            <p title={item.Filename}>{item.Filename}</p>
                                            <label>size - {item.Filesize}</label>
                                            <button onClick={() => deleteConfirm(item, b, index)}><FaRegTrashAlt /></button>
                                        </div>
                                    )}
                                </div> : ''
                                }
                                {loader ? <div className='fileupload-loder'><span className="loader"></span></div> : ''}
                                <div className="reply-footer">
                                    <Button className="save-btn" onClick={() => handleReplySave(index)}><FaPaperPlane /> Post</Button>
                                </div>
                            </div>
                        )}
                        {!queryStatus ?
                            <div className="comment-footer">
                                {editingIndex === index ? (
                                    <>
                                        <Button className="save-btn" onClick={() => handleSave(item.linenumber, item.wholematch, editedQuery)}><FaSave /> Save</Button>
                                        <Button className="cancel-btn" onClick={handleCancel}><FaTimes /> Cancel</Button>
                                    </>
                                ) : (
                                    <>
                                        {item.aqCheck ?
                                            <div className="radio-buttons">
                                                <div>
                                                    <input type="radio" name={'aqchecker' + index} id="yes" value="yes" checked={item.answerCheck === 'yes'} onChange={(e) => handleRadio(e, index)} />
                                                    <label htmlFor={'aqchecker' + index}> Yes</label>
                                                </div>
                                                <div>
                                                    <input type="radio" name={'aqchecker' + index} id="no" value="no" checked={item.answerCheck === 'no'} onChange={(e) => handleRadio(e, index)} />
                                                    <label htmlFor={'aqchecker' + index}> No</label>
                                                </div>
                                            </div> :
                                            <div className="checkbox-area" style={{ display: item.replies?.length > 0 ? 'none' : 'block' }}>
                                                <input
                                                    type="checkbox"
                                                    name="querycheck"
                                                    value="querycheck"
                                                    onChange={() => handleMark(index)}
                                                    checked={item.repliesMark}
                                                />
                                                <label htmlFor="querycheck">No action required</label>
                                            </div>
                                        }

                                        <Button
                                            className="accept-btn"
                                            disabled={
                                                item.repliesMark || // Disable if replies are marked
                                                item.answerCheck === 'yes' || // Disable if answerCheck is 'yes'
                                                (item.aqCheck && item.answerCheck === 'yes') || // Disable for 'yes' radio in aqCheck
                                                item.replies?.length > 0 // Disable if already has replies
                                            }
                                            style={{
                                                display: item.aqCheck
                                                    ? 'none' // Show/hide based on showReplyButton for aqCheck
                                                    : (item.replies?.length > 0 || item.repliesMark || item.answerCheck === 'yes')
                                                        ? 'none'
                                                        : 'inline-block' // Default display logic
                                            }}
                                            onClick={() => handleReply(index)}
                                        >
                                            <FaReply /> Reply
                                        </Button>
                                        {/* <Button className="reject-btn" onClick={() => handleDelete(item.linenumber, item.wholematch)} disabled> <FaTimes /> Delete</Button>
                                        <Button className="edit-btn" onClick={() => handleEdit(index, item.query)} disabled><FaEdit /> Edit</Button> */}
                                    </>
                                )}
                            </div > : ''}
                    </div >
                )}
            </div >
        </div >
    );
};


export default QueryTab;