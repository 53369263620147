import { createContext, useContext, useState, useMemo } from "react";

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
    const [fullData, setFullData] = useState(null);
    const [saveTrigger, setSaveTrigger] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [trackChanges, setTrackChanges] = useState(null);
    const [trackAction, setTrackAction] = useState(null);
    const [lineFocus, setLineFocus] = useState(null);
    const [viewDoc, setViewDoc] = useState(null);
    const [authorQuery, setAuthorQuery] = useState(null);
    const [authorQueryChange, setAuthorQueryChange] = useState(null);
    const [trackChangeBlob, setTrackChangeBlob] = useState(null);
    const [microSave, setMicroSave] = useState(null);
    const [commentValue, setCommentValue] = useState(null);
    const [commentRange, setCommentRange] = useState(null);
    const [commentSidebar, setCommentSidebar] = useState(null);
    const [commentDelete, setCommentDelete] = useState(null);
    const [trackContent, setTrackContent] = useState('');
    const [editorContent, setEditorContent] = useState('');
    const [layout, setLayout] = useState('editorandpdf');
    const [editorStyle, setEditorStyle] = useState('');
    const [readOnly, setReadOnly] = useState(false);
    const [trackfileComment, setTrackfileComment] = useState([]);
    const [highlights, setHighlights] = useState(null);
    const [linepostion, setLinePostion] = useState(null);
    const [editor, setEditor] = useState(null);
    const [mainView, setMainView] = useState(null);
    const [sideBarClose, setSidebarClose] = useState(false);
    const [supplementaryData, setSupplementaryData] = useState([]);
    const [supplementaryGetData, setSupplementaryGetData] = useState([]);
    const [fileContentData, setFileContentData] = useState('');
    const [changedComment, setChangedComment] = useState([]);
    const [closeErrorLog, setCloseErrorLog] = useState(false);

    const value = useMemo(() => ({
        userProfile, setUserProfile,
        trackChanges, setTrackChanges,
        trackAction, setTrackAction,
        lineFocus, setLineFocus,
        viewDoc, setViewDoc,
        authorQuery, setAuthorQuery,
        authorQueryChange, setAuthorQueryChange,
        trackChangeBlob, setTrackChangeBlob,
        microSave, setMicroSave,
        commentValue, setCommentValue,
        commentRange, setCommentRange,
        commentSidebar, setCommentSidebar,
        commentDelete, setCommentDelete,
        fullData, setFullData,
        saveTrigger, setSaveTrigger,
        trackContent, setTrackContent,
        editorContent, setEditorContent,
        layout, setLayout,
        editorStyle, setEditorStyle,
        readOnly, setReadOnly,
        trackfileComment, setTrackfileComment,
        highlights, setHighlights,
        linepostion, setLinePostion,
        editor, setEditor,
        mainView, setMainView,
        sideBarClose, setSidebarClose,
        supplementaryData, setSupplementaryData,
        supplementaryGetData, setSupplementaryGetData,
        fileContentData, setFileContentData,
        changedComment, setChangedComment,
        closeErrorLog, setCloseErrorLog
    }), [
        userProfile, setUserProfile,
        trackChanges, setTrackChanges,
        trackAction, setTrackAction,
        lineFocus, setLineFocus,
        viewDoc, setViewDoc,
        authorQuery, setAuthorQuery,
        authorQueryChange, setAuthorQueryChange,
        trackChangeBlob, setTrackChangeBlob,
        microSave, setMicroSave,
        commentValue, setCommentValue,
        commentRange, setCommentRange,
        commentSidebar, setCommentSidebar,
        commentDelete, setCommentDelete,
        fullData, setFullData,
        saveTrigger, setSaveTrigger,
        trackContent, setTrackContent,
        editorContent, setEditorContent,
        layout, setLayout,
        editorStyle, setEditorStyle,
        readOnly, setReadOnly,
        trackfileComment, setTrackfileComment,
        highlights, setHighlights,
        linepostion, setLinePostion,
        editor, setEditor,
        mainView, setMainView,
        sideBarClose, setSidebarClose,
        supplementaryData, setSupplementaryData,
        supplementaryGetData, setSupplementaryGetData,
        fileContentData, setFileContentData,
        changedComment, setChangedComment,
        closeErrorLog, setCloseErrorLog
    ]);

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContextProvider = () => {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error(
            "useAppContextProvider must be used within an AppContextProvider"
        );
    }

    return context;
};
