import axios from "axios";
import pathconfig from '../../pathconfig.json';
import packjson from '../../../package.json';

const convertToBase64 = (str) => {
    return btoa(str);
};

const convertToBase64Macro = (str) => {
    const utf8Bytes = new TextEncoder().encode(str); // Convert string to UTF-8 byte array
    const binaryString = Array.from(utf8Bytes, (byte) => String.fromCharCode(byte)).join(''); // Convert byte array to binary string
    return btoa(binaryString); // Encode binary string to Base64
};


export const styleEditing = async (value) => {
    try {
        let url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].retrievefiles}`;
        let GetDetailDataString = localStorage.getItem(`${value.docid}_init-getdetails`);
        let GetDetailData = JSON.parse(GetDetailDataString);

        // 1. Check if folder exists
        let folderPath = GetDetailData.Articledetails.ArtResourcePath;

        let data = { InputPath: folderPath };
        let pts_file_name;
        let s5base64;
        let inibase64;

        // 2. Retrieve details and check if the folder contains files
        const fileResponse = await axios.post(url, data);
        console.log(fileResponse.data, 'response');

        // If no files are found in the supplementary folder
        if (fileResponse.data.length === 0) {
            return {
                error: true,
                message: `Supporting files missing in the Supplementary folder. Please contact Admin.`,
            };
        }
        // 3. Check if 'pts_order.xml' file exists
        let findData = fileResponse.data.filter((item) => item.path.includes("pts_order"));
        if (findData.length === 0) {
            return {
                error: true,
                message: `pts_order.xml file is missing in the Supplementary folder.`,
            };
        }
        pts_file_name = findData[0].path.split('/').pop();
        // 4. Check if pts_order file exists
        let ptsFilePath = `${folderPath}/${pts_file_name}`;
        let ptsFileData = { InputPath: ptsFilePath };
        let ptsFileExistResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobFileExistorNot}`, ptsFileData);

        if (!ptsFileExistResponse.data.exist) {
            return {
                error: true,
                message: `${pts_file_name} file is missing in the Supplementary folder.`,
            };
        }
        // 5. Check if 'ELS_user_preedit-mapping.ini' file exists
        let iniFilePath = `${folderPath}/ELS_user_preedit-mapping.ini`;
        let iniFileData = { InputPath: iniFilePath };
        let iniFileExistResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobFileExistorNot}`, iniFileData);
        if (!iniFileExistResponse.data.exist) {
            return {
                error: true,
                message: `ELS_user_preedit-mapping.ini file is missing in the Supplementary folder.`,
            };
        }
        // 6. Fetch the XML data for the pts_order file
        const XmlData = { InputPath: ptsFilePath };
        const ptsFilePreviewResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_preview_file}`, XmlData);
        s5base64 = convertToBase64(ptsFilePreviewResponse.data);
        // 7. Fetch the ini file
        const iniData = { InputPath: iniFilePath };
        const iniFilePreviewResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_preview_file}`, iniData);
        inibase64 = convertToBase64(iniFilePreviewResponse.data);
        // 8. Prepare request body for the final request
        let body = {
            texfilename: `${value.texfilename}.tex`,
            texcontent: convertToBase64Macro(value.texcontent),
            s5filename: pts_file_name,
            s5content: s5base64,
            inifilename: 'ELS_user_preedit-mapping.ini',
            inicontent: inibase64,
        };

        let styleUrl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].styleEditing}`;
        // 9. Make the final axios request
        const response = await axios.post(styleUrl, body);
        let errorObjects = [];
        let textContent;
        // 10. Handle success and errors
        if (response.data.is_success === "true") {
            if (response.data.is_error_status === "true" || response.data.is_warning_status === "true") {
                let logContentBase64 = atob(response.data.data.logcontent);
                const regex = /\[(\d+:\d+)\]:\s(Error|Warning):\[[A-Z]+-\d+\]:\s(.+)/g;
                const matches = [...logContentBase64.matchAll(regex)];
                errorObjects = matches.map(match => ({
                    line: match[1].split(':')[0],
                    type: match[2],
                    message: match[3].trim(),
                }));
                if (response.data.is_warning_status === "true" && response.data.is_error_status === "false") {
                    textContent = atob(response.data.data.texcontent);
                }
            } else {
                textContent = atob(response.data.data.texcontent);
            }
            return { errorObjects, textcontent: textContent, responseData: response.data };
        } else {
            return { errorObjects, textcontent: textContent, responseData: response.data };
        }
    } catch (error) {
        console.log(error);
        return {
            error: true,
            message: error.message || 'An error occurred during the API call',
        };
    }
};
export const issueUpdation = async (value) => {
    try {
        let url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].retrievefiles}`;
        let GetDetailDataString = localStorage.getItem(`${value.docid}_init-getdetails`);
        let GetDetailData = JSON.parse(GetDetailDataString);

        // 1. Check if folder exists
        let folderPath = GetDetailData.Articledetails.ArtResourcePath;

        let data = { InputPath: folderPath };
        let pts_file_name;
        let ptsbase64;

        // 2. Retrieve details and check if the folder contains files
        const fileResponse = await axios.post(url, data);
        console.log(fileResponse.data, 'response');

        // If no files are found in the supplementary folder
        if (fileResponse.data.length === 0) {
            return {
                error: true,
                message: `Supporting files missing in the Supplementary folder. Please contact Admin.`,
            };
        }
        // 3. Check if 'pts_order.xml' file exists
        let findData = fileResponse.data.filter((item) => item.path.includes("pts_order"));
        if (findData.length === 0) {
            return {
                error: true,
                message: `pts_order.xml file is missing in the Supplementary folder.`,
            };
        }

        // Then validate if it's either s300 or s250 format
        const ptsPattern = /pts_order_S(300|250)\.xml$/;
        const validPtsFiles = findData.filter(item => ptsPattern.test(item.path));
        if (validPtsFiles.length !== 1) {
            return {
                error: true,
                message: `Exactly one pts_order file in format pts_order_s300.xml or pts_order_s250.xml must be present`,
            };
        }

        pts_file_name = validPtsFiles[0].path.split('/').pop();
        // 4. Check if pts_order file exists
        let ptsFilePath = `${folderPath}/${pts_file_name}`;
        let ptsFileData = { InputPath: ptsFilePath };
        let ptsFileExistResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobFileExistorNot}`, ptsFileData);

        if (!ptsFileExistResponse.data.exist) {
            return {
                error: true,
                message: `${pts_file_name} file is missing in the Supplementary folder.`,
            };
        }
        // 6. Fetch the XML data for the pts_order file
        const XmlData = { InputPath: ptsFilePath };
        const ptsFilePreviewResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_preview_file}`, XmlData);
        ptsbase64 = convertToBase64Macro(ptsFilePreviewResponse.data);
        // 8. Prepare request body for the final request
        let body = {
            texfilename: `${value.texfilename}.tex`,
            texcontent: convertToBase64Macro(value.texcontent),
            ptsfilename: pts_file_name,
            ptscontent: ptsbase64,
        };

        let styleUrl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].issueUpdation}`;
        // 9. Make the final axios request
        const response = await axios.post(styleUrl, body);
        let errorObjects = [];
        let textContent;
        // 10. Handle success and errors
        if (response.data.is_success === "true") {
            if (response.data.is_error_status === "true" || response.data.is_warning_status === "true") {
                let logContentBase64 = atob(response.data.data.logcontent);
                const regex = /\[(\d+:\d+)\]:\s(Error|Warning):\[[A-Z]+-\d+\]:\s(.+)/g;
                const matches = [...logContentBase64.matchAll(regex)];
                errorObjects = matches.map(match => ({
                    line: match[1].split(':')[0],
                    type: match[2],
                    message: match[3].trim(),
                }));
                if (response.data.is_warning_status === "true" && response.data.is_error_status === "false") {
                    textContent = atob(response.data.data.texcontent);
                }
            } else {
                textContent = atob(response.data.data.texcontent);
            }
            return { errorObjects, textcontent: textContent, responseData: response.data };
        } else {
            return { errorObjects, textcontent: textContent, responseData: response.data };
        }
    } catch (error) {
        console.log(error);
        return {
            error: true,
            message: error.message || 'An error occurred during the API call',
        };
    }
};
export const XMPCreation = async (value) => {
    try {
        let url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].retrievefiles}`;
        let GetDetailDataString = localStorage.getItem(`${value.docid}_init-getdetails`);
        let GetDetailData = JSON.parse(GetDetailDataString);

        // 1. Check if folder exists
        let folderPath = GetDetailData.Articledetails.ArtResourcePath;
        let OutputPath = GetDetailData.Articledetails.OutputPath.substring(0, GetDetailData.Articledetails.OutputPath.lastIndexOf("/"));

        let data = { InputPath: folderPath };
        let pts_file_name;
        let s5base64;
        let bookbase64;

        // 2. Retrieve details and check if the folder contains files
        const fileResponse = await axios.post(url, data);
        console.log(fileResponse.data, 'response');

        // If no files are found in the supplementary folder
        if (fileResponse.data.length === 0) {
            return {
                error: true,
                message: `Supporting files missing in the Supplementary folder. Please contact Admin.`,
            };
        }
        // 3. Check if 'pts_order.xml' file exists
        let findData = fileResponse.data.filter((item) => item.path.includes("pts_order"));
        if (findData.length === 0) {
            return {
                error: true,
                message: `pts_order.xml file is missing in the Supplementary folder.`,
            };
        }
        pts_file_name = findData[0].path.split('/').pop();
        // 4. Check if pts_order file exists
        let ptsFilePath = `${folderPath}/${pts_file_name}`;
        let ptsFileData = { InputPath: ptsFilePath };
        let ptsFileExistResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobFileExistorNot}`, ptsFileData);

        if (!ptsFileExistResponse.data.exist) {
            return {
                error: true,
                message: `${pts_file_name} file is missing in the Supplementary folder.`,
            };
        }
        // 5. Check if 'BookDetails.xml' file exists
        let bookDetailsPath = `${folderPath}/BookDetails.xml`;
        let bookDetailsData = { InputPath: bookDetailsPath };
        let bookDetailsExistResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobFileExistorNot}`, bookDetailsData);
        if (!bookDetailsExistResponse.data.exist) {
            return {
                error: true,
                message: `BookDetails.xmlfile is missing in the Supplementary folder.`,
            };
        }
        // 6. Check if 'texfilename.pginfo' file exists
        let pgInfoPath = `${OutputPath}/${value.texfilename}.pgInfo`
        let pgInfoData = { InputPath: pgInfoPath };
        let pgInfoExistResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobFileExistorNot}`, pgInfoData);
        if (!pgInfoExistResponse.data.exist) {
            return {
                error: true,
                message: `pgInfo file is missing in the Supplementary folder.`,
            }
        }

        // 6. Fetch the XML data for the pts_order file
        const XmlData = { InputPath: ptsFilePath };
        const ptsFilePreviewResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_preview_file}`, XmlData);
        s5base64 = convertToBase64(ptsFilePreviewResponse.data);
        // 7. Fetch the Book file
        const iniData = { InputPath: bookDetailsPath };
        const iniFilePreviewResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_preview_file}`, iniData);
        bookbase64 = convertToBase64(iniFilePreviewResponse.data);

        let pgInfoData1 = { InputPath: pgInfoPath };
        const pgInfoFilePreviewResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_preview_file}`, pgInfoData1);
        let pgInfoBase64 = convertToBase64(pgInfoFilePreviewResponse.data);
        // 8. Prepare request body for the final request
        let body = {
            texfilename: `${value.texfilename}.tex`,
            texcontent: convertToBase64Macro(value.texcontent),
            ptsfilename: pts_file_name,
            ptscontent: s5base64,
            bookdetailsfilename: 'BookDetails.xml',
            bookdetailscontent: bookbase64,
            pginfofilename: `${value.texfilename}.pgInfo`,
            pginfocontent: pgInfoBase64,
        };

        let styleUrl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].xmpCreation}`;
        // 9. Make the final axios request
        const response = await axios.post(styleUrl, body);
        let errorObjects = [];



        // 11. Handle success and errors
        if (response.data.is_success === "true") {
            if (response.data.is_error_status === "true" || response.data.is_warning_status === "true") {
                let logContentBase64 = atob(response.data.data.logcontent);
                const regex = /\[(\d+:\d+)\]:\s(Error|Warning):\[[A-Z]+-\d+\]:\s(.+)/g;
                const matches = [...logContentBase64.matchAll(regex)];
                errorObjects = matches.map(match => ({
                    line: match[1].split(':')[0],
                    type: match[2],
                    message: match[3].trim(),
                }));
            } else {
                // textContent = atob(response.data.data.xmpcontent);
                // downloadXMPFile(response.data.data.xmpcontent, response.data.data.xmpfilename);
                let xmpContent = response.data.data.xmpcontent;
                let xmpFilename = response.data.data.xmpfilename;
                // Decode base64 content with UTF-8 encoding
                const decodedContent = atob(xmpContent);
                const blob = new Blob([decodedContent], { type: 'application/xml' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = xmpFilename;
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);

            }
            return { errorObjects, responseData: response.data };
        } else {
            return { errorObjects, responseData: response.data };
        }
    } catch (error) {
        console.log(error);
        return {
            error: true,
            message: error.message || 'An error occurred during the API call',
        };
    }
};

export const referanceOrdering = async (refValue, runningApi) => {
    let apiUrls = [
        `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].reforderStructuring}`,
        `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].authorStructuring}`,
        `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].pagerangeStructuring}`,
        `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].journalAbbreviation}`,
        `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].titleCasing}`,
        `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].refSortQuery}`
    ];

    let textcontent = refValue.texcontent;

    // Define body outside the loop
    let body = {
        'texfilename': `${refValue.texfilename}.tex`,
        'texcontent': convertToBase64Macro(textcontent)  // Initial content, will be updated in the loop
    };

    let errorObjects = [];

    for (let i = 0; i < apiUrls.length; i++) {
        try {
            let apiname = apiUrls[i].split('/').pop() === "reforderStructuring" ? "Reforder Structuring" : apiUrls[i].split('/').pop() === "authorStructuring" ? "Author Structuring" : apiUrls[i].split('/').pop() === "pagerangeStructuring" ? "Page Range Structuring" : apiUrls[i].split('/').pop() === "journalAbbreviation" ? "Journal Abbreviation" : apiUrls[i].split('/').pop() === "titleCasing" ? "Title Casing" : apiUrls[i].split('/').pop() === "refSortQuery" ? "Ref Sort Query" : "";
            runningApi(apiname);

            // Decode for debugging
            let bodytexcontent = atob(body.texcontent);
            console.log(bodytexcontent, 'Decoded body.texcontent');

            // Make API request
            const response = await axios.post(apiUrls[i], body);
            const { data } = response;

            if (data.is_error_status === 'true') {
                // Handle errors
                let logContentBase64 = atob(data.data.logcontent);
                const regex = /\[(\d+:\d+)\]:\s(Error|Warning):\[[A-Z]+-\d+\]:\s(.+)/g;
                const matches = [...logContentBase64.matchAll(regex)];

                errorObjects = matches.map(match => ({
                    line: match[1].split(':')[0],
                    type: match[2],
                    message: match[3].trim()
                }));

                return {
                    errorObjects,
                    responseData: data,
                    api: apiUrls[i].split('/').pop()
                };
            } else {
                // Update texcontent for the next iteration
                textcontent = data.data.texcontent;  // Base64 encoded
                body.texcontent = textcontent;       // Update body with new content for next API call
            }

            // After the last API call, return the final data
            if (i === apiUrls.length - 1) {
                if (data.is_warning_status !== "false") {
                    let logContentBase64 = atob(data.data.logcontent);
                    const regex = /\[(\d+:\d+)\]:\s(Error|Warning):\[[A-Z]+-\d+\]:\s(.+)/g;
                    const matches = [...logContentBase64.matchAll(regex)];

                    errorObjects = matches.map(match => ({
                        line: match[1].split(':')[0],
                        type: match[2],
                        message: match[3].trim()
                    }));
                }

                return {
                    errorObjects,
                    texcontent: atob(data.data.texcontent),
                    responseData: data
                };
            }
        } catch (err) {
            // Catch any errors during API calls
            return {
                error: true,
                message: err.message || 'An error occurred during the API call',
                api: apiUrls[i].split('/').pop()
            };
        }
    }
};

export const clearCacheFiles = async (val) => {
    try {
        if (val !== undefined || val !== null) {
            let body = {
                'docid': val,
            };
            const cacheFile = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].cachefiledelete}`, body);
            return cacheFile.data;
        }
    } catch (err) {
        console.log(err)
    }
};

export const CleanupEditor = async (cleanvalue) => {
    let url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].retrievefiles}`;
    let GetDetailDataString = localStorage.getItem(`${cleanvalue.docid}_init-getdetails`);
    let GetDetailData = JSON.parse(GetDetailDataString);
    // Check if folder exists
    let folderPath = GetDetailData.Articledetails.ArtResourcePath;
    let data = { InputPath: folderPath };
    //Retrieve details and check if the folder contains files
    const fileResponse = await axios.post(url, data);
    // If no files are found in the supplementary folder
    if (fileResponse.data.length === 0) {
        return {
            error: true,
            message: `Supporting files missing in the Supplementary folder. Please contact Admin.`,
        };
    }
    // Check if 'marco.tex' file exists
    let findData = fileResponse.data.filter((item) => item.path.includes("macro"));
    if (findData.length === 0) {
        return {
            error: true,
            message: `marco.tex file is missing in the Supplementary folder.`,
        };
    }
    // Fetch the macro data string
    let iniFilePath = `${folderPath}/macro.tex`;
    const MacroData = { InputPath: iniFilePath };
    const macroResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_preview_file}`, MacroData);
    let macroBase64 = convertToBase64Macro(macroResponse.data);

    let textcontent = cleanvalue.texcontent;
    let body = {
        'texfilename': `${cleanvalue.texfilename}.tex`,
        'texcontent': convertToBase64(textcontent),
        'macrocontent': macroBase64,
    };

    let macroUrl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].macrocleanup}`;
    const response = await axios.post(macroUrl, body);
    let errorObjects = [];
    let textContent;
    // 10. Handle success and errors
    if (response.data.is_success === "true") {
        if (response.data.is_error_status === "true" || response.data.is_warning_status === "true") {
            let logContentBase64 = atob(response.data.data.logcontent);
            const regex = /\[(\d+:\d+)\]:\s(Error|Warning):\[[A-Z]+-\d+\]:\s(.+)/g;
            const matches = [...logContentBase64.matchAll(regex)];
            errorObjects = matches.map(match => ({
                line: match[1].split(':')[0],
                type: match[2],
                message: match[3].trim(),
            }));
            if (response.data.is_warning_status === "true" && response.data.is_error_status === "false") {
                textContent = atob(response.data.data.texcontent);
            }
        } else {
            textContent = atob(response.data.data.texcontent);
        }
        return { errorObjects, textcontent: textContent, responseData: response.data };
    } else {
        return { errorObjects, textcontent: textContent, responseData: response.data };
    }
};

const inputFileupload = async (OutputPath, filename, Inputcontent) => {
    try {
        let body = {
            'fileName': filename,
            'fileContent': Inputcontent,
            'OutputPath': OutputPath,
        }
        const response = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].UploadInputJsonData}`, body);
        return response.data;
    } catch (err) {
        console.log(err)
    }
};

export const iRefEditor = async (cleanvalue) => {
    try {
        let url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].iref
            }`;
        let GetDetailDataString = localStorage.getItem(
            `${cleanvalue.docid}_init-getdetails`
        );
        let GetDetailData = JSON.parse(GetDetailDataString);
        // Check if folder exists
        let OutputPath = GetDetailData.Articledetails.OutputPath.substring(0, GetDetailData.Articledetails.OutputPath.lastIndexOf("/"));
        let data = {
            texfilename: cleanvalue.texfilename,
            texcontent: convertToBase64Macro(cleanvalue.texcontent),
        };
        //Retrieve details and check if the folder contains files
        const irefResponse = await axios.post(url, data);
        console.log(irefResponse, "fileResponse");
        let errorObjects = [];
        // if (irefResponse.data.is_error_status === "true") {
        //     let logContentBase64 = atob(irefResponse.data.data.logcontent);
        //     const regex = /\[(\d+:\d+)\]:\s(Error|Warning):\[[A-Za-z]+-[A-Za-z0-9]+\]:\s(.+)/g;
        //     const matches = [...logContentBase64.matchAll(regex)];
        //     errorObjects = matches.map(match => ({
        //         line: match[1].split(':')[0],
        //         type: match[2],
        //         message: match[3].trim(),
        //     }));
        //     return { errorObjects, responseData: irefResponse.data };
        // }
        let textContent;
        if (irefResponse.data.is_success === "true") {
            if (
                irefResponse.data.is_error_status === "true" ||
                irefResponse.data.is_warning_status === "true"
            ) {
                let logContentBase64 = atob(irefResponse.data.data.logcontent);
                const regex =
                    /\[(\d+:\d+)\]:\s(Error|Warning):\[[A-Za-z]+-[A-Za-z0-9]+\]:\s(.+)/g;
                const matches = [...logContentBase64.matchAll(regex)];
                errorObjects = matches.map((match) => ({
                    line: match[1].split(":")[0],
                    type: match[2],
                    message: match[3].trim(),
                }));

                if (
                    irefResponse.data.is_warning_status === "true" &&
                    irefResponse.data.is_error_status === "false"
                ) {
                    textContent = atob(irefResponse.data.data.texcontent);
                    let fileupload = await inputFileupload(OutputPath, irefResponse.data.data.inputjsonfilename, atob(irefResponse.data.data.inputjsoncontent))
                    console.log(fileupload, "fileupload")

                }
            } else {
                textContent = atob(irefResponse.data.data.texcontent);
                let fileupload = await inputFileupload(OutputPath, irefResponse.data.data.inputjsonfilename, atob(irefResponse.data.data.inputjsoncontent))
                console.log(fileupload, "fileupload")
            }
            return {
                errorObjects,
                textcontent: textContent,
                responseData: irefResponse.data,
            };
        } else {
            return {
                errorObjects,
                textcontent: textContent,
                responseData: irefResponse.data,
            };
        }
    } catch (error) {
        console.log(error);
        return {
            error: true,
            message: error.message || "An error occurred during the API call",
        };
    }
};

export const CommentUpdateFunction = (commentFromTo, Comment) => {
    const processedCids = new Set();
    // Ensure `updatedComments` excludes undefined or invalid results
    const updatedComments = commentFromTo?.map((itemA) => {
        const matchingBlob = Comment?.find((itemB) => itemB.cid === itemA.cid);
        if (matchingBlob && !processedCids.has(itemA.cid)) {
            processedCids.add(itemA.cid);
            return {
                ...matchingBlob,
                ActivityID: matchingBlob.ActivityID,
                Attachments: matchingBlob.Attachments,
                Cmdcomtents: matchingBlob.Cmdcomtents,
                Date_Time: matchingBlob.Date_Time,
                NoActionRequired: matchingBlob.NoActionRequired,
                ReplyRequire: matchingBlob.ReplyRequire,
                Username: matchingBlob.Username,
                cid: matchingBlob.cid,
                cmdtype: matchingBlob.cmdtype,
                "element-type": "Comments",
                from: itemA.from,
                isTrack: false,
                linenumber: matchingBlob.linenumber, // Safely access viewDoc
                orderid: matchingBlob.orderid,
                reply: matchingBlob.reply,
                role: matchingBlob.role,
                selectText: matchingBlob.selectText,
                sequencenumber: "",
                status: "",
                to: itemA.to,
                trackType: matchingBlob.trackType,
            };
        }
        return null; // Return null if no matchingBlob found
    })
        .filter((comment) => comment !== null); // Exclude null values

    // Prepare the final object for the updated comments
    const CommentsChange = { comment: updatedComments, changeIndex: null };
    return CommentsChange;
};