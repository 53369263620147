import { useState, useEffect } from "react";
import { FaTimes, FaReply, FaPencilAlt, FaRegTrashAlt, FaFileAlt } from "react-icons/fa";
import CommentBox from "./commments/CommentBox";
import { Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { toastError, toastSuccess } from '../toaster';
import pathconfig from '../../pathconfig.json';
import packjson from '../../../package.json';
import axios from "axios";
import { useAppContextProvider } from '../../contextapi';

const generateUniqueId = () => {
  return uuidv4().slice(0, 3); // Generate a 3-letter unique ID
};
const handleError = (error, message = error || 'Something went wrong') => {
  console.error(error);
  toastError(message);
};
const CommentTab = (props) => {
  const [comments, setComments] = useState([]);
  const [commentsAll, setCommentsAll] = useState([]);
  const [replyingIndex, setReplyingIndex] = useState(null);
  const [replyText, setReplyText] = useState('');
  const [repliedComments, setRepliedComments] = useState({});
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState('');
  const [showconfirm, setConfirm] = useState(false)
  const [deleteValue, setDeleteValue] = useState({})
  const { userProfile, setLineFocus, viewDoc, commentValue, setCommentValue, commentSidebar, setCommentDelete, trackfileComment, setSidebarClose } = useAppContextProvider();
  const [isEditing, setIsEditing] = useState(false);
  //just for testing build
  useEffect(() => {
    const hasCommentsChanged = (prevComments, newComments) => {
      // Implement a deep comparison logic here
      return JSON.stringify(prevComments) !== JSON.stringify(newComments);
    };
    if (hasCommentsChanged(commentValue.comment, comments)) {
      // let commentAdded =comments.map((item) => item.ActivityID === undefined ? props.isDocvalue.ActivityID : item.ActivityID);
      let CommentsChange = { comment: comments, changeIndex: null }
      setCommentValue(CommentsChange);
    }
  }, [comments, commentValue.comment]);

  // useEffect(() => {
  //   if (commentBlob.length > 0) {
  //     const filterBlob = commentBlob.filter(item => item?.comment !== '')
  //     const filterFromComment = filterBlob.filter((itemB) =>
  //       comments.some((itemC) => itemC.cid === itemB.cid)
  //     )
  //     setCommentRange(filterFromComment);
  //   }
  // }, [commentBlob]);

  useEffect(() => {
    if (commentValue?.comment !== undefined && commentValue?.comment.length > 0) {
      setComments(commentValue?.comment);
      setCommentsAll(commentValue?.comment);
      // setCommentBlob(commentValue)
    }
  }, [commentValue]);

  // export const CommentUpdateFunction = (value) => {
  //   // Ensure `updatedComments` excludes undefined or invalid results
  //   console.log(value, 'value')
  //   const updatedComments = props.changedComment
  //     .filter((itemA) => itemA !== undefined) // Filter out undefined items
  //     .map((itemA) => {
  //       const matchingBlob = comments.find((itemB) => itemB.cid === itemA.cid);
  //       if (matchingBlob) {
  //         return {
  //           ...matchingBlob,
  //           ActivityID: matchingBlob.ActivityID,
  //           Attachments: matchingBlob.Attachments,
  //           Cmdcomtents: matchingBlob.Cmdcomtents,
  //           Date_Time: matchingBlob.Date_Time,
  //           NoActionRequired: matchingBlob.NoActionRequired,
  //           ReplyRequire: matchingBlob.ReplyRequire,
  //           Username: matchingBlob.Username,
  //           cid: matchingBlob.cid,
  //           cmdtype: matchingBlob.cmdtype,
  //           "element-type": "Comments",
  //           from: itemA.from,
  //           isTrack: false,
  //           linenumber: viewDoc?.state.doc.lineAt(itemA.from)?.number || 0, // Safely access viewDoc
  //           orderid: matchingBlob.orderid,
  //           reply: matchingBlob.reply,
  //           role: matchingBlob.role,
  //           selectText: matchingBlob.selectText,
  //           sequencenumber: "",
  //           status: "",
  //           to: itemA.to,
  //           trackType: matchingBlob.trackType,
  //         };
  //       }
  //       return null; // Return null if no matchingBlob found
  //     })
  //     .filter((comment) => comment !== null); // Exclude null values

  //   // Prepare the final object for the updated comments
  //   const CommentsChange = { comment: updatedComments, changeIndex: null };

  //   // Update the state with the changed comments
  //   setChangedComment(CommentsChange);

  //   // Log the result for debugging
  //   console.log(CommentsChange, 'CommentsChange');
  // };

  // useEffect(() => {
  //     if (props?.isCommentValue.length !== 0) {
  //         setCommentBlob(props?.isCommentValue)
  //     }
  // }, [props?.isCommentValue])

  // useEffect(() => {
  //   if (Object.keys(commentRange).length !== 0 && comments.length > 0) {
  //     const updatedComments = commentRange.map((itemA) => {
  //       const matchingBlob = commentRange.find((itemB) => itemB.cid === itemA.cid);
  //       return matchingBlob
  //         ? {
  //           ...itemA,
  //           from: matchingBlob.from,
  //           to: matchingBlob.to,
  //           linenumber: viewDoc.state.doc.lineAt(matchingBlob.from).number,
  //         }
  //         : itemA;
  //     });
  //     // Check if the comments have actually changed before updating the state
  //     if (JSON.stringify(comments) !== JSON.stringify(updatedComments)) {
  //       setComments(updatedComments);
  //       setCommentsAll(updatedComments);
  //     }
  //   }
  // }, [commentRange]);


  const handleSaveComment = (commentText) => {
    try {
      if (commentSidebar?.selectValue.selectText !== '') {
        if (commentText) {
          const comment = {
            Username: userProfile.username, // Replace with actual username
            role: userProfile.role,
            ActivityID: userProfile.ActivityID,
            cid: generateUniqueId(), // Generate a unique ID for the comment
            Date_Time: new Date().toLocaleString(),
            Cmdcomtents: commentText.comment,
            reply: [], // Initialize reply as an empty array
            Attachments: commentText.fileattached,
            ReplyRequire: false,
            status: '',
            isTrack: false,
            trackType: 'none',
            'element-type': 'Comments',
            cmdtype: 'Comments',
            orderid: 1,
            NoActionRequired: false,
            sequencenumber: '',
            linenumber: commentSidebar?.selectValue.linenumber,
            selectText: commentSidebar?.selectValue.selectText,
            from: commentSidebar?.selectValue.fromLine,
            to: commentSidebar?.selectValue.toLine,
          };
          setComments([...comments, comment]);
          setCommentsAll([...comments, comment])
          // setLineFocus(comment);
        }
      } else {
        toastError('Please select a word or text to insert a comment..')
      }
    } catch (error) {
      handleError(error, 'Error saving comment');
    }
  };

  const handleConfirmDelete = (e, index, replyIndex = null) => {
    setDeleteValue({ 'index': index, 'replyValue': replyIndex })
    setConfirm(true)
  }

  const handleDeleteComment = () => {
    try {
      const updatedComments = comments.filter((comment, index) => {
        if (index === deleteValue.index) {
          if (deleteValue.replyValue === null) {
            // Deleting a main comment
            return false; // Remove this comment
          } else {
            // Deleting a reply
            comment.reply = comment.reply.filter((_, replyIndex) => replyIndex !== deleteValue.replyValue);
            return true; // Keep the main comment, but with updated replies
          }
        }
        return true;
        // Keep all other comments
      });
      const deletedComment = deleteValue.replyValue === null
        ? comments[deleteValue.index]
        : comments[deleteValue.index].reply[deleteValue.replyValue];
      setComments(updatedComments);
      setCommentsAll(updatedComments);
      setCommentDelete(deletedComment);
      setConfirm(false);
      toastSuccess('Message deleted successfully.');
    } catch (error) {
      handleError(error, 'Error deleting comment');
    }
  };

  const handleReplyButtonClick = (e, index) => {
    e.stopPropagation()
    setReplyingIndex(index);
    setReplyText('');
  };

  const handleSendReply = (commentData, commentIndex) => {
    try {
      const cleanupReplycomment = replyText
        .replace(/<[^>]*>/g, "")
        .replace(/&nbsp;/g, "");

      if (!cleanupReplycomment.trim()) {
        toastError('Reply cannot be empty');
        return;
      }

      const reply = {
        Username: userProfile.username, // Replace with actual username
        role: userProfile.role,
        ActivityID: userProfile.ActivityID,
        Date: new Date().toLocaleDateString(),
        Time: new Date().toLocaleTimeString(),
        rpid: generateUniqueId(), // Generate a unique ID for the reply
        Date_Time: new Date().toLocaleString(),
        rplycomtents: cleanupReplycomment,
        newcmnd: true,
        status: '',
        sequencenumber: '',
        cmdtype: 'ReplyCmnt',
        orderid: 1,
        id: comments[commentIndex].cid,
        NoActionRequired: false,
      };
      const updatedComments = [...comments];
      updatedComments[commentIndex].reply.push(reply);
      updatedComments[commentIndex].Date = new Date().toLocaleDateString(); // Update Date
      updatedComments[commentIndex].Date_Time = new Date().toLocaleString(); // Update Date_Time
      setComments(updatedComments);
      setCommentsAll(updatedComments);
      setReplyingIndex(null)
      setReplyText('');
      setRepliedComments({ ...repliedComments, [commentIndex]: true });
      setTimeout(() => {
        let CommentsChange = { comment: comments, changeIndex: commentIndex }
        setCommentValue(CommentsChange);
      }, 1000);
    } catch (error) {
      handleError(error, 'Error sending reply');
    }
  };

  const handleCommentClick = (commentId) => {
    setLineFocus(commentId);
  };

  const handleEditComment = (e, index) => {
    e.stopPropagation();
    const commentToEdit = comments[index];
    setEditingIndex(index);
    // Ensure we're setting the exact content from the comment
    setEditedCommentText(commentToEdit.Cmdcomtents);
    setIsEditing(true);
    console.log("Editing comment:", commentToEdit.Cmdcomtents); // Debug log
  };

  const deleteConfirm = (value, indexvalue) => {
    try {
      props.getTrigger(false)
      let GetDetailDataString = localStorage.getItem(`${userProfile.docid}_init-getdetails`);
      let GetDetailData = JSON.parse(GetDetailDataString);
      let data = {
        "ArticleGUID": value.ArticleGUID,
        "AttachID": value.AttachID,
        "Proof_Type": GetDetailData.jobdetails.ProofType,
        "AttachFlag": value.AttachFlag,
        "docid": userProfile.docid
      }
      const deleteUrl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].deleteAttachment}`;
      axios.post(deleteUrl, data).then((response) => {
        toastSuccess(`${response.data.data[0].Filename} - ${response.data.message}`)
        props.getTrigger(true)
      }
      ).catch((error) => {
        console.log(error);
        toastError(error.message);
        //navigate(`/notification?docid=${profile.docid}`, { state: 'error' })
      });
      const attachmentDelete = [...comments];
      attachmentDelete[indexvalue].Attachments = [];
      setComments(attachmentDelete);
      setCommentsAll(attachmentDelete);
    } catch (error) {
      handleError(error, 'Error confirming deletion');
      props.getTrigger(true);
    }
  }

  const getTriggerfile = () => {
    props.getTrigger(true)
  }

  const handleSelect = (e) => {
    try {
      if (e.target.value === 'all') {
        setCommentsAll(comments)
      } else {
        let filter = comments.filter((item) => item.role === e.target.value)
        setCommentsAll(filter)
      }
    } catch (error) {
      handleError(error, 'Error filtering comments');
    }
  }

  useEffect(() => {
    if (trackfileComment.length !== 0) {
      setComments(trackfileComment);
      setCommentsAll(trackfileComment)
    }
  }, [trackfileComment]);

  return (
    <div className="comment-area">
      <div className="slider-header">
        <h5>Comment - ({commentsAll.length})</h5>
        <div style={{ width: '20px' }}>
          <FaTimes onClick={() => setSidebarClose(true)} />
        </div>
      </div>
      <div className={`modal-outer ${showconfirm ? 'active' : ''}`}>
        <div className="modal confirmation-popup">
          <div className="modal-body">
            <h1>Are you want to delete this comment?</h1>
          </div>
          <div className="modal-footer">
            <button className="primary" onClick={handleDeleteComment}>Yes</button>
            <button className="outline-secondary" onClick={() => setConfirm(false)}>No</button>
          </div>
        </div>
      </div>
      <div className="comment-group">
        <div className="comment-note">
          <FaFileAlt /> <p>Note: Please select a word or text to insert a comment</p>
        </div>
        <CommentBox onSaveComment={handleSaveComment} onCancelComment={() => setReplyingIndex(null)} getTriggerfile={getTriggerfile} />
        <div className="filterTop" style={{ marginTop: '10px' }}>
          <label>Filter:</label>
          <select aria-label="Default select example" onChange={(e) => handleSelect(e)}>
            <option value="all">All</option>
            <option value="Author">Author</option>
            <option value="Editor">Editor</option>
            <option value="Master Copier">Master Copier</option>
            <option value="Copy Editor">Copy Editor</option>
          </select>
        </div>
        <div className="comment-container">
          {commentsAll.length > 0 && commentsAll.map((comment, index) => {
            const attachmentContent = Array.isArray(comment?.Attachments) && comment?.Attachments?.length > 0 ? (
              <div className='comment-attached' style={{ marginTop: '6px' }}>
                {comment.Attachments.map((item, attachmentIndex) => (
                  <div className='comment-attached-list' key={`attachment-${index}-${attachmentIndex}`}>
                    <p title={item.Filename}>{item.Filename}</p>
                    <label>size - {item.Filesize}</label>
                    <button onClick={() => deleteConfirm(item, index)} onKeyDown={(e) => e.key === 'Enter' && deleteConfirm(item, index)}><FaRegTrashAlt /></button>
                  </div>
                ))}
              </div>
            ) : null
            return (
              <div
                key={`comment-${index}`}
                className="comment-display"
              >
                <div className="comment-inner">
                  <div
                    onClick={() => handleCommentClick(comment)}
                    onKeyDown={(e) =>
                      e.key === "Enter" &&
                      handleCommentClick(comment)
                    }
                    tabIndex={0}
                    role="button"
                  >
                    <h5>{comment.Username}{" "}</h5>
                    <label className="time-label">{comment.Date_Time}</label>
                    {editingIndex === index ? (
                      <CommentBox
                      onSaveComment={(commentData) => {
                        const cleanupEditComment = commentData.comment
                          .replace(/<(?!\/?(b|i|u|strong|em))[^>]*>/g, "")
                          .replace(/&nbsp;/g, " ")
                          .trim();
                          
                        // Check if comment has actual content after cleaning
                        const hasContent = cleanupEditComment.replace(/<[^>]*>/g, "").trim().length > 0;
                        
                        if (!hasContent) {
                          toastError('Comment cannot be empty');
                          return;
                        }
                        
                        const updatedComments = [...comments];
                        updatedComments[index].Cmdcomtents = cleanupEditComment;
                        updatedComments[index].Attachments = commentData.fileattached;
                        updatedComments[index].Date = new Date().toLocaleDateString();
                        updatedComments[index].Date_Time = new Date().toLocaleString();
                        setComments(updatedComments);
                        setCommentsAll(updatedComments);
                        setEditingIndex(null);
                        setIsEditing(false);
                      }}
                      
                        onCancelComment={() => {
                          setEditingIndex(null);
                          setIsEditing(false);
                        }}
                        getTriggerfile={getTriggerfile}
                        initialContent={editedCommentText} // Add this prop
                      />
                    ) : (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: comment.Cmdcomtents,
                        }}
                      ></p>
                    )}

                    {attachmentContent}
                  </div>
                  {editingIndex !== index && (
                    <div className="comment-footer">
                      {/* Only show Edit buttons for the comment owner */}
                      {comment.Username === userProfile.username && (
                        <Button onClick={(e) => handleEditComment(e, index)} className="accept-btn" title="Edit" > <FaPencilAlt /> Edit </Button>
                      )}

                      {/* Show reply button only if comment is not from current user */}
                      {comment.Username !== userProfile.username && (
                        <Button onClick={(e) => handleReplyButtonClick(e, index)} className="accept-btn" title="Reply"> <FaReply /> Reply </Button>
                      )}

                      {/* Only show Delete buttons for the comment owner */}
                      {comment.Username === userProfile.username && (
                        <Button onClick={(e) => handleConfirmDelete(e, index)} className="reject-btn" title="Delete" > <FaRegTrashAlt /> Delete </Button>
                      )}
                    </div>
                  )}
                  {comment.reply && comment.reply.length > 0 && (
                    <div className="comment-inner reply-area">
                      {comment.reply.map((reply, replyIndex) => (
                        <div
                          key={`reply-${index}-${replyIndex}`}
                          className="reply-inner"
                        >
                          <h5>
                            {reply.Username}{" "}
                            <label>{comment.Date_Time}</label>
                          </h5>
                          <p>{reply.rplycomtents}</p>
                          <div className="comment-footer">
                            <Button
                              onClick={(e) =>
                                handleConfirmDelete(
                                  e,
                                  index,
                                  replyIndex
                                )
                              }
                              className="reject-btn"
                            >
                              <FaRegTrashAlt /> Delete
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {replyingIndex === index && (
                    <div className="reply-area">
                      <CommentBox
                        onSaveComment={(commentData) => {
                          handleSendReply(commentData, index);
                          setReplyingIndex(null);
                        }}
                        onCancelComment={() =>
                          setReplyingIndex(null)
                        }
                        getTriggerfile={getTriggerfile}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  )
}
export default CommentTab;