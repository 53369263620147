import { FaTimes, FaCloudUploadAlt } from "react-icons/fa";
import { useEffect, useState, useRef } from "react";
import pathconfig from '../../pathconfig.json';
import axios from 'axios';
import moment from 'moment';
import { MdDownload } from "react-icons/md";
import { toastError, toastSuccess } from '../toaster';
import NodataFound from "../nodata";
import packjson from '../../../package.json';
import { useAppContextProvider } from '../../contextapi';

const Supplementary = (props) => {
    const [fileData, setFileData] = useState([]);
    const [fileUpdate, setFileUpdate] = useState([]);
    const fileInputRef = useRef(null);
    const { userProfile, setSidebarClose, setSupplementaryData, supplementaryGetData } = useAppContextProvider();

    useEffect(() => {
        if (supplementaryGetData) {
            setFileUpdate(supplementaryGetData);
        }
        getFiles(userProfile.docid);
    }, [supplementaryGetData, fileUpdate, userProfile.docid]);

    // useEffect(() => {
    //     const updatedStatus = fileData.map(itemA => {
    //         const matchedItem = uploadFileStatus.find(itemB => itemB.path === itemA.path);
    //         if (matchedItem) {
    //             return {
    //                 ...matchedItem,
    //                 role: matchedItem.role,
    //                 username: matchedItem.username,
    //                 createdOn: itemA.createdOn,
    //                 lastModified: itemA.lastModified
    //             };
    //         }
    //         return itemA; // Return the original item if no match is found
    //     });
    //     setFileData(updatedStatus);
    //     setSupplementaryData(updatedStatus);
    // }, [uploadFileStatus]);

    const getFiles = (value) => {
        let url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].retrievefiles}`;
        let GetDetailDataString = localStorage.getItem(`${value}_init-getdetails`);
        let GetDetailData = JSON.parse(GetDetailDataString);
        var data = {
            InputPath: GetDetailData.Articledetails.ArtResourcePath
        }
        axios.post(url, data)
            .then((response) => {
                setFileData(response.data);
                console.log(response.data, 'response.data')
                // const updatedStatus =response.data.map(itemA => {
                //     const matchedItem = fileUpdate.find(itemB => itemB.path === itemA.path);
                //     if (matchedItem) {
                //         return {
                //             ...itemA,        // Retain all fields from itemA
                //             ...matchedItem,  // Overwrite or add fields from matchedItem
                //             role: matchedItem.role, // Explicitly keep role from itemA
                //             username: matchedItem.username, // Explicitly keep username from itemA
                //             createdOn: itemA.createdOn, // Explicitly keep createdOn from itemA
                //             lastModified: itemA.lastModified // Explicitly keep lastModified from itemA
                //         };
                //     }
                //     return itemA; // Return the original item if no match is found
                // });
                //setFileData(updatedStatus);
            }).catch((error) => {
                console.log(error)
                toastError(error.message);
            })
    }

    const fileDownload = async (fileType, path) => {
        let fileExtension = fileType.split('.')[1];
        let mimeType;
        if (fileExtension === 'doc') {
            mimeType = 'application/msword';
        } else if (fileExtension === 'docx') {
            mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        } else if (fileExtension === 'xls') {
            mimeType = 'application/vnd.ms-excel';
        } else if (fileExtension === 'xlsx') {
            mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        } else if (fileExtension === 'ppt') {
            mimeType = 'vnd.ms-powerpoint';
        } else if (fileExtension === 'pptx') {
            mimeType = 'vnd.openxmlformats-officedocument.presentationml.presentation';
        } else if (fileExtension === '7z') {
            mimeType = 'x-7z-compressed';
        } else if (fileExtension === 'swf') {
            mimeType = 'x-shockwave-flash';
        } else {
            mimeType = `application/${fileExtension}`;
        }
        let urlPath = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].download}`;

        const response = await axios.post(urlPath, {
            blobUrl: path,
            type: mimeType
        }, {
            responseType: 'blob',// Important               
        });
        const url = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${fileType}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toastSuccess(`Supplementary file has been successfully downloaded.`);
    }

    const handleFileupload = (e) => {
        try {
            let docId = userProfile.docid;
            const uploadedFile = e.target.files[0];
            const maxSize = 40 * 1024 * 1024;

            if (uploadedFile.size > maxSize) {
                toastError('File size exceeds maximum limit of 40MB');
                return;
            }
            let GetDetailDataString = localStorage.getItem(`${docId}_init-getdetails`);
            let GetDetailData = JSON.parse(GetDetailDataString);
            var formdata = new FormData();
            // let filterFiles = fileData.filter((item) => item.path.split('/').pop() === e.target.files[0].name)
            if (!GetDetailDataString) {
                toastError('Document details not found');
                return;
            }
            let fileName = e.target.files[0];
            formdata.append("files", e.target.files[0]);
            formdata.append("path", GetDetailData.Articledetails.ArtResourcePath);
            formdata.append("role", userProfile.role);
            formdata.append("username", userProfile.username)
            const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].supplymentAttachment}`;
            axios.post(url, formdata, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }).then((response) => {
                if (response.data.success) {
                    toastSuccess(response.data.message);
                    getFiles(docId);
                    // update role 
                    // let updateRole = {
                    //     path: `${GetDetailData.Articledetails.ArtResourcePath}/${fileName.name}`,
                    //     role: userProfile.role,
                    //     username: userProfile.username,
                    // };
                    // setTimeout(() => {
                    //     setUploadFileStatus([...uploadFileStatus, updateRole]);
                    // }, 5000);
                }
            }).catch(error => {
                toastError('Failed to upload a file.');
            });
        } catch (error) {
            toastError('Error processing file upload');
            console.error('File upload error:', error);
        } finally {
            e.target.value = null; // Clear the file input
        }
    }

    return (
        <div className="supplementary-area">
            <div className="slider-header">
                <h5>Supplementary Files</h5>
                <div style={{ width: '20px' }}>
                    <button className="close-btn" onClick={() => setSidebarClose(true)} title="Close">
                        <FaTimes />
                    </button>
                </div>
            </div>
            <div className="supplementary-list">
                <div className="upload-btn-wrapper">
                    <FaCloudUploadAlt />
                    <p>Choose file or drag here</p>
                    <input type="file" onChange={(e) => handleFileupload(e)} ref={fileInputRef} />
                </div>
                {fileData.length !== 0 ? fileData.map((item, index) =>
                    <div className="supplementary-list-group" key={index}>
                        <button className="download-btn" title="Download" onClick={() => fileDownload(item.path.split('/').pop(), item.path)}><MdDownload /></button>
                        <p title={item.path.split('/').pop()}>{item.path.split('/').pop()}</p>
                        <p>role: {item?.metadata.role === undefined ? 'Admin' : item?.metadata.role}</p>
                        <span>Date: {moment(item.createdOn).format("DD-MM-YYYY - HH:mm:ss")}</span>
                    </div>
                ) : <NodataFound />}
            </div>
        </div>
    )
}
export default Supplementary;